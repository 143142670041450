import { CircularProgress } from '@mui/material';
import React from 'react';

interface ICircularLoadingProps {
  loadingText?: string;
}

export default function CircularLoading({ loadingText }: ICircularLoadingProps) {
  return (
    <>
      <div style={{ display: 'flex', flex: '1 1 auto', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
          <p>{loadingText ? loadingText : 'Loading...'}</p>
        </div>
      </div>
    </>
  );
}
