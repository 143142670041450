/* eslint-disable no-var */
import Avatar from '@mui/material/Avatar/Avatar';
import Card from '@mui/material/Card/Card';
import CardContent from '@mui/material/CardContent/CardContent';
import List from '@mui/material/List/List';
import ListItem from '@mui/material/ListItem/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import Typography from '@mui/material/Typography/Typography';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../store/store';
import { urltoFile } from '../../utils/exportAsImage';

export default observer(function AdminPage() {
  const { adminStore, openAI } = useStore();
  const { getAllUserRoles, roleUsers, setTestOwner } = adminStore;
  const { makeApiRequest, creationProcess } = openAI;

  const [text, setText] = useState('Et kurs som handler om å lage donuts');

  useEffect(() => {
    getAllUserRoles();
  }, []);

  const test = async () => {
    makeApiRequest(text);
    //const response = await dalleApiRequest(text);
  };

  /*  return (
    <>
      <div style={{ width: 700, height: 600, margin: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <div style={{ width: 700, height: 500, margin: 'auto', outline: '1px solid gray', padding: 16, display: 'flex' }}>
          <div style={{ width: 300 }}>
            input:
            <textarea
              id='promptText'
              name='promptText'
              rows={4}
              cols={30}
              style={{ backgroundColor: '#454545', color: 'white', fontSize: 16 }}
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </div>
          <ul style={{ width: 500, overflow: 'scroll', fontSize: 12 }}>
            {creationProcess.map((data, index) => (
              <li key={index}>{data}</li>
            ))}
          </ul>
        </div>
        <button id='myButton' onClick={test} style={{ width: 200, height: 30 }}>
          Create course
        </button>
      </div>
    </>
  ); */

  return (
    <>
      <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap', padding: 16, width: '100%', height: '100%', overflow: 'auto' }}>
        {roleUsers && (
          <>
            {roleUsers.map((role) => (
              <Card key={role.id} style={{ width: 400 }}>
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
                    {role.description}
                  </Typography>
                  <Typography variant='h5' component='div'>
                    {role.name}
                  </Typography>

                  <List sx={{ width: '100%', maxWidth: 360, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {role.users.map((user) => (
                      <ListItem key={user.user_id} alignItems='center' style={{ gap: 16, cursor: 'pointer' }} onClick={() => setTestOwner(user.user_id)}>
                        <ListItemAvatar>
                          <Avatar alt={user.name} src={user.picture} sx={{ width: 56, height: 56 }} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={user.name}
                          secondary={
                            <React.Fragment>
                              <Typography sx={{ display: 'inline' }} component='span' variant='body2' color='text.primary'>
                                {user.email}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            ))}
          </>
        )}
      </div>
    </>
  );
});
