import tinycolor from 'tinycolor2';

export class ColorObject {
  color: tinycolor.Instance = tinycolor('rgb(0,0,0)');
  rgb = { r: 0, g: 0, b: 0 };
  rgbString = 'rgb(0,0,0)';
  hex = '#000000';
  hex8 = '#00000000';
  hsv = { h: 0, s: 0, v: 0, a: 1 };
  hsvString = 'hsv(0, 0%, 0%)';
  hsl = { h: 0, s: 0, l: 0, a: 1 };
  hslString = 'hsl(0, 0%, 0%)';

  constructor(value: string) {
    this.color = tinycolor(value);
    this.rgb = this.color.toRgb();
    this.rgbString = this.color.toRgbString();
    this.hex = this.color.toHexString();
    this.hex8 = this.color.toHex8String();
    this.hsv = this.color.toHsv();
    this.hsvString = this.color.toHsvString();
    this.hsl = this.color.toHsl();
    this.hslString = this.color.toHslString();
  }
}
