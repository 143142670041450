import { makeAutoObservable } from 'mobx';

export interface IAccordionData {
  title: string;
  icon: string;
  image: string;
  text: string;
}

//TODO: Endre sånn at testOwner bruker samme user klasse som i userStore...
export default class ContentStore {
  constructor() {
    makeAutoObservable(this);
  }

  /* ACCORDION */
  expandedAccordion = 0;
  accordionData: IAccordionData[] = [
    {
      title: '<strong><span style="font-size: 18pt; data-mce-style="font-size: 18pt;">Valley</span></strong>',
      icon: 'Landscape',
      image: 'https://niastest02.norskinteraktiv.no/niasAPI/media/A5D502AE3A1D8466F4E7DDADA70DAE9A/00000565.jpg?638175989331007145',
      text: '<div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean malesuada sodales metus vitae bibendum. Praesent et ex purus. Praesent vulputate erat sit amet rhoncus aliquet.</div>',
    },
    {
      title: '<strong><span style="font-size: 18pt;" data-mce-style="font-size: 18pt;">Ocean</span></strong>',
      icon: 'Sailing',
      image: 'https://niastest02.norskinteraktiv.no/niasAPI/media/A5D502AE3A1D8466F4E7DDADA70DAE9A/00000566.jpg?638175989500997859',
      text: '<div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean malesuada sodales metus vitae bibendum. Praesent et ex purus. Praesent vulputate erat sit amet rhoncus aliquet.</div>',
    },
    /*     {
      title: '<strong><span style="font-size: 18pt; data-mce-style="font-size: 18pt;">City</span></strong>',
      icon: 'Apartment',
      image: 'https://niastest02.norskinteraktiv.no/niasAPI/media/A5D502AE3A1D8466F4E7DDADA70DAE9A/00000567.jpg?638175989590466701',
      text: '<div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean malesuada sodales metus vitae bibendum. Praesent et ex purus. Praesent vulputate erat sit amet rhoncus aliquet.</div>',
    }, */
    {
      title: '<strong><span style="font-size: 18pt; data-mce-style="font-size: 18pt;">Mountain</span></strong>',
      icon: 'AcUnit',
      image: 'https://niastest02.norskinteraktiv.no/niasAPI/media/A5D502AE3A1D8466F4E7DDADA70DAE9A/00000568.jpg?638175989774338538',
      text: '<div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean malesuada sodales metus vitae bibendum. Praesent et ex purus. Praesent vulputate erat sit amet rhoncus aliquet.</div>',
    },
    {
      title: '<strong><span style="font-size: 18pt; data-mce-style="font-size: 18pt;">Desert</span></strong>',
      icon: 'WbSunny',
      image: 'https://niastest02.norskinteraktiv.no/niasAPI/media/A5D502AE3A1D8466F4E7DDADA70DAE9A/00000569.jpg?638175989916363170',
      text: '<div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean malesuada sodales metus vitae bibendum. Praesent et ex purus. Praesent vulputate erat sit amet rhoncus aliquet.</div>',
    },
  ];

  setExpandedAccordion = (index: number) => {
    this.expandedAccordion = index;
  };

  /* ACCORDION */
}
