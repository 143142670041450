import React, { useEffect, useState } from 'react';
import { default as MuiAccordion } from '@mui/material/Accordion/Accordion';
import Typography from '@mui/material/Typography/Typography';
import AccordionDetails from '@mui/material/AccordionDetails/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';

interface AccordionProps {
  title: string;
  padding?: boolean;
  boldTitle?: boolean;
  borders?: boolean;
  isOpen?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
}

export default function Accordion({ title, isOpen, children, disabled, borders = true, padding: isPadding = true, boldTitle = true }: AccordionProps) {
  const theme = useTheme();
  const [open, setOpen] = useState(isOpen !== undefined ? isOpen : false);

  useEffect(() => {
    if (!isOpen) return;
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <>
      <MuiAccordion
        disableGutters
        square
        disabled={disabled}
        expanded={open}
        onChange={() => setOpen(!open)}
        sx={{
          background: 'transparent !important',
          boxShadow: 0,
          borderTop: borders ? `1px solid ${theme.palette.divider}` : '0',
          '&:last-child': {
            borderBottom: borders ? `1px solid ${theme.palette.divider}` : '0',
          },
          '&:before': {
            display: 'none',
          },
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ fontWeight: boldTitle ? 'bold' : 'normal' }}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ position: 'relative', padding: !isPadding ? 0 : '8px 16px 16px' }}>{children}</AccordionDetails>
      </MuiAccordion>
    </>
  );
}
