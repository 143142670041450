import { Theme } from '@mui/material/styles';
import { createUseStyles } from 'react-jss';

export const coursePageStyles = createUseStyles((theme: Theme) => ({
  title: {
    color: 'inherit',
    cursor: 'text',
  },
  plusIconStyle: {
    position: 'absolute',
    top: -6,
    right: -18,
    color: theme.palette.bgFour.main,
    background: theme.palette.primary.main,
    borderRadius: '50%',
    alignItems: 'center',
  },
  plusBar: {
    width: 'calc(100% + 70px)',
  },
  plusBarContainer: {
    display: 'flex',
    cursor: 'pointer',
    width: '100%',
    height: 5,
    backgroundColor: theme.palette.secondary.main,
  },
  plusBarButtonGroup: {
    '& .MuiButtonGroup-grouped': {
      minWidth: 30,
      padding: '4px 4px',
      /*       '&.Mui-selected': {
        color: theme.palette.primary.main,
        background: 'transparent',
      },
      '&:hover': {
        color: theme.palette.primary.light,
        background: 'transparent',
      },
      '&.Mui-disabled': {
        color: 'gray',
      }, */
    },
  },
  plusBarStyle: {
    position: 'relative',
    width: '30px',
    height: '30px',
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 3,
    cursor: 'pointer',
    boxSizing: 'border-box',
  },
  plusBarStyleLeft: {
    extend: 'plusBarStyle',
    borderTopLeftRadius: '100%',
    borderBottomLeftRadius: '100%',
  },
  plusBarStyleRight: {
    extend: 'plusBarStyle',
    borderTopRightRadius: '100%',
    borderBottomRightRadius: '100%',
    borderLeft: '1px solid',
    borderColor: theme.palette.border.main,
  },
  iconButton: {
    color: theme.palette.darkGray.main,
    margin: 5,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.darkGray.light,
    },
  },
  iconButtonWhite: {
    color: theme.palette.common.white,
    margin: 5,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.darkGray.light,
    },
  },
  iconButtonCompact: {
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.dark,
    },
    color: theme.palette.primary.main,
    margin: 5,
  },
  tooltip: {
    fontSize: theme.typography.pxToRem(15),
  },
  dialog: {
    background: theme.palette.background.default,
  },
  list: {
    width: '100%',
    // marginTop: '23.5em',
  },
  chapter: {
    boxShadow: '0px 0px 1px 1px rgba(0,0,0,0.04)',
    padding: 1,
    width: '100%',
    marginTop: 4,
    marginBottom: 4,
    minHeight: '3em',
    background: '#505050',
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      filter: 'invert(7%)',
    },
    '&:active': {
      boxShadow: '0 0 0.4em 0.05em #999',
      filter: 'unset',
    },
    '& h2:hover': {
      cursor: 'grab',
    },
    '& h2:active': {
      cursor: 'grabbing',
    },
  },
  pageCompact: {
    color: theme.palette.bgOne.contrastText,
    background: 'transparent',
    '&:hover': {
      background: theme.palette.primary.light,
      '& $iconButton': {
        color: theme.palette.common.white,
      },
    },
  },
  pageCompactSelected: {
    color: theme.palette.bgOne.contrastText,
    background: theme.palette.primary.main,
    '& $iconButton': {
      color: theme.palette.common.white,
    },
    '&:hover': {
      background: theme.palette.primary.light,
      '& $iconButton': {
        color: theme.palette.common.white,
      },
    },
  },
  warn: {
    color: theme.palette.warning.main,
  },
}));
