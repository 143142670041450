import { makeAutoObservable } from 'mobx';

export interface iPadding {
  left: number;
  right: number;
  top: number;
  bottom: number;
}

export interface IWalkthroughConfigModel {
  enableInteraction: boolean;
  closeOnMask: boolean;
  hideMask?: boolean;
}
export interface IWalkthroughStepModel {
  selector: string;
  maskSelector?: string;
  title: string;
  body: string;
  delay?: number;
  hideButton?: boolean;
  attach?: boolean;
  attachButtons?: string[];
  attachFocusOut?: boolean;
  attachKeyCode?: number;
  source?: string;
  scrollTo?: boolean;
  position: string;
}

export default class WalkthroughStore {
  isWalkthroughOpen = false;
  currentStep = -1;
  walkthroughSteps: IWalkthroughStepModel[] = [];
  walkthroughConfig: IWalkthroughConfigModel = { enableInteraction: true, closeOnMask: false };

  walkthroughTrigger = false;

  closeWalkthrough: () => void = () => {
    return;
  };
  skipWalkthrough: () => void = () => {
    return;
  };
  nextStep: () => void = () => {
    return;
  };
  previousStep: () => void = () => {
    return;
  };

  constructor() {
    makeAutoObservable(this);
  }

  setCloseWalkthrough = (action: () => void) => {
    this.closeWalkthrough = action;
  };

  setIsWalkthroughOpen = (b: boolean) => {
    this.isWalkthroughOpen = b;
  };
  getIsWalkthroughOpen = () => {
    return this.isWalkthroughOpen;
  };
  setCurrentStep = (n: number) => {
    this.currentStep = n;
  };
  getCurrentStep = () => {
    return this.currentStep;
  };

  getCurrentTemplate = () => {
    return this.walkthroughSteps;
  };

  getCurrentConfig = () => {
    return this.walkthroughConfig;
  };

  /*   startTemplateWalkthrough = () => {
    this.walkthroughConfig = { enableInteraction: true, closeOnMask: false };
    this.walkthroughSteps = [
      {
        selector: '.WalkthroughStepOne',
        title: 'Choose a template',
        body: 'The description of the step and what you can do. Do not write obvious stuff the user already know! We have to inform them of new features and worksteps they may not be familiar with',
        source: '/img/grid/TemplateAnim.mp4',
        delay: 2000,
        position: 'right',
      },
      {
        selector: '.WalkthroughStepTwo',
        title: 'Choose a layout',
        body: 'The description of the step and what you can do. Do not write obvious stuff the user already know! We have to inform them of new features and worksteps they may not be familiar with',
        source: '/img/grid/LayoutAnim.mp4',
        position: 'right',
      },
    ];
    this.startWalkthrough();
  };

  //Denne kan fjernes når NewWalkthrough er ferdig
  setTemplateSteps = () => {
    this.walkthroughConfig = { enableInteraction: true, closeOnMask: false };
    this.walkthroughSteps = [
      {
        selector: '.WalkthroughStepOne',
        title: 'Choose a template',
        body: 'The description of the step and what you can do. Do not write obvious stuff the user already know! We have to inform them of new features and worksteps they may not be familiar with',
        source: '/img/grid/TemplateAnim.mp4',
        position: 'right',
      },
      {
        selector: '.WalkthroughStepTwo',
        title: 'Choose a layout',
        body: 'The description of the step and what you can do. Do not write obvious stuff the user already know! We have to inform them of new features and worksteps they may not be familiar with',
        source: '/img/grid/LayoutAnim.mp4',
        position: 'right',
      },
    ];
    return this.walkthroughSteps;
  };

  setNewCourseSteps = () => {
    this.walkthroughConfig = { enableInteraction: false, closeOnMask: false };
    this.walkthroughSteps = [
      {
        selector: '[data-coursetour="1"]',
        title: 'Create a new course',
        body: 'Click here to create a new course',
        position: 'right',
      },
    ];
    return this.walkthroughSteps;
  }; */

  startNewCourseWalkthrough = () => {
    this.walkthroughConfig = { enableInteraction: true, closeOnMask: false, hideMask: false };
    this.walkthroughSteps = [
      {
        selector: '[data-newcoursetour="0"]',
        title: 'Create a new course',
        body: 'Click here to create a new course',
        position: 'bottom',
        attach: true,
        hideButton: true,
      },
      {
        selector: '[data-newcoursetour="1"]',
        maskSelector: '[data-newcoursetourmask="1"]',
        title: 'Course title',
        body: 'Give your new course a title and press enter',
        position: 'right',
        delay: 300,
        attachFocusOut: true,
        hideButton: true,
      },
      {
        selector: '[data-newcoursetour="2"]',
        maskSelector: '[data-newcoursetourmask="1"]',
        attachButtons: ['[data-newcoursetour="3"]'],
        title: 'Course description',
        body: 'Give your new course a description, then click create',
        position: 'right',
        hideButton: true,
      },
      {
        selector: '[data-newcoursetour="4"]',
        title: 'Edit course',
        body: 'Click here to open your course',
        position: 'right',
        delay: 300,
        attach: true,
        hideButton: true,
      },
      {
        selector: '[data-pagetour="1"]',
        title: 'Create a new page',
        body: 'Click here to create a new page',
        position: 'bottom',
        delay: 500,
        attach: true,
        hideButton: true,
      },
      {
        selector: '[data-pagetour="2"]',
        title: 'Give the page a new title',
        body: 'Click enter when you are satisfied with the title',
        position: 'bottom',
        delay: 300,
        attachFocusOut: true,
        hideButton: true,
      },
      {
        selector: '[data-pagetour="3"]',
        title: 'Edit the new page',
        body: 'Click here to edit the new page',
        position: 'bottom',
        attach: true,
        hideButton: true,
      },
      /*       {
        selector: '.WalkthroughStepOne',
        title: 'Choose a template',
        body: 'The description of the step and what you can do. Do not write obvious stuff the user already know! We have to inform them of new features and worksteps they may not be familiar with',
        source: '/img/grid/TemplateAnim.mp4',
        position: 'right',
        delay: 600,
      }, */
      {
        selector: '.WalkthroughStepTwo',
        attachButtons: [
          '[data-layouttour="1"]',
          '[data-layouttour="2"]',
          '[data-layouttour="3"]',
          '[data-layouttour="4"]',
          '[data-layouttour="5"]',
          '[data-layouttour="6"]',
          '[data-layouttour="7"]',
          '[data-layouttour="8"]',
          '[data-layouttour="9"]',
          '[data-layouttour="10"]',
          '[data-layouttour="11"]',
          '[data-layouttour="12"]',
          '[data-layouttour="13"]',
          '[data-layouttour="14"]',
          '[data-layouttour="15"]',
          '[data-layouttour="16"]',
        ],
        title: 'Choose a layout',
        body: 'Select the layout you want to use for your course! Each container is responsive and will scale itself on smaller devices.',
        source: '/img/grid/LayoutAnim.mp4',
        position: 'right',
        delay: 600,
        scrollTo: true,
      },
    ];
    this.walkthroughTrigger = !this.walkthroughTrigger;
  };
}
