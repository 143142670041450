/// <summary>
/// Placeholder container for React Prop

import { IPagePlaceholder } from '../services/models/PageViewModel';
import * as Layout from './LayoutObject';
import { Rnd, Position, ResizableDelta } from 'react-rnd';
import { ResizeDirection } from 're-resizable';

export type ResizeEvent = {
  direction: ResizeDirection;
  delta: ResizableDelta;
  position: Position;
};

/// <summary>
export class TPagePlaceholder {
  public readonly serverData: IPagePlaceholder;
  private readonly newServerData: IPagePlaceholder;
  public rndRef: Rnd | null = null;
  private readonly layoutElement: Layout.TLayoutElement;
  private rect: Layout.TRect;

  constructor(aServerData: IPagePlaceholder) {
    this.serverData = aServerData;
    this.newServerData = { ...aServerData };
    this.rect = new Layout.TRect(aServerData.left, aServerData.top, aServerData.width, aServerData.height);
    this.layoutElement = Layout.createElement(this.rect, this);
  }

  public resize(aEvent: ResizeEvent, aResizeDirection: Layout.IResizeEdgeObject | null) {
    if (aResizeDirection === null) {
      let resizeDirection = Layout.ResizeEdge.None;
      switch (aEvent.direction) {
        case 'left':
          resizeDirection = Layout.ResizeEdge.Left;
          break;
        case 'right':
          resizeDirection = Layout.ResizeEdge.Right;
          break;
        case 'top':
          resizeDirection = Layout.ResizeEdge.Top;
          break;
        case 'bottom':
          resizeDirection = Layout.ResizeEdge.Bottom;
          break;
        default:
          resizeDirection = Layout.ResizeEdge.None;
      }
      //this.serverData.left = aEvent.position.x;
      //this.serverData.top = aEvent.position.y;
      this.layoutElement.resize(aEvent, resizeDirection);
    } else {
      if (this.rndRef != null) {
        let direction = 1;
        let delta = aEvent.delta.width;
        switch (aResizeDirection) {
          case Layout.ResizeEdge.Right:
            if (aEvent.direction !== 'right') {
              direction = -1;
            }
            delta = aEvent.delta.width * direction;
            this.newServerData.width = this.serverData.width + delta;
            this.rndRef.updateSize({ width: this.serverData.width + delta, height: this.serverData.height });
            break;
          case Layout.ResizeEdge.Left:
            if (aEvent.direction !== 'left') {
              direction = -1;
            }
            delta = aEvent.delta.width * direction;
            this.newServerData.width = this.serverData.width + delta;
            this.newServerData.left = this.serverData.left - delta;
            this.rndRef.updateSize({ width: this.serverData.width + delta, height: this.serverData.height });
            this.rndRef.updatePosition({ x: this.serverData.left - delta, y: this.serverData.top });
            break;
          case Layout.ResizeEdge.Bottom:
            if (aEvent.direction !== 'bottom') {
              direction = -1;
            }
            delta = aEvent.delta.height * direction;

            this.newServerData.height = this.serverData.height + delta;
            this.rndRef.updateSize({ width: this.serverData.width, height: this.serverData.height + delta });
            break;
          case Layout.ResizeEdge.Top:
            if (aEvent.direction !== 'top') {
              direction = -1;
            }
            delta = aEvent.delta.height * direction;
            this.newServerData.height = this.serverData.height + delta;
            this.newServerData.top = this.serverData.top - delta;
            this.rndRef.updateSize({ width: this.serverData.width, height: this.serverData.height + delta });
            this.rndRef.updatePosition({ x: this.serverData.left, y: this.serverData.top - delta });
            break;
        }
        //this.rect.update(this.serverData.left, this.serverData.top, this.serverData.width, this.serverData.height);
      }
    }
  }

  public ResizePlaceholder(useOriginData: boolean): void {
    if (!useOriginData) {
      this.serverData.left = this.newServerData.left;
      this.serverData.top = this.newServerData.top;
      this.serverData.width = this.newServerData.width;
      this.serverData.height = this.newServerData.height;
      this.serverData.hasChanged = true;
    } else {
      this.newServerData.left = this.serverData.left;
      this.newServerData.top = this.serverData.top;
      this.newServerData.width = this.serverData.width;
      this.newServerData.height = this.serverData.height;
      this.newServerData.hasChanged = this.serverData.hasChanged;
    }
    this.rect.update(this.serverData.left, this.serverData.top, this.serverData.width, this.serverData.height);
  }
} // End of class TPlaceHolder
