import React, { useEffect, useState } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import { useStore } from '../../../store/store';
import { observer } from 'mobx-react';
import { useQueryParam, NumberParam } from 'use-query-params';
import { createUseStyles } from 'react-jss';
import PageNavigation from './PageNavigation';
import PreviewButtons from './PreviewButtons';
import { TTreeItemDataViewModel } from '../../../services/models/CourseViewModels';
import { TPageViewModel } from '../../../services/models/PageViewModel';
import { Link } from 'react-router-dom';

interface TopBarProps {
  editMode?: boolean;
}

const useStyles = createUseStyles((theme: Theme) => ({
  topBar: {
    flex: '0 1 auto',
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.bgOne.contrastText,
    background: theme.palette.bgOne.main,
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    height: 50,
  },
}));

export default observer(function TopBar({ editMode }: TopBarProps) {
  const { courseStore, pageStore, previewStore } = useStore();
  const { loadCourseViewModel, courseViewModel, clearCourseViewModel } = courseStore;
  const { getPage, getCoursePage, openPagePreview } = pageStore;
  const { openCoursePreview, sharePreview } = previewStore;
  const [courseIDParam] = useQueryParam('courseID', NumberParam);
  const [pageIDParam, setPageIDParam] = useQueryParam('pageID', NumberParam);
  const [pageStateParam, setPageStateParam] = useQueryParam('pageState', NumberParam);
  const [currentPage, setCurrentPage] = useState<TTreeItemDataViewModel>();
  const [coursePage, setCoursePage] = useState<TPageViewModel>();
  const [nextPage, setNextPage] = useState<number>();
  const [previousPage, setPreviousPage] = useState<number>();

  const theme = useTheme();
  const classes = useStyles({ theme });

  const loadPage = async () => {
    if (courseIDParam && pageStateParam) {
      await getPage(courseIDParam, 0, pageStateParam);
    } else if (courseIDParam && pageIDParam) {
      await getPage(courseIDParam, pageIDParam);
    }

    const page = getCoursePage();
    setCoursePage(page);
    if (page) {
      setNextPage(page.nextPage?.coursePageID);
      setPreviousPage(page.prevPage?.coursePageID);
    }

    if (courseIDParam && page) {
      if (courseViewModel?.courseID !== page.courseID) {
        loadCourseViewModel(courseIDParam);
      }
      setCurrentPage(courseViewModel?.pages.items[page.coursePageID]?.data);
    }
  };

  useEffect(() => {
    loadPage();
  }, [courseIDParam, pageIDParam, pageStateParam]);

  useEffect(() => {
    if (courseViewModel && coursePage) {
      setCurrentPage(courseViewModel?.pages.items[coursePage.coursePageID]?.data);
    }
  }, [courseViewModel, coursePage]);

  useEffect(() => {
    if (!courseViewModel && courseIDParam) {
      loadCourseViewModel(courseIDParam);
    }
  }, [courseViewModel, courseIDParam]);

  return (
    <AppBar position='static' className={classes.topBar}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', height: '100%' }}>
        <IconButton size='small' component={Link} to={'/'} style={{ color: 'inherit' }} onClick={clearCourseViewModel}>
          <HomeIcon />
        </IconButton>
        {editMode && (
          <>
            <IconButton size='small' component={Link} to={`/course?id=${courseIDParam}`} style={{ color: 'inherit' }}>
              <SubdirectoryArrowLeftIcon style={{ transform: 'rotate(90deg)' }} />
            </IconButton>
            <PageNavigation
              previousPage={previousPage}
              nextPage={nextPage}
              currentPage={currentPage}
              courseViewModel={courseViewModel}
              pageIDParam={pageIDParam ?? undefined}
              pageStateParam={pageStateParam ?? undefined}
              setPageIDParam={setPageIDParam}
              setPageStateParam={setPageStateParam}
            />
          </>
        )}
        <PreviewButtons editMode={editMode ?? false} openPagePreview={openPagePreview} openCoursePreview={openCoursePreview} sharePreview={sharePreview} />
      </div>
    </AppBar>
  );
});
