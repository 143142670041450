import { makeAutoObservable, runInAction } from 'mobx';
import { ICourseWizard } from '../services/models/WizardViewModel';
import agent from '../services/api/agent';
import { log } from 'console';
import { timeout } from '../utils/timeout';
import { store } from './store';

interface IInputResponse {
  id: string;
  input: string;
}

export default class CourseWizardStore {
  isAdvancedOpen = false;
  courseWizardOpen = false;
  courseWizard: ICourseWizard | undefined = undefined;
  courseWizardStages: string[] = [];
  courseWizardCustomStages: string[] = [];
  currentStage = 0;
  hasUsedCustomSettings = false;

  titleInput: IInputResponse = { id: '1', input: '' };
  descriptionInput: IInputResponse = { id: '2', input: '' };
  courseWidth: IInputResponse = { id: '57', input: '1280' };
  courseHeight: IInputResponse = { id: '58', input: '720' };
  selectedSize = '45';

  constructor() {
    makeAutoObservable(this);
  }

  OpenAdvanced = (b: boolean) => {
    this.isAdvancedOpen = b;
  };

  OpenCourseWizard = () => {
    this.courseWizardOpen = true;
  };

  closeWizard = () => {
    this.courseWizardOpen = false;
    this.resetWizard();
  };

  resetWizard = () => {
    timeout(500).then(() => {
      runInAction(() => {
        this.currentStage = 0;
        this.titleInput = { id: '1', input: '' };
        this.descriptionInput = { id: '2', input: '' };
        this.courseWidth = { id: '57', input: '1280' };
        this.courseHeight = { id: '58', input: '720' };
        this.selectedSize = '45';
        this.hasUsedCustomSettings = false;
        this.isAdvancedOpen = false;
      });
    });
  };

  getWizard = async () => {
    this.courseWizard = await agent.Owner.getNewCourseWizard();
    this.courseWizardStages = Object.keys(this.courseWizard.stages);
    this.courseWizardCustomStages = Object.keys(this.courseWizard.advanced.pageSize.propertyNames.display.propertyOptionNameID);
    this.OpenCourseWizard();
  };

  nextStep = () => {
    if (this.currentStage < this.courseWizardStages.length - 1) {
      this.currentStage++;
    } else {
      this.createCourse();
    }
  };

  createCourse = () => {
    if (this.courseWizard === undefined) return;
    const responseObj = this.courseWizard.response;
    responseObj[this.titleInput.id] = this.titleInput.input;
    responseObj[this.descriptionInput.id] = this.descriptionInput.input;
    responseObj[this.courseWidth.id] = this.courseWidth.input;
    responseObj[this.courseHeight.id] = this.courseHeight.input;

    store.ownerStore.createCourse(responseObj);
    this.closeWizard();
  };

  previousStep = () => {
    if (this.currentStage > 0) {
      this.currentStage--;
    }
  };

  setTitleInput = (aInput: string) => {
    const tmp = this.titleInput;
    tmp.input = aInput;
    this.titleInput = tmp;
  };

  setDescriptionInput = (aDescription: string) => {
    const tmp = this.descriptionInput;
    tmp.input = aDescription;
    this.descriptionInput = tmp;
  };

  setSelectedSize = (aSize: string) => {
    if (this.courseWizard === undefined) return;
    this.selectedSize = aSize;

    const tmpSelectedSize = this.courseWizard.advanced.pageSize.propertyNames.display.propertyOptionNameID[aSize];
    if (tmpSelectedSize === undefined) return;
    this.courseWidth.input = tmpSelectedSize.width;
    this.courseHeight.input = tmpSelectedSize.height;
    this.setHasUsedCustomSettings();
  };

  setCourseWidth = (aWidth: string) => {
    this.courseWidth.input = aWidth;
  };

  setCourseHeight = (aHeight: string) => {
    this.courseHeight.input = aHeight;
  };

  setHasUsedCustomSettings = () => {
    this.hasUsedCustomSettings = true;
  };
}
