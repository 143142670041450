import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useStore } from '../../store/store';
import { observer } from 'mobx-react';

export default observer(function ImageEditorHeader() {
  const { imageEditorStore } = useStore();
  const { imageFileName } = imageEditorStore;
  const theme = useTheme();

  return (
    <>
      {imageFileName !== '' && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.text.primary,
            background: theme.palette.bgTwo.main,
            padding: theme.spacing(1),
            zIndex: 10,
          }}
        >
          {imageFileName}
        </div>
      )}
    </>
  );
});
