import { Theme } from '@mui/material/styles';
import { createUseStyles } from 'react-jss';

export const modalStyle = createUseStyles((theme: Theme) => ({
    modalBody: {
      background: theme.palette.bgThree.main,
      color: theme.palette.bgThree.contrastText,
      elevation: 0,
      width: '90%',
      height: '70%',
    },
  }));