import html2canvas from 'html2canvas';
import agent from '../services/api/agent';
import { store } from '../store/store';

export const exportAsImage = async (el: HTMLElement | null) => {
  if (el == null) return;
  //return;
  try {
    const canvas = await html2canvas(el, { allowTaint: true, useCORS: true, logging: false });
    const image = canvas.toDataURL('image/png', 1.0);
    const coursePage = store.pageStore.getCoursePage();
    if (coursePage === undefined) return;
    urltoFile(image, `pageAvatar-${coursePage.courseID}-${coursePage.coursePageID}.png`, 'image/png').then(function (file) {
      agent.Page.UploadCoursePageAvatar(coursePage.courseID, coursePage.coursePageID, file);
    });
  } catch {
    console.log('pang');
  }
};

export const urltoFile = async (url: string, filename: string, mimeType: string) => {
  const res = await fetch(url);
  const buf = await res.arrayBuffer();
  return new File([buf], filename, { type: mimeType });
};

export const base64ToFile = (base64String: string, fileName: string, mimeType: string): File => {
  // Remove the data URL prefix (e.g., "data:image/png;base64,")
  const base64Data = base64String.replace(/^data:.*;base64,/, '');

  // Convert the base64 string to a binary array
  const binaryString = window.atob(base64Data);
  const binaryData = new Uint8Array(binaryString.length);

  for (let i = 0; i < binaryString.length; i++) {
    binaryData[i] = binaryString.charCodeAt(i);
  }

  // Create a Blob from the binary data
  const blob = new Blob([binaryData], { type: mimeType });

  // Create a File object from the Blob
  return new File([blob], fileName, { type: mimeType });
};
