import React from 'react';
import { EPageState } from '../../../store/pageStore';
import { useStore } from '../../../store/store';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import CloudIcon from '@mui/icons-material/Cloud';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import { observer } from 'mobx-react';

export default observer(function PageStatus() {
  const { pageStore } = useStore();
  const { pageState } = pageStore;

  switch (pageState) {
    case EPageState.unsaved:
    case EPageState.saving: {
      return <CloudSyncIcon />;
    }
    case EPageState.saved: {
      return <CloudDoneIcon />;
    }
    case EPageState.disconnected: {
      return <CloudOffIcon />;
    }
    case EPageState.reconnecting: {
      return <CloudQueueIcon />;
    }
    default: {
      return <CloudIcon />;
    }
  }
});
