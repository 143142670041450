import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../services/api/agent';
import { EOwnerCourseListSortByType, TOwnerCourseViewModel } from '../services/models/OwnerViewModel';
import { TToolBox } from '../services/models/ToolboxModel';
import { ICWResponseObj, IWizard } from '../services/models/WizardViewModel';
import { store } from './store';
import SnackbarUtils from '../components/snackbarUtils/SnackbarUtilsConfigurator';
import { TPropertyValueView } from '../services/models/PropertyViewModel';

export interface courseListType {
  id: number;
  course: TOwnerCourseViewModel;
}

export default class OwnerStore {
  courseList: courseListType[] | undefined = undefined;
  sortBy: EOwnerCourseListSortByType = EOwnerCourseListSortByType.Altered;
  searchQuery = '';
  toolBox: TToolBox | undefined = undefined;
  courseWizardOld: IWizard | undefined = undefined;
  courseWizardOpen = false;
  CurrentSideViewTab = 3; //3 == news;
  gridView = true;
  editMode = false;
  loadingInitial = true;
  loadingExtra = false;
  uploading = false;

  constructor() {
    makeAutoObservable(this);
  }

  loadOwnerViewModel = async () => {
    try {
      const courses = await agent.Owner.getCourses(this.sortBy, this.searchQuery);
      //const test = await agent.User.getUser();
      //console.log(test);
      try {
        this.setOwnerViewModel(courses);
      } catch (error) {
        console.log(error);
      }
      this.setLoadingInitial(false);
      this.setLoadingExtra(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      this.setLoadingExtra(false);
    }
  };

  createCourse = async (aResponseObj: ICWResponseObj) => {
    try {
      const newCourse: TOwnerCourseViewModel[] = await agent.Owner.createCourse(JSON.stringify(aResponseObj));
      this.addNewCourse(newCourse[0]);
      return newCourse[0];
      //this.closeWizard();
    } catch (error) {
      console.log(error);
    }
  };

  copyCourse = async (courseID: number) => {
    try {
      //Gjøre noe med teksten som sier at dette kan ta tid. Bare bruk systemet på vanlig måte
      SnackbarUtils.copyingStart('Copying in progress.');
      const duplicatedCourse: TOwnerCourseViewModel[] = await agent.Owner.copyCourse(courseID);
      if (duplicatedCourse) {
        this.addNewCourse(duplicatedCourse[0]);
        SnackbarUtils.copyingClose(duplicatedCourse[0].courseID);
      }
    } catch (error) {
      console.log(error);
    }
  };

  addNewCourse = (newCourse: TOwnerCourseViewModel) => {
    if (this.courseList === undefined) return;
    this.courseList.unshift({ id: newCourse.courseID, course: newCourse });

    store.courseStore.getCourseProperties(newCourse.courseID);
    //store.courseStore.OpenCourseProperties();
    this.setSideViewTab(0);
  };

  private addCourseToList = (course: TOwnerCourseViewModel) => {
    if (this.courseList === undefined) return;
    this.courseList.push({ id: course.courseID, course: course });
  };

  private setOwnerViewModel(courses: TOwnerCourseViewModel[]) {
    this.courseList = [];
    courses.forEach((course) => {
      this.addCourseToList(course);
    });
  }

  courseListSortBy = (sortBy: any) => {
    this.sortBy = sortBy as EOwnerCourseListSortByType;
    this.loadOwnerViewModel();
  };

  courseListSearchQuery = (aQuery: string) => {
    this.searchQuery = aQuery;
    this.loadOwnerViewModel();
  };

  getToolBox = async () => {
    //const tBox = await agent.Owner.getToolBox();
    try {
      runInAction(() => {
        //this.toolBox = tBox;
      });
    } catch (error) {
      console.log(error);
    }
  };

  updateCourseOption = async (selcCourseId: number, aPropertyValueView: TPropertyValueView, value: string) => {
    try {
      await store.propertyStore.setOption(aPropertyValueView, value);
      if (selcCourseId != 0) {
        this.updateCourseView(selcCourseId);
        store.courseStore.updateCourseProperties(selcCourseId);
      }
    } catch (error) {
      console.log(error);
    }
  };

  updateCourseView = async (courseId: number) => {
    if (this.courseList === undefined) return;
    const newCourse: TOwnerCourseViewModel[] = await agent.Owner.getCourse(courseId);
    const course = this.courseList.find((x) => x.course.courseID == courseId);
    if (course) {
      runInAction(() => {
        course.course = newCourse[0];
        store.courseStore.selectedCourse = newCourse[0];
      });
    } else {
      console.log('CourseId ' + courseId + ' is not in courselist');
    }
  };

  deleteCourse = async (courseID: number) => {
    if (this.courseList === undefined) return;
    const response = await agent.Owner.deleteCourse(courseID);
    if (response != 500) {
      /* SnackbarUtils.success('Course deleted', () => this.setDarkMode(!this.darkMode), 'undo', 5000); */
      SnackbarUtils.success('Course deleted', undefined, undefined, 5000);
      store.courseStore.deselectCourseProperties();
    }
    this.courseList = this.courseList.filter((element) => {
      return element.id != courseID;
    });
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  setLoadingExtra = (state: boolean) => {
    this.loadingExtra = state;
  };

  closeForm = () => {
    this.editMode = false;
  };

  toggleGridView = () => {
    this.gridView = !this.gridView;
  };

  setSideViewTab = (n: number) => {
    if (n != undefined) this.CurrentSideViewTab = n;
  };
}
