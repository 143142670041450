import React, { useEffect, useState } from 'react';
import { HSLToHex, getColorName, hexToHSL, hexToRGB, rgbToHex } from '../../utils/HexToRGBA';
import { colorNames } from './ColorNames';
import { createUseStyles } from 'react-jss';
import ColorPicker from './ColorPicker';
import Typography from '@mui/material/Typography/Typography';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton/ToggleButton';
import { formatString } from '../../utils/FormatCodeString';

interface IColorShades {
  hsl: IColorShadesHSL;
  hex: string;
  textColor: string;
  inputColor?: boolean;
}

interface IColorShadesHSL {
  h: number;
  s: number;
  l: number;
}

export default function ColorShade() {
  const [colorShades, setColorShades] = useState<IColorShades[]>([]);
  const [colorName, setColorName] = useState('');
  const aColor = colorNames[Math.floor(Math.random() * colorNames.length)];
  const [mainColor, setMainColor] = useState(aColor ? aColor.hex : '#FF0000');
  //const [color2, setColor2] = useState(colorNames[Math.floor(Math.random() * colorNames.length)].hex);
  const [exportText, setExportText] = useState('');
  const [exportState, setExportState] = useState('hex');

  const onColorChange = (aColor: string) => {
    aColor = rgbToHex(aColor);
    setMainColor(aColor);
    generateExport();
  };

  const createColorShades = (aHexColor: string) => {
    const hslColor = hexToHSL(aHexColor);
    if (hslColor === null) return;

    const colorArray: IColorShades[] = [];
    let hslLightValue = hslColor.l;

    while (hslLightValue + 10 < 100) {
      hslLightValue = (hslLightValue + 10) * 1;
      colorArray.push({ hsl: { h: hslColor.h, s: hslColor.s, l: hslLightValue }, hex: HSLToHex(`hsl(${hslColor.h},${hslColor.s}%,${hslLightValue}%)`), textColor: '#000' });
    }

    colorArray.unshift({ hsl: { h: hslColor.h, s: hslColor.s, l: hslColor.l }, hex: aHexColor, textColor: '#000', inputColor: true });
    hslLightValue = hslColor.l;

    while (hslLightValue - 10 > 0) {
      hslLightValue = (hslLightValue - 10) * 1;
      colorArray.unshift({ hsl: { h: hslColor.h, s: hslColor.s, l: hslLightValue }, hex: HSLToHex(`hsl(${hslColor.h},${hslColor.s}%,${hslLightValue}%)`), textColor: '#000' });
    }

    hslLightValue = colorArray[colorArray.length - 1].hsl.l;
    if (hslLightValue >= 90 && hslLightValue < 95 && colorArray.length < 10) {
      hslLightValue = (hslLightValue + 5) * 1;
      colorArray.push({ hsl: { h: hslColor.h, s: hslColor.s, l: hslLightValue }, hex: HSLToHex(`hsl(${hslColor.h},${hslColor.s}%,${hslLightValue}%)`), textColor: '#000' });
    }

    setColorShades(colorArray.reverse());
    handleGetColorName();
  };

  const handleGetColorName = () => {
    setColorName(getColorName(mainColor));
  };

  const generateExport = () => {
    let exportString = '';

    switch (exportState) {
      case 'hex':
        for (let i = 0; i < colorShades.length; i++) {
          exportString += `--color-${i + 1}: ${colorShades[i].hex.toLocaleUpperCase()};\n`;
        }
        break;
      case 'rgb':
        for (let i = 0; i < colorShades.length; i++) {
          exportString += `--color-${i + 1}: ${hexToRGB(colorShades[i].hex.toLocaleUpperCase())};\n`;
        }
        break;
      case 'svg':
        exportString = '<svg width="1000" height="100" viewBox="0 0 1000 100" fill="none" xmlns="http://www.w3.org/2000/svg">';
        for (let i = 0; i < colorShades.length; i++) {
          const newRect = `<rect width="100" height="100" fill="${colorShades[i].hex.toLocaleUpperCase()}" x="${100 * i}" y="0"/>`;
          const textColorIndex = colorShades[i].hsl.l < 50 ? 0 : colorShades.length - 1;
          const TextRect = `<text x="${50 + 100 * i}" y="20" font-family="Arial" text-anchor="middle" font-size="15" fill="${colorShades[textColorIndex].hex.toLocaleUpperCase()}">${colorShades[
            i
          ].hex.toLocaleUpperCase()}</text>`;
          exportString += newRect + TextRect;
        }

        exportString += '</svg>';
        break;
    }
    setExportText(formatString(exportString));
  };

  const handleExportStateChange = (event: React.MouseEvent<HTMLElement>, aValue: string) => {
    if (aValue !== null) {
      setExportState(aValue);
    }
  };

  useEffect(() => {
    generateExport();
  }, [exportState]);

  useEffect(() => {
    createColorShades(mainColor);
  }, [mainColor]);

  return (
    <div style={{ overflow: 'hidden', padding: 16, paddingBottom: 32, position: 'relative' }}>
      <div style={{ padding: 8, overflow: 'auto', boxSizing: 'border-box', maxHeight: '100%' }}>
        <div style={{ width: 200 }}>
          <ColorPicker defaultColor={mainColor} onColorChange={onColorChange} />
        </div>
        <h1 style={{ textTransform: 'capitalize' }}>{colorName}</h1>
        <div style={{ display: 'flex', gap: 16, justifyContent: 'space-between', flexWrap: 'wrap' }}>
          {colorShades.map((color) => (
            <ColorShadeCard key={color.hex} color={color} colorArray={colorShades} mainColor={mainColor} />
          ))}
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: 16, width: '100%', marginTop: 16, height: 220 }}>
          <Typography variant='body1'>Export to</Typography>
          <ToggleButtonGroup color='primary' size='small' aria-label='Small sizes' value={exportState} onChange={handleExportStateChange} exclusive>
            <ToggleButton value='hex' key='hex'>
              CSS Hex
            </ToggleButton>
            <ToggleButton value='rgb' key='rgb'>
              CSS RGB
            </ToggleButton>
            <ToggleButton value='svg' key='svg'>
              SVG
            </ToggleButton>
          </ToggleButtonGroup>

          <pre>{exportText}</pre>
        </div>
      </div>
    </div>
  );
}

interface IColorShadeCardProps {
  mainColor: string;
  color: IColorShades;
  colorArray: IColorShades[];
}

interface IStyleProps {
  color: string;
  mainColor: string;
}

function ColorShadeCard({ mainColor, color, colorArray }: IColorShadeCardProps) {
  const getTextColor = () => {
    return color.hsl.l < 50 ? colorArray[0].hex : colorArray[colorArray.length - 1].hex;
  };

  const copyHexColor = () => {
    navigator.clipboard.writeText(color.hex.toLocaleUpperCase());
  };

  const useStyles = createUseStyles({
    swatch: {
      position: 'relative',
      '&:after': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 20,
        height: 20,
        borderRadius: '50%',
        backgroundColor: getTextColor(),
        content: ({ color, mainColor }: IStyleProps) => (color.toLowerCase() === mainColor.toLowerCase() ? '""' : ''),
      },
    },
  });
  const classes = useStyles({ color: color.hex, mainColor: mainColor });

  return (
    <>
      <div
        style={{
          width: 100,
          height: 150,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'end',
          boxSizing: 'border-box',
          padding: 16,
          backgroundColor: color.hex,
          color: getTextColor(),
          borderRadius: 10,
          cursor: 'pointer',
        }}
        className={classes.swatch}
        onClick={copyHexColor}
      >
        {color.hex.toLocaleUpperCase()}
      </div>
    </>
  );
}

{
  /* <svg width="1000" height="100" viewBox="0 0 1000 100" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="100" height="100" fill="#fdfcfb" x="0" y="0"></rect>
  <text x="50" y="20" font-family="Arial" text-anchor="middle" font-size="15" fill="#1b1512">#FDFCFB</text>
  <rect width="100" height="100" fill="#e8e1dd" x="100" y="0"></rect>
  <text x="150" y="20" font-family="Arial" text-anchor="middle" font-size="15" fill="#1b1512">#E8E1DD</text>
  <rect width="100" height="100" fill="#d3c6bf" x="200" y="0"></rect>
  <text x="250" y="20" font-family="Arial" text-anchor="middle" font-size="15" fill="#1b1512">#D3C6BF</text>
  <rect width="100" height="100" fill="#bfaaa0" x="300" y="0"></rect>
  <text x="350" y="20" font-family="Arial" text-anchor="middle" font-size="15" fill="#1b1512">#BFAAA0</text>
  <rect width="100" height="100" fill="#aa8f82" x="400" y="0"></rect>
  <text x="450" y="20" font-family="Arial" text-anchor="middle" font-size="15" fill="#fdfcfb">#AA8F82</text>
  <rect width="100" height="100" fill="#947565" x="500" y="0"></rect>
  <text x="550" y="20" font-family="Arial" text-anchor="middle" font-size="15" fill="#fdfcfb">#947565</text>
  <rect width="100" height="100" fill="#765d50" x="600" y="0"></rect>
  <text x="650" y="20" font-family="Arial" text-anchor="middle" font-size="15" fill="#fdfcfb">#765D50</text>
  <rect width="100" height="100" fill="#57453c" x="700" y="0"></rect>
  <text x="750" y="20" font-family="Arial" text-anchor="middle" font-size="15" fill="#fdfcfb">#57453C</text>
  <rect width="100" height="100" fill="#392d27" x="800" y="0"></rect>
  <text x="850" y="20" font-family="Arial" text-anchor="middle" font-size="15" fill="#fdfcfb">#392D27</text>
  <rect width="100" height="100" fill="#1b1512" x="900" y="0"></rect>
  <text x="950" y="20" font-family="Arial" text-anchor="middle" font-size="15" fill="#fdfcfb">#1B1512</text>
</svg> */
}
