import Button from '@mui/material/Button/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { SnackbarKey, useSnackbar, VariantType, WithSnackbarProps } from 'notistack';
import React from 'react';
import { Link } from 'react-router-dom';
import { EDashboardView } from '../../routes/courseDashboard/CourseDashboard';

let useSnackbarRef: WithSnackbarProps;
export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

let copyingSnackbar: SnackbarKey | undefined;
let test: SnackbarKey | undefined;

const closeCopySuccess = (aDelay: number) => {
  setTimeout(() => {
    useSnackbarRef.closeSnackbar(copyingSnackbar);
  }, aDelay);
};

export default {
  success(msg: string, action?: () => void, actionText?: string, duration = 3000) {
    const d = duration === 0 ? null : duration;
    this.toast(msg, 'success', d, action, actionText);
  },
  warning(msg: string, action?: () => void, actionText?: string, duration = 3000) {
    const d = duration === 0 ? null : duration;
    this.toast(msg, 'warning', d, action, actionText);
  },
  info(msg: string, action?: () => void, actionText?: string, duration = 3000) {
    const d = duration === 0 ? null : duration;
    this.toast(msg, 'info', d, action, actionText);
  },
  error(msg: string, action?: () => void, actionText?: string, duration = 3000) {
    const d = duration === 0 ? null : duration;
    this.toast(msg, 'error', d, action, actionText);
  },
  copyingStart(msg: string, action = true) {
    copyingSnackbar = useSnackbarRef.enqueueSnackbar(msg, {
      variant: 'info',
      persist: true,
      action: action ? <CircularProgress sx={{ color: 'white' }} size={20} /> : <></>,
    });
  },
  connectionError(msg: string, action = true) {
    test = useSnackbarRef.enqueueSnackbar(msg, {
      variant: 'error',
      persist: true,
      action: action ? <CircularProgress sx={{ color: 'white' }} size={20} /> : <></>,
    });
  },
  closeConnectionError() {
    if (test !== undefined) {
      useSnackbarRef.closeSnackbar(test);
    }
  },
  copyingClose(aCourseID: number) {
    if (copyingSnackbar !== undefined) {
      useSnackbarRef.closeSnackbar(copyingSnackbar);

      copyingSnackbar = useSnackbarRef.enqueueSnackbar('Copying complete', {
        variant: 'success',
        persist: true,
        action: (
          <>
            <Button variant='newSecondary' sx={{ marginRight: 2 }} onClick={() => closeCopySuccess(300)} style={{ minWidth: 30 }}>
              OK
            </Button>
            <Button variant='newSecondary' component={Link} to={`/course?id=${aCourseID}&view=${EDashboardView.Overview}`} onClick={() => closeCopySuccess(500)}>
              Go To Course
            </Button>
          </>
        ),
      });
    }
  },
  toast(msg: string, variant: VariantType = 'default', duration: number | null, action?: () => void, actionText = 'Click') {
    useSnackbarRef.enqueueSnackbar(msg, {
      variant,
      autoHideDuration: duration,
      action: action ? (
        <Button onClick={action} sx={{ color: 'white' }}>
          {actionText}
        </Button>
      ) : (
        <></>
      ),
    });
  },
};
