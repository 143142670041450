import axios, { AxiosResponse } from 'axios';
import { IVimeoVideo } from '../models/VimeoModels';

const axiosVimeo = axios.create({
  baseURL: 'https://api.vimeo.com',
});

const accessToken = 'db1b50593e2e9fd87a37d3c07a7632e9';

// Add a request interceptor
axiosVimeo.interceptors.request.use(
  function (config) {
    config.headers.authorization = `Bearer ${accessToken}`;
    return config;
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  },
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
  getList: <T>(url: string, query: string, page?: number) =>
    axiosVimeo
      .get<T>(url, {
        params: {
          query: query,
          per_page: 20,
          page: page,
        },
      })
      .then(responseBody),
};

const videos = {
  search: (query: string, page?: number) => requests.getList<IVimeoVideo>('/videos', query, page),
};

const agent = {
  videos,
};

export default agent;
