import { useAuth0 } from '@auth0/auth0-react';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import { useEffect } from 'react';
import { Route, RouteProps } from 'react-router';
import { observer } from 'mobx-react';
import AccessDenied from '../error/AccessDenied';
import { accessToken, setAccessToken } from '../../services/api/agent';
import { useStore } from '../../store/store';
import { ECreatorUserRole } from '../../store/userStore';
import IncorrectRole from '../../routes/auth/IncorrectRole';

export default observer(function AdminProtectedRoute(props: RouteProps) {
  const { userStore } = useStore();
  const { role } = userStore;
  const { loginWithRedirect, isLoading, isAuthenticated: isAuth0Authenticated, error, getAccessTokenSilently } = useAuth0();

  //TODO: Se om man kan ta i bruk localstorage på å ta vare på token.
  useEffect(() => {
    if (isAuth0Authenticated) {
      if (accessToken !== '') {
        const getAccessToken = async () => {
          const accessToken = await getAccessTokenSilently();
          setAccessToken(accessToken);
        };
        getAccessToken();
      }
    }
  }, [isAuth0Authenticated]);

  if (isLoading) {
    return (
      <div style={{ flex: '1 1 auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    if (error.message === 'Unauthorized Account.' || error.message === 'Validate Email.') {
      return <AccessDenied error={error} />;
    }
  }
  if (isAuth0Authenticated) {
    if (role >= ECreatorUserRole.superAdmin) {
      return <Route {...props} />;
    } else {
      return <IncorrectRole />;
    }
  } else {
    loginWithRedirect();
    return <></>;
  }
});
