import { makeAutoObservable, runInAction } from 'mobx';
import vimeoAgent from '../../services/api/vimeoAgent';
import { IVimeoVideo } from '../../services/models/VimeoModels';
import { toTimestamp } from '../../utils/convertions';
import SnackbarUtils from '../../components/snackbarUtils/SnackbarUtilsConfigurator';
import { store } from '../store';

export interface ICreatorVideoModel {
  title: string;
  description: string;
  published: string;
  link: string;
  img: string;
  duration: string;
  views: string;
  user: string;
}

export default class VimeoStore {
  vimeoList: IVimeoVideo | undefined = undefined;
  videoList: ICreatorVideoModel[] = [];
  vimeoCurrentPage = 1;
  searchQuery = '';

  isVimeoOpen = false;
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  searchVimeo = async (query: string, nextPage?: number) => {
    if (nextPage == undefined) {
      this.setLoading(true);
      this.videoList = [];
    }
    this.vimeoList = await vimeoAgent.videos.search(query);
    runInAction(() => {
      this.vimeoList?.data.forEach((video) => {
        const date = new Date(video.release_time);
        const day = date.getDay() < 10 ? '0' + date.getDay() : date.getDay();
        const month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
        this.videoList.push({
          title: video.name.replace(/&quot;/g, '"'),
          description: video.description != null ? video.description.substring(0, 200).replace(/&quot;/g, '"') + '...' : '',
          published: day + '.' + month + '.' + date.getFullYear(),
          link: video.player_embed_url,
          img: video.pictures.sizes[2].link,
          duration: toTimestamp(video.duration),
          views: video.stats.plays,
          user: video.user.name,
        });
      });
      this.vimeoList = undefined;
    });
    this.setLoading(false);
  };

  setSearchQuery = (aString: string) => {
    this.searchQuery = aString;
  };

  getNextPage = () => {
    this.searchVimeo(this.searchQuery, this.vimeoCurrentPage + 1);
  };

  createVideoFromEmbed = (embedCode: string) => {
    console.log('123123');
    const regEx = new RegExp(/src\s*=\s*['"]?([^'"\s>]+)/i);
    const source = regEx.exec(embedCode);
    if (!embedCode.includes('vimeo')) {
      SnackbarUtils.warning('Embed does not appear to be a vimeo embed.');
    } else {
      if (source != null) {
        store.pageStore.createEmbedOnlineVideo(source[1]);
        this.closeVimeo();
      } else {
        if (!embedCode.includes('src')) {
          SnackbarUtils.warning('Could not find src in embed code. Try again.');
        }
      }
    }
  };

  openVimeo = () => {
    this.isVimeoOpen = true;
  };

  closeVimeo = () => {
    this.isVimeoOpen = false;
    this.vimeoList = undefined;
    this.setLoading(false);
    this.setSearchQuery('');
  };

  setLoading = (state: boolean) => {
    this.loading = state;
  };
}
