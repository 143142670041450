/* eslint-disable @typescript-eslint/no-this-alias */

export enum EResizeEdge {
  None = 0,
  Left,
  Right,
  Top,
  Bottom,
}

export interface IResizeEdgeObject {
  value: EResizeEdge;
  cursor: string;
}

export interface IResizeEdge {
  None: IResizeEdgeObject;
  Left: IResizeEdgeObject;
  Top: IResizeEdgeObject;
  Right: IResizeEdgeObject;
  Bottom: IResizeEdgeObject;
}

export const ResizeEdge: IResizeEdge = {
  None: { value: EResizeEdge.None, cursor: 'default' },
  Left: { value: EResizeEdge.Left, cursor: 'e-resize' },
  Top: { value: EResizeEdge.Top, cursor: 'n-resize' },
  Right: { value: EResizeEdge.Right, cursor: 'w-resize' },
  Bottom: { value: EResizeEdge.Bottom, cursor: 's-resize' },
};

export type FResizeFunc = (aEvent: any, aResizeEdge: IResizeEdgeObject) => void;

export interface IPos {
  x: number;
  y: number;

  update(aX: number, aY: number): void;
}

export const mousePos: IPos = {
  x: 0,
  y: 0,
  update(aX: number, aY: number) {
    this.x = aX;
    this.y = aY;
  },
};

export class TRect {
  public left = 0;
  public top = 0;
  public width = 0;
  public height = 0;
  public right = 0;
  public bottom = 0;

  constructor(aX = 0, aY = 0, aWidth = 0, aHeight = 0) {
    this.update(aX, aY, aWidth, aHeight);
  }

  public update = (aX: number, aY: number, aWidth: number, aHeight: number): void => {
    this.left = aX;
    this.top = aY;
    this.width = aWidth;
    this.height = aHeight;
    this.right = this.left + this.width;
    this.bottom = this.top + this.height;
  };

  public setLeft(aX: number): void {
    this.left = aX;
    this.width = this.right - this.left;
  }

  public setTop(aY: number): void {
    this.top = aY;
    this.height = this.bottom - this.top;
  }

  setRight(aX: number): void {
    this.right = aX;
    this.width = this.right - this.left;
  }

  setBottom(aY: number): void {
    this.bottom = aY;
    this.height = this.bottom - this.top;
  }
}

class TResizeObject {
  public readonly resizeEdge: IResizeEdgeObject;
  public readonly element: TLayoutElement;

  constructor(aElement: TLayoutElement, aResizeEdge: IResizeEdgeObject) {
    this.element = aElement;
    this.resizeEdge = aResizeEdge;
  }

  public resize(aEvent: any): void {
    this.element.callLayoutObjectResize(aEvent, this.resizeEdge);
  }
}

export class TLayoutElement {
  public readonly rect: TRect;
  //public readonly resizeFunc: FResizeFunc;
  private readonly leLeft: Array<TResizeObject> = [];
  private readonly leRight: Array<TResizeObject> = [];
  private readonly leTop: Array<TResizeObject> = [];
  private readonly leBottom: Array<TResizeObject> = [];
  private readonly layoutObject: any;

  constructor(aRect: TRect /*, aResizeFunc: FResizeFunc*/, aLayoutObject: any) {
    this.rect = aRect;
    //this.resizeFunc = aResizeFunc;
    this.layoutObject = aLayoutObject;
  }

  public callLayoutObjectResize(aEvent: any, aResizeEdge: IResizeEdgeObject): void {
    if (this.layoutObject.resize) {
      this.layoutObject.resize(aEvent, aResizeEdge);
    }
  }

  public resize(aEvent: any, aResizeEdge: IResizeEdgeObject): void {
    switch (aResizeEdge.value) {
      case EResizeEdge.Right:
        //this.rect.setRight(mousePos.x);
        for (let i = 0; i < this.leRight.length; i++) {
          this.leRight[i].resize(aEvent);
          //this.leRight[i].element.rect.setLeft(mousePos.x);
        }
        break;
      case EResizeEdge.Left:
        //this.rect.setLeft(mousePos.x);
        for (let i = 0; i < this.leLeft.length; i++) {
          this.leLeft[i].resize(aEvent);
          //this.leLeft[i].element.rect().setRight(mousePos.x);
        }
        break;
      case EResizeEdge.Top:
        for (let i = 0; i < this.leTop.length; i++) {
          this.leTop[i].resize(aEvent);
        }
        break;
      case EResizeEdge.Bottom:
        for (let i = 0; i < this.leBottom.length; i++) {
          this.leBottom[i].resize(aEvent);
        }
        break;
    }
  }

  public setLayoutElement(aElement: TLayoutElement): void {
    //New Element
    aElement.createResizeObject(this);
  }

  public createResizeObject(aElement: TLayoutElement): void {
    //Old element against new element. aRect new, rect old
    const element: TLayoutElement = this;
    const lim = 20;
    const anchorTopBottom = Math.abs(aElement.rect.top - element.rect.bottom) < lim;
    const anchorBottomTop = Math.abs(aElement.rect.bottom - element.rect.top) < lim;
    const anchorLeftRight = Math.abs(aElement.rect.left - element.rect.right) < lim;
    const anchorRightLeft = Math.abs(aElement.rect.right - element.rect.left) < lim;
    //Sjekker vertikale kanter
    if (anchorLeftRight && !(anchorBottomTop || anchorTopBottom)) {
      aElement.addResizeObject(new TResizeObject(element, ResizeEdge.Right), ResizeEdge.Left);
      element.addResizeObject(new TResizeObject(aElement, ResizeEdge.Left), ResizeEdge.Right);
    } else if (anchorRightLeft && !(anchorBottomTop || anchorTopBottom)) {
      aElement.addResizeObject(new TResizeObject(element, ResizeEdge.Left), ResizeEdge.Right);
      element.addResizeObject(new TResizeObject(aElement, ResizeEdge.Right), ResizeEdge.Left);
    }

    //Sjekker horisontale kanter

    if (anchorTopBottom && !(anchorLeftRight || anchorRightLeft)) {
      aElement.addResizeObject(new TResizeObject(element, ResizeEdge.Bottom), ResizeEdge.Top);
      element.addResizeObject(new TResizeObject(aElement, ResizeEdge.Top), ResizeEdge.Bottom);
    } else if (anchorBottomTop && !(anchorLeftRight || anchorRightLeft)) {
      aElement.addResizeObject(new TResizeObject(element, ResizeEdge.Top), ResizeEdge.Bottom);
      element.addResizeObject(new TResizeObject(aElement, ResizeEdge.Bottom), ResizeEdge.Top);
    }
    /*    
    if ((!anchorLeftRight && anchorTopBottom) || (!anchorLeftRight && anchorBottomTop)) {
      if (anchorTopBottom) {
        aElement.addResizeObject(new TResizeObject(element, ResizeEdge.Bottom), ResizeEdge.Top);
        element.addResizeObject(new TResizeObject(aElement, ResizeEdge.Top), ResizeEdge.Bottom);
      } else {
        aElement.addResizeObject(new TResizeObject(element, ResizeEdge.Top), ResizeEdge.Bottom);
        element.addResizeObject(new TResizeObject(aElement, ResizeEdge.Bottom), ResizeEdge.Top);
      }
    }
    */
  }

  public addResizeObject(aResizeObject: TResizeObject, aResizeEdge: IResizeEdgeObject) {
    switch (aResizeEdge) {
      case ResizeEdge.Left:
        this.leLeft.push(aResizeObject);
        break;
      case ResizeEdge.Right:
        this.leRight.push(aResizeObject);
        break;
      case ResizeEdge.Top:
        this.leTop.push(aResizeObject);
        break;
      case ResizeEdge.Bottom:
        this.leBottom.push(aResizeObject);
        break;
    }
  }

  public checkShareEdges(): void {
    if (this.leLeft.length > 1) {
      TLayoutElement.addShareEdge(this.leLeft, ResizeEdge.Right);
    }
    if (this.leRight.length > 1) {
      TLayoutElement.addShareEdge(this.leRight, ResizeEdge.Left);
    }
    if (this.leTop.length > 1) {
      TLayoutElement.addShareEdge(this.leTop, ResizeEdge.Bottom);
    }
    if (this.leBottom.length > 1) {
      TLayoutElement.addShareEdge(this.leBottom, ResizeEdge.Top);
    }
  }

  private static addShareEdge(aArray: TResizeObject[], aResizeEdge: IResizeEdgeObject) {
    for (let j = 0; j < aArray.length; j++) {
      const shareResizeObject: TResizeObject = aArray[j];
      let newResizeObject = null;
      for (let i = 0; i < aArray.length; i++) {
        if (shareResizeObject !== aArray[i]) {
          const isSharing = aArray[i].element.hasSharedElement(shareResizeObject.element, aArray[i].resizeEdge);
          if (!isSharing) {
            if (newResizeObject === null) {
              newResizeObject = new TResizeObject(shareResizeObject.element, shareResizeObject.resizeEdge);
            }
            aArray[i].element.addResizeObject(newResizeObject, aResizeEdge);
          }
        }
      }
    }
  }

  public hasSharedElement(aElement: TLayoutElement, aResizeEdge: IResizeEdgeObject): boolean {
    let leArray = null;
    switch (aResizeEdge) {
      case ResizeEdge.Left:
        leArray = this.leLeft;
        aResizeEdge = ResizeEdge.Right;
        break;
      case ResizeEdge.Right:
        leArray = this.leRight;
        aResizeEdge = ResizeEdge.Left;
        break;
      case ResizeEdge.Top:
        leArray = this.leTop;
        aResizeEdge = ResizeEdge.Bottom;
        break;
      case ResizeEdge.Bottom:
        leArray = this.leBottom;
        aResizeEdge = ResizeEdge.Top;
        break;
    }
    if (leArray !== null) {
      for (let i = 0; i < leArray.length; i++) {
        if (leArray[i].element === aElement) {
          return true;
        }
      }
    }
    return false;
  }

  public clearLayout(): void {
    this.leLeft.length = 0;
    this.leRight.length = 0;
    this.leTop.length = 0;
    this.leBottom.length = 0;
  }
}

const elements = Array<TLayoutElement>();
const layoutRect: TRect = new TRect();

export function createElement(aRect: TRect, aLayoutElement: any): TLayoutElement {
  const newElement = new TLayoutElement(aRect, aLayoutElement);
  elements.push(newElement);
  return newElement;
}

export function updateLayout(aX: number, aY: number, aWidth: number, aHeight: number) {
  layoutRect.update(aX, aY, aWidth, aHeight);

  for (let i = 0; i < elements.length; i++) {
    elements[i].clearLayout();
  }
  for (let i = 1; i < elements.length; i++) {
    const element = elements[i];
    for (let j = 0; j < i; j++) {
      element.setLayoutElement(elements[j]);
    }
  }

  for (let i = 0; i < elements.length; i++) {
    elements[i].checkShareEdges();
  }
}
