import React from 'react';
import IconButton from '@mui/material/IconButton';
import PreviewIcon from '@mui/icons-material/Preview';
import ShareIcon from '@mui/icons-material/Share';
import Tooltip from '@mui/material/Tooltip';

interface PreviewButtonsProps {
  editMode: boolean;
  openPagePreview: () => void;
  openCoursePreview: () => void;
  sharePreview: () => void;
}

const PreviewButtons = ({ editMode, openPagePreview, openCoursePreview, sharePreview }: PreviewButtonsProps) => {
  return (
    <div>
      <Tooltip title={editMode ? 'Preview Page' : 'Preview Course'}>
        <IconButton size='small' onClick={editMode ? openPagePreview : openCoursePreview}>
          <PreviewIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={'Get shareable preview link'}>
        <IconButton size='small' onClick={sharePreview}>
          <ShareIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default PreviewButtons;
