export const convertISO8601 = (duration: string): string => {
  const time_extractor = /^P([0-9]*D)?T([0-9]*H)?([0-9]*M)?([0-9]*S)?$/i;
  const extracted = time_extractor.exec(duration);
  if (extracted) {
    const days = parseInt(extracted[1], 10) || 0;
    const hours = parseInt(extracted[2], 10) || 0;
    const minutes = parseInt(extracted[3], 10) || 0;
    const seconds = parseInt(extracted[4], 10) || 0;
    if (days != 0) {
      return `${days}:${hours}:${minutes}:${seconds}`;
    } else if (hours != 0) {
      return `${hours}:${minutes}:${seconds}`;
    } else if (minutes != 0) {
      return `${minutes}:${seconds}`;
    } else {
      return `${seconds}`;
    }
  }
  return '0';
};

export const toTimestamp = (duration: string | number): string => {
  // Convert to number if the input is a string, ignoring anything after a decimal point (milliseconds)
  const sec_num = typeof duration === 'string' ? Math.floor(parseFloat(duration)) : Math.floor(duration);

  // Ensure the input is a valid number
  if (isNaN(sec_num)) {
    return '00:00'; // Return a default value if it's not a valid number
  }

  let hours: string | number = Math.floor(sec_num / 3600);
  let minutes: string | number = Math.floor((sec_num - hours * 3600) / 60);
  let seconds: string | number = sec_num - hours * 3600 - minutes * 60;

  // Format hours, minutes, and seconds
  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  // Only include hours if greater than 0
  const hoursStr = hours !== '00' ? hours + ':' : '';

  return hoursStr + minutes + ':' + seconds;
};

export const fromTimestamp = (timestamp: string) => {
  const a: string[] = timestamp.split(':');
  let seconds = 0;

  for (let i = 0; i < a.length; i++) {
    try {
      seconds += parseInt(a[i]) * 60 ** (Math.abs(i - a.length) - 1);
    } catch {
      console.log('unable to parse ', a[i]);
      seconds += 0;
    }
  }

  return seconds;
};
