import Box from '@mui/material/Box/Box';
import Typography from '@mui/material/Typography/Typography';
import { observer } from 'mobx-react';
import React from 'react';
import { TStaticInfo } from '../../../services/models/OwnerViewModel';
import { coursePageStyles } from './CoursePageStyle';
import useTheme from '@mui/material/styles/useTheme';

interface ChapterProps {
  title: TStaticInfo;
}

export default observer(function CoursePageItemCompact({ title }: ChapterProps) {
  const theme = useTheme();
  const classes = coursePageStyles({ theme });

  return (
    <Box display='flex' alignItems='center' style={{ width: '100%', paddingLeft: 10, backgroundColor: 'transparent', height: '40px' }}>
      <Typography variant='body2' className={classes.title} noWrap>
        {title.value}
      </Typography>
    </Box>
  );
});
