import React, { useEffect, useState } from 'react';
import ViewWrapper from '../ViewWrapper';
import { useTheme } from '@mui/material/styles';
import { TCourseViewModel, TTreeItemDataViewModel } from '../../../../services/models/CourseViewModels';
import { ICourseProperties } from '../../../../services/models/PropertyViewModel';
import { createUseStyles } from 'react-jss';
import { useStore } from '../../../../store/store';
import { ItemId, TreeData, TreeItem } from '@atlaskit/tree/dist/types/types';
import Tree, { RenderItemParams } from '@atlaskit/tree';
import TextField from '@mui/material/TextField/TextField';
import Button from '@mui/material/Button';
import { Link, useHistory } from 'react-router-dom';
import { EDashboardView } from '../../CourseDashboard';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import { Hidden, useMediaQuery } from '@mui/material';

interface IDeleteCourseViewProps {
  courseViewModel: TCourseViewModel;
  courseProperties: ICourseProperties;
}

export default function DeleteCourseView({ courseViewModel, courseProperties }: IDeleteCourseViewProps) {
  const { userStore, ownerStore } = useStore();
  const { user } = userStore;
  const { deleteCourse } = ownerStore;
  const [treeData, setTreeData] = useState<TreeData>(JSON.parse(JSON.stringify(courseViewModel.pages)));
  const [disabled, setDisabled] = useState(true);
  const [value, setValue] = useState('');
  const theme = useTheme();
  const history = useHistory();
  const mediaQueryBreakpoint = useMediaQuery(theme.breakpoints.down('xl'));

  const useStyles = createUseStyles({
    section: {
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'space-between',
      fontSize: 14,
    },
    inputField: () => ({
      '& .MuiInputBase-root': {
        outline: 'none',
        outlineWidth: 0,
        borderRadius: 4,
        backgroundColor: theme.palette.bgFive.main,
        '& fieldset': {
          borderColor: 'transparent',
        },
        '&:hover fieldset': {
          borderColor: 'transparent',
        },
        '&:focus fieldset': {
          borderColor: 'transparent',
        },
        '&:active fieldset': {
          borderColor: 'transparent',
        },
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'transparent',
        },
        '&:hover fieldset': {
          borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'transparent',
        },
      },
    }),
    treeItem: {
      marginBottom: 8,
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
  });
  const classes = useStyles();

  const handleDete = () => {
    deleteCourse(courseViewModel.courseID).then(() => {
      history.push('/');
    });
  };

  const handleTextChange = (e: any) => {
    setValue(e.target.value);

    if (e.target.value === 'Delete') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const renderItem = ({ item, provided }: RenderItemParams) => {
    const data: TTreeItemDataViewModel = item.data;
    item.isExpanded = true;
    return (
      <div ref={provided.innerRef} {...provided.draggableProps} className={classes.treeItem}>
        <div style={{ backgroundColor: theme.palette.bgFour.main, padding: 12, borderRadius: 16 }}>{data.title.value}</div>
      </div>
    );
  };

  const expandAllItems = (items: Record<ItemId, TreeItem>) => {
    const updatedItems = { ...items };

    Object.keys(updatedItems).forEach((key) => {
      const item = updatedItems[key];

      // Set isExpanded to true for each item
      item.isExpanded = true;

      // If the item has children, expand those children as well
      if (item.hasChildren && item.children && item.children.length > 0) {
        item.children.forEach((childId) => {
          if (updatedItems[childId]) {
            updatedItems[childId].isExpanded = true; // Ensure child is also expanded
          }
        });
      }
    });

    return updatedItems;
  };

  useEffect(() => {
    setTreeData((prevPages) => ({
      ...prevPages,
      items: expandAllItems(prevPages.items),
    }));
  }, []);

  return (
    <>
      <ViewWrapper title={'Delete Course'}>
        <div style={{ backgroundColor: theme.palette.bgTwo.main, display: 'flex', flex: '1 1 auto', flexDirection: 'column' }}>
          <div style={{ boxSizing: 'border-box', fontSize: 24, fontWeight: '400', padding: '24px 24px', flexBasis: 77, maxHeight: 77 }}>You are about to delete the following course</div>
          <div
            style={{
              display: 'flex',
              flexDirection: !mediaQueryBreakpoint ? 'row' : 'column',
              flexGrow: '1',
              gap: theme.spacing(2),
              padding: theme.spacing(3),
              paddingBottom: theme.spacing(3),
              paddingTop: theme.spacing(0),
              overflow: 'auto',
            }}
          >
            <div style={{ display: 'flex', flex: '1', gap: theme.spacing(2), maxHeight: !mediaQueryBreakpoint ? '100%' : 'unset', minWidth: 450 }}>
              <div style={{ flex: '1', display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
                <img src={courseViewModel.avatar} alt='course avatar' style={{ width: '100%', borderRadius: 8 }} />
                <div
                  style={{
                    display: 'flex',
                    flex: '1 1 auto',
                    flexDirection: 'column',
                    gap: theme.spacing(1),
                    backgroundColor: theme.palette.bgOne.main,
                    borderRadius: 8,
                    padding: theme.spacing(1),
                    overflow: 'auto',
                  }}
                >
                  <div className={classes.section}>
                    <div>Created by:</div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                      {user?.picture && <img src={user.picture} alt='' height='18px' width='18px' style={{ borderRadius: '100%', objectFit: 'cover' }} />}
                      <div>{courseProperties.descriptions.items.createdBy.option.value}</div>
                    </div>
                  </div>
                  <div className={classes.section}>
                    <div>Created:</div>
                    <div>{courseViewModel.createdDate}</div>
                  </div>
                  <div className={classes.section}>
                    <div>Modified:</div>
                    <div>{courseViewModel.modifiedDate}</div>
                  </div>
                  {/*                   <div className={classes.section}>
                    <div>Co Editors:</div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
                        {user?.picture && <img src={user.picture} alt='' height='18px' width='18px' style={{ borderRadius: '100%', objectFit: 'cover' }} />}
                        <div>{courseProperties.descriptions.items.createdBy.option.value}</div>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
                        {user?.picture && <img src={user.picture} alt='' height='18px' width='18px' style={{ borderRadius: '100%', objectFit: 'cover' }} />}
                        <div>{courseProperties.descriptions.items.createdBy.option.value}</div>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
                        {user?.picture && <img src={user.picture} alt='' height='18px' width='18px' style={{ borderRadius: '100%', objectFit: 'cover' }} />}
                        <div>{courseProperties.descriptions.items.createdBy.option.value}</div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.section}>
                    <div>Reviewers:</div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
                        {user?.picture && <img src={user.picture} alt='' height='18px' width='18px' style={{ borderRadius: '100%', objectFit: 'cover' }} />}
                        <div>{courseProperties.descriptions.items.createdBy.option.value}</div>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
                        {user?.picture && <img src={user.picture} alt='' height='18px' width='18px' style={{ borderRadius: '100%', objectFit: 'cover' }} />}
                        <div>{courseProperties.descriptions.items.createdBy.option.value}</div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div style={{ flex: '1', display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}>
                <div style={{ fontSize: 20, fontWeight: '500', wordWrap: 'break-word' }}>{courseViewModel.title}</div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.spacing(1),
                    backgroundColor: theme.palette.bgOne.main,
                    borderRadius: 8,
                    padding: theme.spacing(1),
                    fontSize: 14,
                    fontWeight: '400',
                    wordWrap: 'break-word',
                    minHeight: 155,
                  }}
                >
                  {courseViewModel.description}
                </div>
              </div>
            </div>
            <div style={{ flex: '1', display: 'flex', flexDirection: 'column', gap: theme.spacing(1), minWidth: 450, height: !mediaQueryBreakpoint ? '100%' : 'auto' }}>
              <div style={{ fontSize: 20, fontWeight: '500', wordWrap: 'break-word' }}>Course Content</div>
              <div
                style={{
                  flex: '1 1 auto',
                  paddingTop: theme.spacing(1),
                  paddingBottom: theme.spacing(1),
                  paddingLeft: theme.spacing(3),
                  paddingRight: theme.spacing(3),
                  background: theme.palette.bgOne.main,
                  gap: 8,
                  borderRadius: 8,
                  overflow: !mediaQueryBreakpoint ? 'auto' : 'unset',
                }}
              >
                <Tree tree={treeData} renderItem={renderItem} isDragEnabled={false} isNestingEnabled offsetPerLevel={60} />
              </div>
            </div>
          </div>
          <div style={{ backgroundColor: theme.palette.bgOne.main, display: 'flex', justifyContent: 'center', flex: '1', padding: theme.spacing(4) }}>
            <div style={{ flexGrow: 2, display: 'flex', flexDirection: 'column', gap: theme.spacing(2), maxWidth: 'min-content' }}>
              <h3 style={{ margin: 0 }}>{'Type "Delete" into the field to confirm.'}</h3>

              <div style={{ display: 'flex', gap: theme.spacing(2) }}>
                <TextField className={classes.inputField} placeholder='Delete' style={{ width: 300 }} value={value} onChange={handleTextChange} />
                <Tooltip title='Type "Delete" into the field to confirm.' aria-label='Delete course confirmation input field' placement='top' arrow disableHoverListener={!disabled}>
                  <div style={{ height: '100%' }}>
                    <Button
                      variant='contained'
                      color='error'
                      aria-label='Delete course confirmation button'
                      disabled={disabled}
                      style={{ padding: 8, height: '100%', minWidth: 170 }}
                      onClick={handleDete}
                    >
                      DELETE COURSE
                    </Button>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </ViewWrapper>
    </>
  );
}
