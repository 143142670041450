import React, { useState, useRef, useEffect } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import { TCourseViewModel, TTreeItemDataViewModel } from '../../../services/models/CourseViewModels';
import CourseView from '../../../routes/courseDashboard/CoursePages/CoursePageList';
import Title from '../../titleAndInput/Title';
import { createUseStyles } from 'react-jss';
import PageStatus from '../../../features/courseEdit/pageStatusIndicator/PageStatus';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useStore } from '../../../store/store';
import { observer } from 'mobx-react';
import { useSnackbar } from 'notistack';
import Popper from '@mui/material/Popper/Popper';
import Paper from '@mui/material/Paper/Paper';
import Typography from '@mui/material/Typography/Typography';
import TextField from '@mui/material/TextField/TextField';
import Button from '@mui/material/Button/Button';

interface PageNavigationProps {
  previousPage?: number;
  nextPage?: number;
  currentPage?: TTreeItemDataViewModel;
  courseViewModel: TCourseViewModel | undefined;
  pageIDParam: number | undefined;
  pageStateParam: number | undefined;
  setPageIDParam: (n: number | undefined) => void;
  setPageStateParam: (n: number | undefined) => void;
}

const useStyles = createUseStyles((theme: Theme) => ({
  paperTest: {
    minWidth: 500,
    borderRadius: 0,
    background: theme.palette.bgOne.main,
    color: theme.palette.bgOne.contrastText,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    marginLeft: theme.spacing(2),
  },
  tooltip: {
    fontSize: '0.9em',
  },
}));

export default observer(function PageNavigation({ previousPage, nextPage, currentPage, courseViewModel, pageIDParam, pageStateParam, setPageIDParam, setPageStateParam }: PageNavigationProps) {
  const { courseStore } = useStore();
  const { newEmptyPage } = courseStore;
  const theme = useTheme();
  const classes = useStyles({ theme });
  const divRef = useRef<HTMLDivElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [popperOpen, setPopperOpen] = React.useState(false);
  const [popperAnchorEl, setPopperAnchorEl] = React.useState<null | HTMLElement>(null);
  const [newName, setNewName] = useState('');

  const changePage = (pageID?: number) => {
    if (pageID == undefined) return;
    if (pageStateParam) setPageStateParam(undefined);
    setPageIDParam(pageID);
  };

  const handlePreviousPage = () => changePage(previousPage);
  const handleNextPage = () => changePage(nextPage);

  const handleTogglePopover = () => setOpen(!open);
  const handleClosePopover = () => setOpen(false);

  const createLoadNextPage = async () => {
    if (courseViewModel !== undefined) {
      handleClosePopper();
      const page = await newEmptyPage(Object.keys(courseViewModel.pages.items).length, undefined, newName.length > 0 ? newName : undefined);
      setNewName('');
      if (page !== undefined) {
        changePage(page.coursePageID);
      }
    }
  };

  const emptyFunc = () => {
    return;
  };

  const popperHandleClick = () => {
    setPopperAnchorEl(anchorRef.current);
    setPopperOpen(true);
  };

  const handleOnClickNewPage = (e: any) => {
    if (e.keyCode === 13) {
      createLoadNextPage();
    }
  };

  const handleClosePopper = () => {
    setPopperOpen(false);
  };

  useEffect(() => {
    const handlePageUpDown = (e: any) => {
      if (e.keyCode == 33) {
        //Previous page
        if (previousPage) {
          changePage(previousPage);
        } else {
          enqueueSnackbar('You are on the first page', { variant: 'info' });
        }
      } else if (e.keyCode == 34) {
        //Next page
        if (nextPage) {
          changePage(nextPage);
        } else {
          enqueueSnackbar('You are on the last page', { variant: 'info' });
        }
      }
    };

    window.addEventListener('keydown', handlePageUpDown, { passive: false });
    return () => {
      window.removeEventListener('keydown', handlePageUpDown);
    };
  }, [previousPage, nextPage]);

  return (
    <>
      <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center', marginRight: 24 }}>
        <div style={{ minWidth: 35, alignSelf: 'end' }}>
          <IconButton disabled={previousPage === undefined} size='small' onClick={handlePreviousPage}>
            <ArrowBackIosNewIcon />
          </IconButton>
        </div>
        <div ref={divRef} style={{ position: 'relative', display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: 500, marginLeft: 20, marginRight: 20 }}>
          {currentPage !== undefined && (
            <>
              <PageStatus />
              <Title data={currentPage} className={classes.title} topBar />
              <IconButton size='small' style={{ color: 'inherit' }} onClick={handleTogglePopover}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </>
          )}
          <Popover
            open={open}
            anchorEl={divRef.current}
            onClose={handleClosePopover}
            PaperProps={{ className: classes.paperTest }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            {courseViewModel && (
              <CourseView
                pageID={pageIDParam ? pageIDParam : 0}
                pages={courseViewModel.pages}
                height={500}
                compact
                handleClose={handleClosePopover}
                handleNewPageItem={emptyFunc}
                handleCopyPageItem={emptyFunc}
                handlePastePage={emptyFunc}
                handleDuplicatePageItem={emptyFunc}
              />
            )}
          </Popover>
        </div>
        <div ref={anchorRef} style={{ minWidth: 35 }}>
          {nextPage !== undefined ? (
            <IconButton size='small' onClick={handleNextPage}>
              <ArrowForwardIosIcon />
            </IconButton>
          ) : (
            <Tooltip title='Create New Page' classes={{ tooltip: classes.tooltip }}>
              <IconButton size='small' onClick={popperHandleClick}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      <Popper
        open={popperOpen}
        anchorEl={popperAnchorEl}
        placement='top'
        sx={{
          zIndex: 10000,
        }}
      >
        <Paper elevation={5} sx={{ background: theme.palette.bgFour.main, padding: theme.spacing(2) }}>
          <Typography variant='h6'>Page name</Typography>
          <TextField variant={'standard'} placeholder={'New Page'} value={newName} onChange={(e) => setNewName(e.target.value)} fullWidth onKeyUp={(e: any) => handleOnClickNewPage(e)} />
          <div style={{ marginTop: theme.spacing(2), display: 'flex', justifyContent: 'end' }}>
            <Button onClick={handleClosePopper} variant='newSecondaryText'>
              Cancel
            </Button>
            <Button onClick={createLoadNextPage} variant='contained' color='primary'>
              OK
            </Button>
          </div>
        </Paper>
      </Popper>
    </>
  );
});
