import { ItemId, TreeData } from '@atlaskit/tree/dist/types/types';
import { TreeItem } from '../../routes/courseDashboard/CoursePages/CoursePageList';

export const getParent = (treeData: TreeData, parentID: ItemId) => {
  if (treeData.items[parentID] != null) {
    return treeData.items[parentID];
  }
  return null;
};

export const checkNumberOfParents = (treeData: TreeData, destinationParentID: ItemId) => {
  let count = 0;
  let notNull = true;
  let parent: TreeItem | null = getParent(treeData, destinationParentID);
  while (notNull) {
    if (parent != null && parent.data) {
      count++;
      const newParent = getParent(treeData, parent.data.parentCoursePageID);
      if (newParent != null) {
        parent = newParent;
      } else {
        notNull = false;
      }
    } else {
      break;
    }
  }
  return count;
};

export const checkNumberOfChildren = (treeData: TreeData, currentItem: ItemId) => {
  const hasChildren = treeData.items[currentItem].hasChildren;
  let childrenCount = 0;

  if (hasChildren) {
    childrenCount = 1;
    for (let i = 0; i < treeData.items[currentItem].children.length; i++) {
      const childID = treeData.items[currentItem].children[i];
      if (treeData.items[childID].hasChildren) {
        const totalChildren = checkNumberOfChildren(treeData, childID);
        childrenCount += totalChildren;
        return childrenCount;
      }
    }
  }
  return childrenCount;
};
