import React, { useEffect, useRef, useState } from 'react';
import { PinturaEditor } from '@pqina/react-pintura';
import { createDefaultImageWriter, createNode, getEditorDefaults } from '@pqina/pintura';
import '@pqina/pintura/pintura.css';
import { hexToRGBAArray } from '../../utils/HexToRGBA';
import { useTheme } from '@mui/material/styles';
import { useStore } from '../../store/store';
import Button from '@mui/material/Button/Button';
import ButtonGroup from '@mui/material/ButtonGroup/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper/Paper';
import Typography from '@mui/material/Typography/Typography';
import { Popper, TextField } from '@mui/material';

import { setPlugins, imageStateToCanvas } from '@pqina/pintura';

import { plugin_trim_locale_en_gb, plugin_trim, createDefaultVideoWriter, createMediaStreamEncoder } from '@pqina/pintura-video';
import '@pqina/pintura-video/pinturavideo.css';
import fixWebmDuration from 'webm-duration-fix';
import { MediaType } from '../../store/media/imageEditorStore';

// Load the Trim plugin view
setPlugins(plugin_trim);

export default function VideoEditor() {
  const { imageEditorStore, mediaLibraryStore, userStore } = useStore();
  const { editorSource: imageEditorSource, closeVideoEditor, saveImage, imageFileName } = imageEditorStore;
  const { currentFolderPath, getFilesInFolder } = mediaLibraryStore;
  const { metadata } = userStore;
  const [fileName, setFileName] = useState('');
  const [hasLoaded, setHasLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const editorRef = useRef<PinturaEditor>(null);
  const [popperOpen, setPopperOpen] = React.useState(false);
  const [popperAnchorEl, setPopperAnchorEl] = React.useState<null | HTMLElement>(null);
  const [newName, setNewName] = useState('');

  const editorDefaults = getEditorDefaults({
    locale: {
      // Add the Trim plugin locale
      ...plugin_trim_locale_en_gb,
    },
    imageWriter: createDefaultVideoWriter({
      // Use media stream encoder

      // Use media stream encoder
      encoder: createMediaStreamEncoder({
        // Required
        imageStateToCanvas,

        // Force a mime type, please note that this won't work on each browser.
        // By default this property is undefined and Pintura will search a mime type.
        mimeType: 'video/webm;codecs=h264,opus',

        audioBitrate: 48000,
        videoBitrate: 4000000,
        framesPerSecond: 30,

        // If the `mimeType` property isn't the `mimeTypes` property allows us to define a list of mime types to test in order.
        // These are the default values.
        mimeTypes: [
          // MP4 if at all possible
          'video/mp4;codecs=avc1,aac',
          'video/mp4;codecs=avc,aac',
          'video/mp4;codecs=mp4a,aac',
          'video/mp4;codecs=h264,aac',
          'video/mp4',

          // WEBM will usually work
          'video/webm;codecs=vp9,opus',
          'video/webm;codecs=vp8,opus',
          'video/webm;codecs=h264,opus',
          'video/webm',

          // Matroska fallback for Linux
          'video/x-matroska;codecs=avc1,opus',
          'video/x-matroska;codecs=vp9,opus',
          'video/x-matroska;codecs=vp8,opus',
          'video/x-matroska',
        ],
      }),
    }),
  });

  const onSaveHandler = async (newName?: string) => {
    setPopperOpen(false);
    editorRef.current?.editor
      .processImage()
      .then(async (tmp) => {
        const file = await fixWebmDuration(tmp.dest);
        let cleanName = tmp.dest.name;
        if (tmp.dest.name.includes('?')) {
          cleanName = tmp.dest.name.substring(0, tmp.dest.name.lastIndexOf('?'));
        }
        const extension = /.[0-9a-z]+$/.exec(cleanName);
        let newFileName = imageFileName.length < 1 ? cleanName.replace(/\?.*$/, '') : imageFileName;
        newFileName = newFileName.replace(/\.[^.]*$/, '');
        if (newName && newName !== '') {
          newFileName = newName;
        }
        if (extension) {
          if (!newFileName.includes(extension[0])) {
            newFileName += extension[0];
          }
          const renamedFile = new File([file], newFileName);
          saveImage(renamedFile, MediaType.Video);
          getFilesInFolder(currentFolderPath);
        }
        closeVideoEditor();
      })
      .catch(() => {
        closeVideoEditor();
      });
  };
  const handleOnClickEnter = (e: any) => {
    if (e.keyCode === 13) {
      onSaveHandler(newName);
    }
  };

  const popperHandleClick = () => {
    setPopperAnchorEl(anchorRef.current);
    setPopperOpen(true);
  };

  const willRenderToolbar = (toolbar: any) => {
    return [toolbar[0], toolbar[1], createNode('div', 'my-div', { textContent: '' })];
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClickAwayMenu = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const handleClosePopper = () => {
    setPopperAnchorEl(null);
    setOpen(false);
  };

  useEffect(() => {
    const tmpName = imageEditorSource.split('\\')?.pop()?.split('/').pop();
    setFileName(tmpName ? tmpName : 'image');
    const element = document.querySelector('.pintura-editor') as HTMLElement | null;

    if (element) {
      //TODO: Dette må få en bedre permanent fix etterhvert.
      if (!metadata.darkMode) {
        const foregroundColor = hexToRGBAArray('#000000');
        const backgroundColor = hexToRGBAArray('#F5F5F5');
        element.style.setProperty('--color-foreground', foregroundColor[0] + ',' + foregroundColor[1] + ',' + foregroundColor[2]);
        element.style.setProperty('--color-background', backgroundColor[0] + ',' + backgroundColor[1] + ',' + backgroundColor[2]);
      } else {
        const foregroundColor = hexToRGBAArray('#eeeeee');
        const backgroundColor = hexToRGBAArray('#303030');
        element.style.setProperty('--color-foreground', foregroundColor[0] + ',' + foregroundColor[1] + ',' + foregroundColor[2]);
        element.style.setProperty('--color-background', backgroundColor[0] + ',' + backgroundColor[1] + ',' + backgroundColor[2]);
      }
    }
  }, []);

  return (
    <>
      <PinturaEditor
        ref={editorRef}
        onLoaderror={(error) => console.log(error)}
        {...editorDefaults}
        src={imageEditorSource}
        stickers={['😎']}
        willRenderToolbar={willRenderToolbar}
        onReady={() => setHasLoaded(true)}
      ></PinturaEditor>
      {hasLoaded && (
        <div style={{ padding: theme.spacing(2), display: 'flex', alignItems: 'end', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography style={{ marginRight: 20, color: theme.palette.gray.main, fontStyle: 'italic', fontSize: 12, lineHeight: 0.8 }}>
            The video editor may not be suitable for longer videos. The output quality will be reduced.
          </Typography>
          <div style={{ display: 'flex', alignItems: 'end' }}>
            <Typography style={{ marginRight: 20, color: theme.palette.gray.main, fontStyle: 'italic', fontSize: 12, lineHeight: 0.8 }}>Edited files will be converted into .webm</Typography>

            <ButtonGroup variant='contained' ref={anchorRef} aria-label='save'>
              <Button size='small' onClick={() => onSaveHandler()}>
                Save
              </Button>
              <Button
                size='small'
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label='save and replace'
                aria-haspopup='menu'
                onClick={handleToggle}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
          </div>
          <Popper
            placement='top-start'
            sx={{
              zIndex: 1,
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper style={{ backgroundColor: theme.palette.bgFour.main }}>
                  <ClickAwayListener onClickAway={handleClickAwayMenu}>
                    <MenuList id='split-button-menu' autoFocusItem>
                      <MenuItem onClick={popperHandleClick}>Save as</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      )}
      <Popper
        open={popperOpen}
        anchorEl={popperAnchorEl}
        placement='top'
        sx={{
          zIndex: 10000,
        }}
      >
        <Paper elevation={5} sx={{ background: theme.palette.bgFour.main, padding: theme.spacing(2) }}>
          <Typography variant='h6'>Choose a name</Typography>
          <TextField variant={'standard'} placeholder={'New Name'} onChange={(e) => setNewName(e.target.value)} fullWidth onKeyUp={handleOnClickEnter} />
          <div style={{ marginTop: theme.spacing(2), display: 'flex', justifyContent: 'end' }}>
            <Button onClick={() => onSaveHandler(newName)}>OK</Button>
            <Button onClick={handleClosePopper}>Cancel</Button>
          </div>
        </Paper>
      </Popper>
    </>
  );
}
