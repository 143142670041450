import React, { useRef, useState } from 'react';
import { MediaLibraryStyle } from './MediaLibraryStyle';
import AddIcon from '@mui/icons-material/Add';
import { useStore } from '../../store/store';
import { handleDrag, handleDragIn, handleDragOut, handleDrop } from '../../utils/UploadFile/UploadFile';
import { observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import Menu from '@mui/material/Menu/Menu';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import SnackbarUtils from '../../components/snackbarUtils/SnackbarUtilsConfigurator';
import blobToFileList from '../../utils/UploadFile/BlobToFileList';

interface IMediaFileUploadProps {
  tileSize: number;
  isListView: boolean;
}

export default observer(function MediaFileUpload({ tileSize, isListView }: IMediaFileUploadProps) {
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const [dragHasFiles, setDragHasFiles] = useState(false);
  const { mediaLibraryStore } = useStore();
  const { uploadFiles } = mediaLibraryStore;
  const theme = useTheme();

  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : null,
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleChange = () => {
    if (uploadInputRef.current) {
      if (uploadInputRef.current.files) {
        uploadFiles(uploadInputRef.current.files);
      }
    }
  };

  const handleUploadFromPaste = async () => {
    let fileWasImage = false;
    handleClose();
    const clipboardContents = await navigator.clipboard.read();
    for (const item of clipboardContents) {
      console.log(item);
      if (item.types.includes('image/png')) {
        fileWasImage = true;
        const blob = await item.getType('image/png');
        uploadFiles(blobToFileList(blob, 'png'));
      }
    }

    if (!fileWasImage) {
      SnackbarUtils.error('No image found in clipboard');
    }
  };

  const classes = MediaLibraryStyle({ theme });
  return (
    <>
      <label htmlFor='uploadFiles' style={{ width: isListView ? '100%' : 'auto' }}>
        <div
          className={dragHasFiles ? classes.addFileRootHasFiles : contextMenu ? classes.addFileRootContextOpen : classes.addFileRoot}
          style={{ width: isListView ? '100%' : tileSize, height: isListView ? '100px' : tileSize }}
          onDragEnter={(e: any) => handleDragIn(e, setDragHasFiles)}
          onDragLeave={(e: any) => handleDragOut(e, setDragHasFiles)}
          onDragOver={handleDrag}
          onDrop={(e: any) => handleDrop(e, uploadFiles)}
          onContextMenu={handleContextMenu}
        >
          <div className={classes.addFileContent} style={{ pointerEvents: 'none' }}>
            <input id='uploadFiles' type='file' multiple={true} ref={uploadInputRef} title='' value='' onChange={handleChange} />
            <AddIcon fontSize='inherit' color='inherit' />
          </div>
        </div>
      </label>
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference='anchorPosition'
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
      >
        <MenuItem onClick={handleUploadFromPaste}>Upload from paste</MenuItem>
      </Menu>
    </>
  );
});
