export interface IEnquiryContentObjectProperties {
  connectType?: number;
  padding: number;
  cards?: IEnquiryFlashCard[];
  rows?: IEnquiryConnectRow[];
  cs: IEnquiryContentObjectCs;
  sp?: IEnquiryFlashCardSp;
  spl?: IEnquiryMatchingSpl;
  spr?: IEnquiryMatchingSpl;
}

export interface IEnquiryContentObjectCs {
  normal: IEnquiryContentObjectStrokeStyle;
  correct: IEnquiryContentObjectStrokeStyle;
  wrong: IEnquiryContentObjectStrokeStyle;
}

export interface IEnquiryContentObjectStrokeStyle {
  front?: IEnquiryContentObjectStrokeStyle;
  back?: IEnquiryContentObjectStrokeStyle;
  strokeStyle?: string;
  fillStyle?: string;
}

export interface IEnquiryFlashCardSp {
  w: number;
  h: number;
}

export interface IEnquiryMatchingSpl {
  w: number;
  h: number;
  a: number;
  th: number;
}

export interface IEnquiryFlashCard {
  front: string;
  back: string;
}

export interface IEnquiryConnectRow {
  captionLeft: string;
  captionRight: string;
}

export enum EEnquiryContentType {
  singleAlternative = 'singleAlternative',
  multipleAlternative = 'multipleAlternative',
  trueOrFalse = 'trueOrFalse',
  categorization = 'categorization',
  fileUpload = 'fileUpload',
  formula = 'formula',
  matching = 'matching',
  sorting = 'sorting',
  essay = 'essay',
  fillInTheBlank = 'fillInTheBlank',
  hotSpot = 'hotSpot',
  numeric = 'numeric',
  flashCard = 'flashCard',
  fillInTheWord = 'fillInTheWord',
  fillInTheNumber = 'fillInTheNumber',
  selectWord = 'selectWord',
  wordL = 'wordL',
  classicAccordion = 'classicAccordion',
}
