import React, { useEffect, useState } from 'react';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import IconButton from '@mui/material/IconButton/IconButton';
import { useStore } from '../../store/store';
import { observer } from 'mobx-react';
import Slider from '@mui/material/Slider/Slider';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import { TextField } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import { Theme, useTheme } from '@mui/material/styles';
import { createUseStyles } from 'react-jss';

interface IMediaLibraryHeaderProps {
  tileSize: number;
  setTileSize: (n: number) => void;
  listView: boolean;
  setListView: (b: boolean) => void;
  createNewFolder: () => void;
}

export default observer(function MediaLibraryHeader({ tileSize, setTileSize, listView, setListView, createNewFolder }: IMediaLibraryHeaderProps) {
  const [currentPath, setCurrentPath] = useState('');
  const { mediaLibraryStore } = useStore();
  const { currentFolderPath, mediaFolders, sortFilesByName, sortFilesBySize, sortFilesByCreatedDate } = mediaLibraryStore;
  const theme = useTheme();
  const [selectValue, setSelectValue] = useState('1');

  const handleSelectChange = (e: any) => {
    setSelectValue(e.target.value);

    switch (e.target.value) {
      case 1:
        sortFilesByName(true);
        break;
      case 2:
        sortFilesByName(false);
        break;
      case 3:
        sortFilesBySize(true);
        break;
      case 4:
        sortFilesBySize(false);
        break;
      case 5:
        sortFilesByCreatedDate(true);
        break;
      case 6:
        sortFilesByCreatedDate(false);
        break;
    }
  };

  const useStyles = createUseStyles((theme: Theme) => ({
    Icon: {
      color: theme.palette.text.primary,
      transition: 'transform 500ms cubic-bezier(0.4, 0.0, 0.2, 1), -webkit-transform 550ms cubic-bezier(0.4, 0.0, 0.2, 1)',
      width: '100%',
      height: '100%',
      position: 'absolute',
      left: 0,
      top: 0,
    },
  }));
  const classes = useStyles({ theme });

  useEffect(() => {
    if (currentFolderPath.length > 0) {
      setCurrentPath(currentFolderPath.replaceAll('\\', '/'));
    }
  }, [currentFolderPath]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          color: theme.palette.text.primary,
          background: theme.palette.bgTwo.main,
          boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.44)',
          zIndex: 10,
        }}
      >
        <div style={{ minWidth: 300, maxWidth: 300, color: 'inherit' }}>
          <div style={{ display: 'flex', paddingLeft: theme.spacing(2), gap: theme.spacing(2), color: 'inherit' }}>
            {mediaFolders && (
              <IconButton size='medium' color='inherit' onClick={createNewFolder}>
                <CreateNewFolderIcon fontSize='inherit' color='inherit' />
              </IconButton>
            )}
          </div>
        </div>
        <div style={{ flexGrow: 1 }}>{currentPath}</div>
        <div style={{ display: 'flex', width: 280, marginRight: 10, alignItems: 'center', gap: 32 }}>
          <TextField select variant='outlined' size='small' value={selectValue} onChange={handleSelectChange} sx={{ minWidth: 135 }}>
            <MenuItem value={1}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 8, color: theme.palette.text.primary }}>
                Name <SortIcon sx={{ transform: 'scale(1, -1)' }} />
              </div>
            </MenuItem>
            <MenuItem value={2}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 8, color: theme.palette.text.primary }}>
                Name <SortIcon />
              </div>
            </MenuItem>
            <MenuItem value={3}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 8, color: theme.palette.text.primary }}>
                Size <SortIcon sx={{ transform: 'scale(1, -1)' }} />
              </div>
            </MenuItem>
            <MenuItem value={4}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 8, color: theme.palette.text.primary }}>
                Size <SortIcon />
              </div>
            </MenuItem>
            <MenuItem value={5}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 8, color: theme.palette.text.primary }}>
                Created <SortIcon sx={{ transform: 'scale(1, -1)' }} />
              </div>
            </MenuItem>
            <MenuItem value={6}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 8, color: theme.palette.text.primary }}>
                Created <SortIcon />
              </div>
            </MenuItem>
          </TextField>

          <IconButton onClick={() => setListView(!listView)} size='large'>
            <ViewModuleIcon className={classes.Icon} style={{ visibility: !listView ? 'hidden' : 'visible', transform: !listView ? 'rotate(-360deg)' : 'rotate(0deg)' }} />
            <ViewListIcon className={classes.Icon} style={{ visibility: !listView ? 'visible' : 'hidden', transform: !listView ? 'rotate(0deg)' : 'rotate(360deg)' }} />
          </IconButton>

          {!listView && <Slider color={'slider'} value={tileSize} aria-label='icon-size-slider' min={120} max={200} onChange={(e: Event, newValue) => setTileSize(newValue as number)} />}
        </div>
        <div style={{ minWidth: 300, maxWidth: 300 }}></div>
      </div>
    </>
  );
});
