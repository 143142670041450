import Dialog from '@mui/material/Dialog/Dialog';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import { observer } from 'mobx-react';
import IconButton from '@mui/material/IconButton/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { createUseStyles } from 'react-jss';
import { modalStyle } from './ModalStyle';

interface ModalProps {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  header?: React.ReactNode;
  content?: React.ReactNode;
}

export default observer(function Modal({ title, isOpen, onClose, header, content }: ModalProps) {
  const theme = useTheme();
  const classes = modalStyle({ theme });

  return (
    <Dialog
      open={isOpen}
      aria-labelledby='responsive-dialog-title'
      maxWidth={'lg'}
      onClose={onClose}
      PaperProps={{
        className: classes.modalBody,
      }}
    >
      <DialogContent style={{ padding: 0, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
        <IconButton sx={{ position: 'absolute', top: 5, right: 5, color: '#EEE' }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <div style={{ padding: theme.spacing(2), background: theme.palette.bgZero.main, color: theme.palette.bgZero.contrastText }}>{title}</div>
        <div
          style={{
            background: theme.palette.bgTwo.main,
            color: theme.palette.bgTwo.contrastText,
            display: 'flex',
            justifyContent: 'center',
            boxShadow: `0px 3px 6px ${theme.palette.bgOne.main}`,
            zIndex: 10,
          }}
        >
          {header}
        </div>
        {content}
      </DialogContent>
    </Dialog>
  );
});
