import React from 'react';
import { useTheme } from '@mui/material/styles';
import { createUseStyles } from 'react-jss';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import Typography from '@mui/material/Typography/Typography';

interface IViewWrapperProps {
  title: string;
  children: React.ReactNode;
}
export default function ViewWrapper({ title, children }: IViewWrapperProps) {
  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.down('xl'));

  return (
    <>
      <div
        style={{
          flex: '1 1 auto',
          marginLeft: theme.spacing(10),
          marginRight: theme.spacing(10),
          marginTop: theme.spacing(5),
          marginBottom: theme.spacing(5),
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(3), marginBottom: theme.spacing(3) }}>
          <Typography variant='h5'>{title}</Typography>
          <div style={{ display: 'flex', gap: theme.spacing(4), height: 16 }}>
            {/*             <div style={{ fontSize: 14, fontWeight: '500', textTransform: 'uppercase', wordWrap: 'break-word' }}>Text</div>
            <div style={{ fontSize: 14, fontWeight: '500', textTransform: 'uppercase', wordWrap: 'break-word' }}>Text</div>
            <div style={{ fontSize: 14, fontWeight: '500', textTransform: 'uppercase', wordWrap: 'break-word' }}>Text</div> */}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flex: '1 1 auto',
            overflow: 'hidden',
          }}
        >
          {children}
        </div>
      </div>
    </>
  );
}
