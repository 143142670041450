import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import useTheme from '@mui/system/useTheme';
import React from 'react';

interface IAccessDeniedProps {
  error: Error;
}

export default function AccessDenied({ error }: IAccessDeniedProps) {
  const theme = useTheme();
  const { logout } = useAuth0();

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      {error.message === 'Unauthorized Account.' && (
        <>
          <span style={{ position: 'absolute', top: '10%', left: '10%', fontSize: '15vw', fontWeight: 800, color: theme.palette.primary.main }}>403</span>
          <p>Access Denied</p>
          <Button variant='contained' onClick={() => logout()}>
            Log in with a different account.
          </Button>
        </>
      )}
      {error.message === 'Validate Email.' && (
        <>
          <p>Please verify your email</p>
          <p>Check your inbox for a verification email</p>
          <p>Verify then reload the page</p>
          <Button variant='contained' onClick={() => logout()}>
            Or log in with a different account.
          </Button>
        </>
      )}
    </div>
  );
}
