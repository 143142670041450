import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../services/api/agent';
import { store } from './store';
import SnackbarUtils from '../components/snackbarUtils/SnackbarUtilsConfigurator';

export default class PreviewStore {
  previewSource = '';
  currentPreviewCourseID = 0;
  currentPreviewCourseTitle = '';
  currentPreviewPageID = 0;
  coursePreviewOpen = false;

  constructor() {
    makeAutoObservable(this);
  }

  openPagePreview = async () => {
    if (store.pageStore.coursePage === undefined) return;

    if (store.pageStore.coursePage.courseID !== this.currentPreviewCourseID) {
      this.openPreview(store.pageStore.coursePage.courseID, store.pageStore.coursePage.coursePageID);
      return;
    } else if (store.pageStore.coursePage.coursePageID !== this.currentPreviewPageID) {
      this.openPreview(store.pageStore.coursePage.courseID, store.pageStore.coursePage.coursePageID);
      return;
    }
    this.setPreviewOpen(false);
  };

  openCoursePreview = async () => {
    if (store.courseStore.courseViewModel === undefined) return;
    if (store.courseStore.courseViewModel.courseID !== this.currentPreviewCourseID) {
      this.openPreview(store.courseStore.courseViewModel.courseID);
      return;
    }

    this.setPreviewOpen(false);
  };

  openCoursePreviewByID = async (courseID: number) => {
    if (courseID !== this.currentPreviewCourseID) {
      this.openPreview(courseID);
      return;
    }

    this.setPreviewOpen(false);
  };

  getPreview = async (courseID: number, pageID?: number) => {
    let page = 1;
    if (pageID === undefined) {
      const coursePages = await agent.Course.getPages(courseID);
      this.setCurrentPreviewCourseTitle(coursePages.title);
      const pages = Object.keys(coursePages.pages.items);
      if (pages.length > 1) {
        page = parseInt(coursePages.pages.items[pages[0]].children[0]);
      } else {
        SnackbarUtils.info('Course has no pages');
        return;
      }
    } else {
      this.setCurrentPreviewCourseTitle(store.pageStore.coursePage ? store.pageStore.coursePage.title : '');
    }

    const source = await agent.ExportStaticHtml.PreviewPage(courseID, pageID ? pageID : page);
    return source;
  };

  openPreview = async (courseID: number, pageID?: number) => {
    const source = await this.getPreview(courseID, pageID);
    if (source === undefined) return;
    runInAction(() => {
      this.previewSource = source;
    });
    this.setCurrentPreviewCourseID(courseID);
    this.setCurrentPreviewPageID(pageID ? pageID : 1);
    this.setPreviewOpen(true);
  };

  sharePreview = async () => {
    let source;
    if (store.courseStore.courseViewModel !== undefined) {
      source = await this.getPreview(store.courseStore.courseViewModel.courseID);
    } else if (store.pageStore.coursePage !== undefined) {
      source = await this.getPreview(store.pageStore.coursePage.courseID);
    }

    if (source === undefined) return;

    SnackbarUtils.info('Copied link to clipboard');
    navigator.clipboard.writeText(source);
  };

  setCurrentPreviewCourseID = (n: number) => {
    this.currentPreviewCourseID = n;
  };

  setCurrentPreviewCourseTitle = (s: string) => {
    this.currentPreviewCourseTitle = s;
  };

  setCurrentPreviewPageID = (n: number) => {
    this.currentPreviewPageID = n;
  };

  setPreviewOpen = (state: boolean) => {
    this.coursePreviewOpen = state;
    if (!state) {
      this.setCurrentPreviewCourseID(0);
      this.setCurrentPreviewPageID(0);
    }
  };
}
