import { makeAutoObservable } from 'mobx';
import agent from '../services/api/agent';
import { store } from './store';
import { timeout } from '../utils/timeout';
import { debounce } from '../utils/Debounce';
import { IPropertyGroup, IPropertyGroupColor, IPropertyValue, IPropertyValueObject, TPropertyValueView } from '../services/models/PropertyViewModel';

interface ISaveTimer {
  timeLastChange: number;
  timeUpdateServer: number;
  timeLastSave: number;
  timeForceSave: number;
}

export default class PropertyStore {
  constructor() {
    makeAutoObservable(this);
  }

  saveTimeout: NodeJS.Timeout | undefined = undefined;
  propertyTimer: ISaveTimer = { timeLastChange: 0, timeLastSave: 0, timeUpdateServer: 500, timeForceSave: 20000 };

  setProperty = (propertyValue: TPropertyValueView, value: string) => {
    this.setOption(propertyValue, value);
  };

  setPropertyByValueID = (propertyValueID: number, value: string) => {
    this.setOptionByValueID(propertyValueID, value);
  };

  setPropertyTimer = (propertyValue: TPropertyValueView, value: string) => {
    this.propertyDebounced(propertyValue, value);
  };

  setDesignProperty = (propertyValue: TPropertyValueView, value: string) => {
    this.setOption(propertyValue, value);
    timeout(500).then(() => store.pageStore.savePageHTMLTimer()); //This delay is so the placeholder actually registers the change before we ask for the HTML in SavePAgeHTMLTimer function.
  };

  setDesignPropertyList = (propertyGroup: IPropertyGroupColor | IPropertyGroup, optionList: IPropertyValue[]) => {
    return new Promise(() => {
      this.setOptionListDebounced(propertyGroup, optionList); // This delay is so the placeholder actually registers the change before we ask for the HTML in SavePageHTMLTimer function.
    });
  };

  setOptionList = (propertyGroup: IPropertyGroupColor | IPropertyGroup, optionList: IPropertyValue[]) => {
    return new Promise(() => {
      const options: IPropertyValueObject[] = [];

      optionList.forEach((option) => {
        options.push({
          value: option.value,
          propertyValueID: option.propertyValueView.propertyValueID !== 0 ? option.propertyValueView.propertyValueID : undefined,
          valueMapID: option.propertyValueView.propertyValueID !== 0 ? undefined : option.propertyValueView.valueMapID,
        });
      });

      agent.Property.setOptions(options)
        .then((res) => {
          res.forEach((opt) => {
            const keys = Object.keys(propertyGroup.items);
            if (!opt.valueMapID) return;
            const value = keys.find((x) => x === opt.valueMapID?.propertyReactName);
            if (!value || !opt.propertyValueID) return;
            propertyGroup.items[value].propertyValueID = opt.propertyValueID;
          });
        })
        .then(() => {
          timeout(500).then(() => store.pageStore.savePageHTMLTimer());
        });
    });
  };
  setOptionListDebounced = debounce(this.setOptionList, 500);

  setDesignPropertyTimer = (aPropertyValueView: TPropertyValueView, value: string) => {
    this.designPropertyDebounced(aPropertyValueView, value);
  };

  designPropertyDebounced = debounce(this.setDesignProperty, 500);
  propertyDebounced = debounce(this.setDesignProperty, 500);

  setOption = async (aPropertyValueView: TPropertyValueView, value: string) => {
    return agent.Property.setOption(
      value,
      aPropertyValueView.propertyValueID !== 0 ? aPropertyValueView.propertyValueID : undefined,
      aPropertyValueView.propertyValueID !== 0 ? undefined : aPropertyValueView.valueMapID,
    ).then((res) => {
      if (res.propertyValueID) aPropertyValueView.propertyValueID = res.propertyValueID;
      return res;
    });
  };

  setOptionByValueID = async (propertyValueID: number, value: string) => {
    agent.Property.setOption(value, propertyValueID, undefined);
  };
}
