import useTheme from '@mui/material/styles/useTheme';
import { observer } from 'mobx-react';
import React from 'react';

interface IWalkthroughStepProps {
  title: string;
  body: string;
  source?: string;
}
interface IVideoProps {
  url: string;
}

export default observer(function WalkthroughStep({ title, body, source }: IWalkthroughStepProps) {
  const theme = useTheme();
  const Video = ({ url }: IVideoProps) => {
    return (
      <video autoPlay muted loop playsInline>
        <source src={url} type='video/mp4' />
        Your browser does not support the video tag.
      </video>
    );
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
      {source && <Video url={source} />}
      <div style={{ fontSize: 18, fontWeight: 'bold', marginBottom: theme.spacing(1), marginTop: theme.spacing(2) }}>{title}</div>
      <div>{body}</div>
    </div>
  );
});
