import { Theme, useTheme } from '@mui/material/styles';
import React from 'react';
import { animated, useSpring } from 'react-spring';
import { observer } from 'mobx-react';
import { createUseStyles } from 'react-jss';

interface ISideDrawerProps {
  isOpen: boolean;
  isPersistent?: boolean;
  isLeft?: boolean;
  children?: React.ReactNode;
}
export default observer(function SideDrawer({ isOpen, isPersistent: isPersistant, isLeft, children }: ISideDrawerProps) {
  const width = 300;
  const drawerPropsLeft = useSpring({ left: isOpen ? 0 : -width });
  const drawerPropsRight = useSpring({ right: isOpen ? 0 : -width });
  const theme = useTheme();

  const useStyles = createUseStyles((theme: Theme) => ({
    drawer: {
      minWidth: width,
      maxWidth: width,
      height: !isPersistant ? '100%' : '',
      background: theme.palette.bgTwo.main,
      position: !isPersistant ? 'absolute' : 'static',
      display: 'flex',
      flexDirection: 'column',
      zIndex: 20,
      top: 0,
    },
  }));
  const classes = useStyles({ theme });

  return (
    <>
      <animated.div className={classes.drawer} style={isLeft ? drawerPropsLeft : drawerPropsRight}>
        {children}
      </animated.div>
    </>
  );
});
