import './styles/css/main.css';
import React, { Suspense, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Box, StyledEngineProvider /* useMediaQuery */ } from '@mui/material';
import { Theme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import NavBar from './components/layout/navbar/NavBar';
import { useStore } from './store/store';
import { Route, Switch } from 'react-router-dom';
//import Home from './views/Home/Home';
import { darkTheme /* lightTheme */ } from './styles/Themes';
//import NotFound from './views/Error/NotFound';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
//import CourseDashboard from './views/Course/CourseDashboard/CourseDashboard';
//import CourseEdit from './views/Course/CourseEdit/CourseEdit';
//import UserAccount from './views/Account/UserAccount/UserAccount';
import { SnackbarUtilsConfigurator } from './components/snackbarUtils/SnackbarUtilsConfigurator';
import PreviewCourse from './components/previewCourse/PreviewCourse';
//import Login from './views/Login/Login';
import ProtectedRoute from './features/auth/ProtectedRoute';
import Modal from './components/modal/Modal';
import ImageEditor from './components/imageEditor/ImageEditor';
import AdminPage from './routes/admin/AdminPage';
import AdminProtectedRoute from './features/auth/AdminProtectedRoute';
import ImageEditorHeader from './components/imageEditor/ImageEditorHeader';
import NewWalkthrough from './features/walkthrough/NewWalkthrough';
import { createUseStyles } from 'react-jss';
import ColorShade from './components/color/ColorShade';
import { NumberParam, useQueryParam } from 'use-query-params';

import CircularLoading from './components/loading/circularLoading';
import VideoEditor from './components/imageEditor/VideoEditor';
import ConfigProvider from 'antd/es/config-provider';

const CourseOverview = React.lazy(() => import('./routes/courseOverview/CourseOverview'));
const Consent = React.lazy(() => import('./routes/consent/Consent'));
const CourseDashboard = React.lazy(() => import('./routes/courseDashboard/CourseDashboard'));
const CourseEdit = React.lazy(() => import('./routes/courseEdit/CourseEdit'));
const UserAccount = React.lazy(() => import('./routes/userAccount/UserAccount'));
const MaintenanceCountdown = React.lazy(() => import('./routes/maintenance/MaintenanceCountdown'));
const Login = React.lazy(() => import('./routes/auth/Login'));
const Logout = React.lazy(() => import('./routes/auth/Logout'));
const Unauthorized = React.lazy(() => import('./routes/auth/Unauthorized'));
const NoRole = React.lazy(() => import('./routes/auth/IncorrectRole'));
const NotFound = React.lazy(() => import('./routes/auth/NotFound'));

//TODO: Sette på "noUncheckedIndexedAccess" i tsconfig.json til true. Dette krever en del endringer i koden.
//TODO: Fjern "allowJs": true, fra tsconfig.json. Dette krever at ingen js filer er i bruk.

export default observer(function App() {
  const { userStore, imageEditorStore, previewStore } = useStore();
  const { metadata } = userStore;
  const { setPreviewOpen } = previewStore;
  const [isColorShadesOpen, setIsColorShadesOpen] = useState(false);
  const { isImageEditorOpen, isVideoEditorOpen, closeImageEditor, closeVideoEditor } = imageEditorStore;

  //const [maintenanceMode, setMaintenanceMode] = useState(false);

  const [colorShade, setColorShade] = useQueryParam('color', NumberParam); //temp

  const [theme, setTheme] = useState(darkTheme);

  const handleCloseColorShades = () => {
    setIsColorShadesOpen(false);
    setColorShade(undefined);
  };

  useEffect(() => {
    if (colorShade !== undefined) {
      setIsColorShadesOpen(true);
    }
  }, [colorShade]);

  useEffect(() => {
    if (metadata?.darkMode !== undefined) {
      //setTheme(metaData.darkMode ? darkTheme : lightTheme);
      setTheme(darkTheme);
    }
  }, [metadata.darkMode]);

  useEffect(() => {
    const closePreview = () => {
      setPreviewOpen(false);
    };
    window.addEventListener('beforeunload', closePreview, { passive: false });
    return () => {
      window.removeEventListener('beforeunload', closePreview);
    };
  }, []);

  /*   useEffect(() => {
    if (role >= ECreatorUserRole.superAdmin && maintenanceMode) {
      setMaintenanceMode(false);
    }

  //This is just to show add "maintenance" in the url. It does nothing else.
    if (history.location.pathname !== '/admin' && maintenanceMode && role < ECreatorUserRole.superAdmin) {
      history.push('/maintenance');
    }
  }, [history.location.pathname, history.location.search]); */

  const useStyles = createUseStyles((theme: Theme) => ({
    '@global': {
      '*::-webkit-scrollbar': {
        width: 9,
        height: 9,
      },
      '*::-webkit-scrollbar-track': {
        borderRadius: 10,
        background: 'transparent',
      },
      '*::-webkit-scrollbar-corner': {
        background: 'transparent',
      },
      '*::-webkit-scrollbar-thumb': {
        borderRadius: 10,
        backgroundColor: '#9e9e9e',
        borderRight: '1px solid',
        borderRightColor: theme.palette.bgTwo.main,
        '&:hover': {
          backgroundColor: '#707070',
          width: 20,
        },
        '&:active': {
          backgroundColor: '#454545',
        },
      },
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      color: theme.palette.text.primary,
      background: theme.palette.bgThree.main,
      minWidth: '100vw',
      minHeight: '100vh',
      maxHeight: '100vh',
    },
    SnackbarError: { backgroundColor: theme.palette.error.main + ' !important' },
    SnackbarInfo: { background: theme.palette.info.main + ' !important' },
    SnackbarWarning: { background: theme.palette.warning.main + ' !important', color: theme.palette.warning.contrastText + ' !important' },
    SnackbarSuccess: { background: theme.palette.success.main + ' !important' },
  }));

  const classes = useStyles({ theme });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: 'Roboto',
            },
          }}
        >
          <NewWalkthrough>
            <SnackbarProvider
              maxSnack={5}
              autoHideDuration={5000}
              classes={{
                variantError: classes.SnackbarError,
                variantInfo: classes.SnackbarInfo,
                variantWarning: classes.SnackbarWarning,
                variantSuccess: classes.SnackbarSuccess,
              }}
              iconVariant={{
                success: <CheckCircleIcon style={{ marginRight: 10 }} />,
                error: <ErrorIcon style={{ marginRight: 10 }} />,
                warning: <WarningIcon style={{ marginRight: 10 }} />,
                info: <InfoIcon style={{ marginRight: 10 }} />,
              }}
            >
              <SnackbarUtilsConfigurator />
              <Box className={classes.root}>
                <NavBar />
                <Suspense fallback={<CircularLoading loadingText='Loading...' />}>
                  <Switch>
                    <AdminProtectedRoute path='/admin' component={AdminPage} />
                    {/* {maintenanceMode && <Route path='/' component={MaintenanceCountdown} />} */}

                    <ProtectedRoute path='/' exact component={CourseOverview} />
                    <Route path='/consent' component={Consent} />
                    <ProtectedRoute path='/course' component={CourseDashboard} />
                    <ProtectedRoute path='/courseEdit' component={CourseEdit} />
                    <ProtectedRoute path='/userAccount' component={UserAccount} />
                    <Route path='/maintenance' component={MaintenanceCountdown} />
                    <Route path='/login' exact component={Login} />
                    <Route path='/logout' exact component={Logout} />
                    <Route path='/Unauthorized' exact component={Unauthorized} />
                    <Route path='/norole' exact component={NoRole} />
                    <Route component={NotFound} />
                  </Switch>
                </Suspense>
                <PreviewCourse />
                <Modal isOpen={isImageEditorOpen} onClose={closeImageEditor} title='Image Editor' header={<ImageEditorHeader />} content={<ImageEditor />} />
                <Modal isOpen={isVideoEditorOpen} onClose={closeVideoEditor} title='Video Editor' header={<ImageEditorHeader />} content={<VideoEditor />} />
                <Modal isOpen={isColorShadesOpen} onClose={handleCloseColorShades} title='Color Shades' header={<></>} content={<ColorShade />} />
                <span style={{ position: 'absolute', bottom: 0, left: 0, marginLeft: 4, marginBottom: 4, fontSize: 12, color: theme.palette.versionNumber.main }}>{process.env.REACT_APP_VERSION}</span>
              </Box>
            </SnackbarProvider>
          </NewWalkthrough>
        </ConfigProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
});
