import SnackbarUtils from '../../components/snackbarUtils/SnackbarUtilsConfigurator';
import { IPagePlaceholder } from '../../services/models/PageViewModel';

export const handleDragIn = (e: any, hasFiles: (b: boolean) => void) => {
  e.preventDefault();
  e.stopPropagation();

  if (
    (e.dataTransfer.items.length > 0 && e.dataTransfer.items[0].type.includes('image')) ||
    e.dataTransfer.items[0].type.includes('video') ||
    e.dataTransfer.items[0].type.includes('text') ||
    e.dataTransfer.items[0].type.includes('audio')
  ) {
    hasFiles(true);
  }
};
export const handleDragOut = (e: any, hasFiles: (b: boolean) => void) => {
  e.preventDefault();
  e.stopPropagation();
  hasFiles(false);
};
export const handleDrag = (e: any) => {
  e.preventDefault();
  e.stopPropagation();
};
export const handleDrop = (e: any, callback: (files: FileList) => void) => {
  e.preventDefault();
  e.stopPropagation();

  if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
    const eventFiles: FileList = e.dataTransfer.files;
    callback(eventFiles);
    e.dataTransfer.clearData();
  } else {
    SnackbarUtils.error('No file detected.');
  }
};
export const handleDropSingle = async (e: any, placeholder: IPagePlaceholder, callback: (placeholder: IPagePlaceholder, files: FileList) => void) => {
  e.preventDefault();
  e.stopPropagation();

  if (e.dataTransfer.files) {
    if (e.dataTransfer.files.length <= 1) {
      const eventFiles: FileList = e.dataTransfer.files;
      const res: any = await callback(placeholder, eventFiles);
      e.dataTransfer.clearData();
      if (res === false) {
        return false;
      }
      return true;
    } else {
      SnackbarUtils.error('Do not upload more than 1 file.');
      return false;
    }
  } else {
    return false;
  }
};
