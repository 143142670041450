import axios, { AxiosResponse } from 'axios';
import { VideoListResponse, videoDetailsResponse } from '../models/YoutubeModels';

const axiosYT = axios.create({
  baseURL: 'https://www.googleapis.com/youtube/v3',
});

//const KEY = 'AIzaSyA-IlajfP0ncGvL6Z9fVt304tuHJ2ukgyM';
const KEY = 'AIzaSyAca6mh5dsMy4GFkcwOnnUBki7aYDicrKY';

axios.interceptors.response.use(async (response) => {
  try {
    return response;
  } catch (error) {
    console.log(error);
    return await Promise.reject(error);
  }
});

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
  getList: <T>(url: string, part: string, query: string, nextPageToken?: string) =>
    axiosYT
      .get<T>(url, {
        params: {
          part: part,
          maxResults: 20,
          pageToken: nextPageToken,
          key: KEY,
          type: 'video',
          q: query,
        },
      })
      .then(responseBody),
  getdetails: <T>(url: string, part: string, query: string) =>
    axiosYT
      .get<T>(url, {
        params: {
          part: part,
          key: KEY,
          id: query,
        },
      })
      .then(responseBody),
};

const videos = {
  search: (query: string, nextPageToken?: string) => requests.getList<VideoListResponse>('/search', 'snippet', query, nextPageToken),
  videoDetails: (query: string) => requests.getdetails<videoDetailsResponse>('/videos', 'contentDetails,player,statistics', query),
};

const agent = {
  videos,
};

export default agent;
