/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/ban-types */

type FTreeForEachNodeCallback = (aNode: ITreeNode) => void;
type FTreeEveryNodeCallback = (aNode: ITreeNode) => boolean;
type FTreeNodeGoUpCallback = (aNode: ITreeNode) => void;
type FTreeForEachNode = (aCallback: FTreeForEachNodeCallback) => void;
type FTreeEveryNode = (aCallback: FTreeEveryNodeCallback) => boolean;
type FTreeNodeAddNode = (aObject: object) => ITreeNode;
type FTreeNodeDelNode = () => void;
type FTreeNodeGoUp = (aCallBack: FTreeNodeGoUpCallback) => void;

export interface ITreeNode {
  children: Array<ITreeNode>;
  parent: ITreeNode | null;
  data: object;
  depth: number;
  forEach: FTreeForEachNode;
  every: FTreeEveryNode;
  addNode: FTreeNodeAddNode;
  delNode: FTreeNodeDelNode;
  goUp: FTreeNodeGoUp;
}

class TTreeNode implements ITreeNode {
  data: object;
  parent: ITreeNode | null;
  children: Array<ITreeNode>;
  depth: number;

  constructor(aData: object, aParent?: ITreeNode) {
    this.data = aData;
    this.children = new Array<ITreeNode>();
    this.parent = aParent || null;
    this.depth = this.parent !== null ? this.parent.depth + 1 : 0;
  }

  public forEach(aCallback: FTreeForEachNodeCallback): void {
    aCallback(this);
    this.children.forEach((v) => {
      v.forEach(aCallback);
      //v.children.forEach(aCallback);
    });
  }

  public every(aCallback: FTreeEveryNodeCallback): boolean {
    if (aCallback(this)) {
      this.children.every((v) => {
        return v.every(aCallback);
      });
      return true;
    }
    return false;
  }

  public addNode(aData: object): ITreeNode {
    const newNode = new TTreeNode(aData, this);
    this.children.push(newNode);
    return newNode;
  }

  public delNode(): void {
    if (this.parent !== null) {
      let index = this.parent.children.indexOf(this);
      if (index >= 0) {
        this.parent.children.splice(index, 1);
      }
    }
  }

  public goUp(aCallBack: FTreeNodeGoUpCallback) {
    aCallBack(this);
    if (this.parent !== null) {
      this.parent.goUp(aCallBack);
    }
  }
}

export interface IPathObject {
  name: string;
  node: ITreeNode | null;
  children: Array<IPathObject>;
  path: string;
  parentPath: string;
  expanded: boolean;
}

export function createTreeObjectFromPathList(aRootPath: string, aPathList: Array<string>): IPathObject {
  const pathObject: IPathObject = { name: aRootPath, node: null, children: [], path: '', parentPath: '', expanded: false };
  pathObject.node = new TTreeNode(pathObject);
  for (let i: number = 0; i < aPathList.length; i++) {
    const items = aPathList[i].split('\\');
    addTreeListNode(pathObject.node, items, 0);
  }

  pathObject.node.forEach((v: ITreeNode) => {
    if (v.parent !== null) {
      const path: IPathObject = <IPathObject>v.data;
      const parent: IPathObject = <IPathObject>v.parent.data;
      parent.children.push(path);
    }
  });
  return pathObject;

  function addTreeListNode(aRoot: ITreeNode, aItems: Array<string>, aLevel: number): void {
    let pathName: string = aItems[aLevel];

    if (pathName.includes('Hidden')) return;
    if (pathName.includes('User')) return;
    let root: ITreeNode | null = null;
    const level = aLevel;
    aRoot.every((v) => {
      const path: IPathObject = <IPathObject>v.data;
      if (path.name === pathName && level != v.depth) {
        root = v;
        return false; //Do not check next
      }
      return true;
    });
    if (root === null) {
      const pathObject: IPathObject = { name: pathName, node: null, children: [], path: '', parentPath: '', expanded: false };
      //const pathObject = new TPathObject(pathName);
      pathObject.node = root = aRoot.addNode(pathObject);
      pathObject.path = getFullPath(pathObject);
    }
    for (let i: number = aLevel + 1; i < aItems.length; i++) {
      addTreeListNode(root, aItems, aLevel + 1);
    }
  }
}

export function getFullPath(aPathObject: IPathObject): string {
  let sPath = '';
  aPathObject.node?.goUp((v) => {
    const pathObject: IPathObject = <IPathObject>v.data;
    if (sPath === '') {
      sPath = pathObject.name;
    } else {
      if (pathObject.name.length > 0) {
        sPath = pathObject.name + '\\' + sPath;
      }
    }
  });
  return sPath;
}

export function deletePathObject(aPathObject: IPathObject) {
  const temp = aPathObject.node?.parent;
  aPathObject.node?.delNode();
  if (aPathObject.node?.parent !== null) {
    const pathObject: IPathObject = <IPathObject>aPathObject.node?.parent.data;
    const index: number = pathObject.children.indexOf(<IPathObject>aPathObject.node?.data);
    if (index >= 0) {
      pathObject.children.splice(index, 1);
    }
  }
}

function getPathTreeView(aPathObject: IPathObject, aULElement: HTMLUListElement) {
  aPathObject.children.forEach((v) => {
    const lsElement = document.createElement('li');
    const textNode = document.createTextNode(v.name);
    lsElement.appendChild(textNode);
    aULElement.appendChild(lsElement);
    if (v.children.length > 0) {
      const ulElement = document.createElement('ul');
      getPathTreeView(v, ulElement);
      lsElement.appendChild(ulElement);
    }
  });
}
