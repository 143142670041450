import { User } from '@auth0/auth0-react';
import agent from '../services/api/agent';
import { makeAutoObservable, runInAction } from 'mobx';
import { IMetaDataWalkthrough, IMetadata, IUserProfileViewModel } from '../services/models/UserProfileViewModel';
import SnackbarUtils from '../components/snackbarUtils/SnackbarUtilsConfigurator';
import { store } from './store';

export interface CreatorUser extends User {
  companyName?: string;
}

export enum ECreatorUserRole {
  noRole,
  trial,
  user,
  admin,
  nias,
  superAdmin,
}

export default class UserStore {
  metadata: IMetadata = {};
  user: CreatorUser | undefined = undefined;
  role: ECreatorUserRole = ECreatorUserRole.noRole;
  roles: string[] | undefined = undefined;

  loadingUser = true;
  loadingProfile = true;
  userProfileView: IUserProfileViewModel | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  setUser = async (aUser: User, aPrefersDarkMode: boolean, aRoles?: string[], aMetadata?: IMetadata) => {
    this.setLoadingUser(true);
    this.user = { ...aUser };
    this.roles = aRoles;
    if (aRoles === undefined) aRoles = ['noRole'];
    const userRole = this.roleCheck(aRoles);
    this.role = userRole;

    if (aMetadata !== undefined) {
      this.metadata = aMetadata;

      if (this.metadata.skipGridWalkthrough !== undefined && this.metadata.walkthrough === undefined) {
        const walkthroughObj: IMetaDataWalkthrough = { createCourse: this.metadata.skipGridWalkthrough };
        this.metadata.walkthrough = walkthroughObj;
        this.metadata.skipGridWalkthrough = undefined;
        agent.User.setUserMetadata(this.metadata);
      }

      if (aMetadata.darkMode === undefined) {
        this.metadata.darkMode = aPrefersDarkMode;
      }
    } else {
      this.metadata.skipGridWalkthrough = undefined;
    }
    this.setLoadingUser(false);
    store.adminStore.getTestOwner();
  };

  roleCheck = (aRoleList: string[]) => {
    if (aRoleList.length < 1) return ECreatorUserRole.noRole;

    const rolesInEnum: ECreatorUserRole[] = aRoleList
      .map((role) => {
        const roleKey = role as keyof typeof ECreatorUserRole;
        return ECreatorUserRole[roleKey];
      })
      .filter((role) => role !== undefined);

    const highestRole = rolesInEnum.length > 0 ? Math.max(...rolesInEnum) : ECreatorUserRole.noRole;
    return highestRole;
  };

  getUserProfileView = async () => {
    const userProfileView = await agent.User.getUserProfileView();
    try {
      runInAction(() => {
        this.userProfileView = userProfileView;
      });
      this.setLoadingProfile(false);
    } catch (error) {
      console.log(error);
    }
  };

  changePassword = async () => {
    if (this.user) {
      if (this.user.email) {
        const response = await agent.User.changePassword(this.user.email);
        SnackbarUtils.success(response.auth0);
      }
    }
  };

  setLoadingUser = (state: boolean) => {
    this.loadingUser = state;
  };
  setLoadingProfile = (state: boolean) => {
    this.loadingProfile = state;
  };

  setUserData = async () => {
    if (this.user != undefined) {
      agent.User.setUserData(this.user);
    }
  };

  setDarkMode = (val: boolean) => {
    this.metadata.darkMode = val;
    agent.User.setUserMetadata(this.metadata);
  };

  setCreateCourseWalkthrough = (val: boolean): Promise<any> => {
    if (this.metadata.walkthrough === undefined) {
      const walkthroughObj: IMetaDataWalkthrough = { createCourse: false };
      this.metadata.walkthrough = walkthroughObj;
    }
    this.metadata.walkthrough.createCourse = val;
    return agent.User.setUserMetadata(this.metadata);
  };

  /*   setSkipGridWalkthrough = (val: boolean): Promise<any> => {
    this.metadata.skipGridWalkthrough = val;
    return agent.User.setUserMetadata(this.metadata);
  }; */

  setHideTips = (val: boolean) => {
    this.metadata.hideTips = val;
    agent.User.setUserMetadata(this.metadata);
  };

  uploadAvatar = async (file: Blob) => {
    const user = await agent.User.SetUserAvatar(file);
    this.user = { ...user };
  };
}
