import { createContext, useContext } from 'react';
import PageStore from './pageStore';
import CourseStore from './courseStore';
import OwnerStore from './ownerStore';
import PropertyStore from './propertyStore';
import UserStore from './userStore';
import UnsplashStore from './media/UnsplashStore';
import YoutubeStore from './media/YoutubeStore';
import VimeoStore from './media/VimeoStore';
import MediaLibraryStore from './mediaLibrary/mediaLibraryStore';
import ImageEditorStore from './media/imageEditorStore';
import AdminStore from './adminStore';
import WalkthroughStore from './walkthroughStore';
import PreviewStore from './previewStore';
import ContentStore from './contentStore';
import CourseWizardStore from './coursewizardStore';
import OpenAIStore from './openAIStore';
import EnquiryStore from './enquiryStore';
import StoryboardStore from './dashboard/storyboardStore';

interface Store {
  ownerStore: OwnerStore;
  courseStore: CourseStore;
  pageStore: PageStore;
  propertyStore: PropertyStore;
  userStore: UserStore;
  unsplashStore: UnsplashStore;
  youtubeStore: YoutubeStore;
  vimeoStore: VimeoStore;
  mediaLibraryStore: MediaLibraryStore;
  imageEditorStore: ImageEditorStore;
  adminStore: AdminStore;
  walkthroughStore: WalkthroughStore;
  previewStore: PreviewStore;
  contentStore: ContentStore;
  coursewizardStore: CourseWizardStore;
  openAI: OpenAIStore;
  enquiryStore: EnquiryStore;
  storyboardStore: StoryboardStore;
}

export const store: Store = {
  ownerStore: new OwnerStore(),
  courseStore: new CourseStore(),
  pageStore: new PageStore(),
  propertyStore: new PropertyStore(),
  userStore: new UserStore(),
  unsplashStore: new UnsplashStore(),
  youtubeStore: new YoutubeStore(),
  vimeoStore: new VimeoStore(),
  mediaLibraryStore: new MediaLibraryStore(),
  imageEditorStore: new ImageEditorStore(),
  adminStore: new AdminStore(),
  walkthroughStore: new WalkthroughStore(),
  previewStore: new PreviewStore(),
  contentStore: new ContentStore(),
  coursewizardStore: new CourseWizardStore(),
  openAI: new OpenAIStore(),
  enquiryStore: new EnquiryStore(),
  storyboardStore: new StoryboardStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
