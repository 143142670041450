import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link/Link';
import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from '../../store/store';

export default observer(function IncorrectRole() {
  const { userStore } = useStore();
  const { user } = userStore;
  const { logout } = useAuth0();

  return (
    <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <span>Hello, {user?.email}</span>
      <span style={{ marginBottom: 20, marginTop: 5 }}>
        Please contact{' '}
        <Link href='mailto: abm@norskinteraktiv.no' color={'secondary'}>
          abm@norskinteraktiv.no
        </Link>{' '}
        to get access.
      </span>
      <Button variant='contained' onClick={() => logout()}>
        Or log in with a different account.
      </Button>
    </div>
  );
});
