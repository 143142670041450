export function cleanFileName(fileName: string): string {
  // Remove or replace forbidden characters
  const invalidChars = /[/\\:*?"<>|]/g;
  let cleanedFileName: string = fileName.replace(invalidChars, '');

  // Replace spaces with hyphens
  cleanedFileName = cleanedFileName.replace(/\s+/g, '-');

  // Trim any trailing periods or hyphens
  cleanedFileName = cleanedFileName.trim().replace(/[.-]+$/, '');

  // Handle reserved filenames
  const reservedNames: string[] = [
    'CON',
    'PRN',
    'AUX',
    'NUL',
    'COM1',
    'COM2',
    'COM3',
    'COM4',
    'COM5',
    'COM6',
    'COM7',
    'COM8',
    'COM9',
    'LPT1',
    'LPT2',
    'LPT3',
    'LPT4',
    'LPT5',
    'LPT6',
    'LPT7',
    'LPT8',
    'LPT9',
  ];
  if (reservedNames.includes(cleanedFileName.toUpperCase())) {
    cleanedFileName = `_${cleanedFileName}`;
  }

  return cleanedFileName;
}
