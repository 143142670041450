type DebounceCallback = (...args: any[]) => void;

export const debounce = (func: DebounceCallback, delay: number) => {
  let timeoutId: NodeJS.Timeout;

  return (...args: Parameters<DebounceCallback>) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};
