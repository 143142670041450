import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../store/store';
import { setAccessToken } from '../../services/api/agent';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { ECreatorUserRole } from '../../store/userStore';
import CircularLoading from '../../components/loading/circularLoading';
import { observer } from 'mobx-react';
import IncorrectRole from './IncorrectRole';
import { StringParam, useQueryParam } from 'use-query-params';

export default observer(function Login() {
  const { userStore } = useStore();
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { setUser, role, loadingUser } = userStore;
  const [error] = useQueryParam('error', StringParam);
  const [errorDescription] = useQueryParam('error_description', StringParam);
  const history = useHistory();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isAuthenticated && user != undefined) {
      const getAccessToken = async () => {
        const accessToken = await getAccessTokenSilently();
        const decoded: any = jwt_decode(accessToken);
        const metaData = decoded['https://niascreator/user_metadata'];
        const roles = decoded['https://niascreator/roles'];
        setAccessToken(accessToken);
        setUser(user, true, roles, metaData);
      };
      getAccessToken();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (role > ECreatorUserRole.noRole) {
      history.push('/');
    } else if (error === 'unauthorized') {
      history.push('/unauthorized');
    }
  }, [role, error]);

  useEffect(() => {
    if (!loadingUser) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [loadingUser]);

  if (loading) {
    return <CircularLoading loadingText='Loading...' />;
  }

  return <IncorrectRole />;
});
