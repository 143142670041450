import { Theme, useTheme } from '@mui/material/styles';
import React, { useRef, useState } from 'react';
import { useStore } from '../../store/store';
import Menu from '@mui/material/Menu/Menu';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import Modal from '../modal/Modal';
import MediaLibrary from '../../features/mediaLibrary/MediaLibrary';
import { BiggerTooltip } from '../tooltip/BiggerTooltip';
import { createUseStyles } from 'react-jss';
import { acceptedImageTypes } from '../../utils/AcceptedFileTypes';
import { TPropertyValueView } from '../../services/models/PropertyViewModel';

interface IImageUploadProps {
  propertyValueView?: TPropertyValueView;
  children?: React.ReactNode;
  isAvatar?: boolean;
}

export default function ImageUpload({ propertyValueView, children, isAvatar }: IImageUploadProps) {
  const { courseStore, userStore, mediaLibraryStore } = useStore();
  const { uploadCoverPhoto, changeCoverPhoto } = courseStore;
  const { uploadAvatar } = userStore;
  const { openMediaLibraryFromEditor } = mediaLibraryStore;
  const theme = useTheme();
  const uploadInputRef = useRef<HTMLInputElement>(null);

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const menuOpen = Boolean(menuAnchorEl);
  const [isMediaLibraryOpen, setIsMediaLibraryOpen] = useState(false);

  const handleChange = (e: any) => {
    if (uploadInputRef.current) {
      if (propertyValueView) {
        uploadCoverPhoto(propertyValueView, e.target.files[0]);
        return;
      }
      uploadAvatar(e.target.files[0]);
    }
  };

  const useStyles = createUseStyles((theme: Theme) => ({
    cardMenu: {
      background: theme.palette.bgFour.main,
    },
  }));
  const classes = useStyles({ theme });

  const selectImage = (aSrc: string) => {
    if (propertyValueView) {
      changeCoverPhoto(propertyValueView, aSrc);
      setIsMediaLibraryOpen(false);
    }
  };

  const handleMenu = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };
  const handleOpenMediaLibrary = () => {
    openMediaLibraryFromEditor();
    setIsMediaLibraryOpen(true);
  };

  if (isAvatar) {
    return (
      <>
        <BiggerTooltip title='Upload' placement='top' followCursor={true}>
          <div>
            <input ref={uploadInputRef} accept='image/*' id='upload' type='file' hidden onChange={handleChange} />
            <label htmlFor='upload' style={{ cursor: 'pointer' }}>
              <>{children}</>
            </label>
          </div>
        </BiggerTooltip>
      </>
    );
  }

  return (
    <>
      <BiggerTooltip title='Upload' placement='top' followCursor={true}>
        <div>
          <input ref={uploadInputRef} accept='image/*' id='upload' type='file' hidden onChange={handleChange} />
          <div onClick={handleMenu} style={{ cursor: 'pointer' }}>
            <>{children}</>
          </div>
        </div>
      </BiggerTooltip>
      <Menu
        anchorEl={menuAnchorEl}
        id='media-menu'
        open={menuOpen}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        PaperProps={{
          elevation: 5,
          className: classes.cardMenu,
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'center' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
      >
        <label htmlFor='upload' style={{ cursor: 'pointer' }}>
          <MenuItem>Upload</MenuItem>
        </label>
        <MenuItem onClick={handleOpenMediaLibrary}>Media Library</MenuItem>
      </Menu>
      <Modal isOpen={isMediaLibraryOpen} onClose={() => setIsMediaLibraryOpen(false)} title='Media Library' content={<MediaLibrary onSelect={selectImage} acceptedFiletypes={acceptedImageTypes} />} />
    </>
  );
}
