import { makeAutoObservable, runInAction } from 'mobx';
import youtubeAgent from '../../services/api/youtubeAgent';
import { VideoListResponse, videoDetailsResponse } from '../../services/models/YoutubeModels';
import { convertISO8601 } from '../../utils/convertions';
import SnackbarUtils from '../../components/snackbarUtils/SnackbarUtilsConfigurator';
import { store } from '../store';

export interface ICreatorVideoModel {
  title: string;
  description: string;
  published: string;
  link: string;
  img: string;
  duration: string;
  views: string;
  user: string;
}

export default class YoutubeStore {
  youtubeList: VideoListResponse | undefined = undefined;
  youtubeDetails: videoDetailsResponse | undefined = undefined;
  videoList: ICreatorVideoModel[] = [];
  ytNextPageToken = '';
  searchQuery = '';

  isYoutubeOpen = false;
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  //TODO: Add option to switch to next page.
  searchYoutube = async (query: string, nextPageToken?: string) => {
    if (nextPageToken == undefined) {
      this.setLoading(true);
      this.videoList = [];
    }
    this.youtubeList = await youtubeAgent.videos.search(query, nextPageToken);
    this.ytNextPageToken = this.youtubeList.nextPageToken;
    let tempList = '';
    if (this.youtubeList != undefined) {
      this.youtubeList.items.forEach((video) => {
        tempList += video.id.videoId + ',';
      });

      this.youtubeDetails = await youtubeAgent.videos.videoDetails(tempList);

      runInAction(() => {
        this.youtubeList?.items.forEach((video) => {
          const videoDetail = this.youtubeDetails?.items.find((x) => x.id == video.id.videoId);
          const date = new Date(video.snippet.publishedAt);
          const day = date.getDay() < 10 ? '0' + date.getDay() : date.getDay();
          const month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
          if (videoDetail != undefined) {
            this.videoList.push({
              title: video.snippet.title.replace(/&quot;/g, '"'),
              description: video.snippet.description.replace(/&quot;/g, '"'),
              published: day + '.' + month + '.' + date.getFullYear(),
              link: 'https://www.youtube.com/embed/' + video.id.videoId,
              img: video.snippet.thumbnails.medium.url,
              duration: convertISO8601(videoDetail.contentDetails.duration),
              views: videoDetail.statistics.viewCount,
              user: video.snippet.channelTitle,
            });
          }
        });
        this.youtubeList = undefined;
      });

      this.setLoading(false);
    }
  };
  setSearchQuery = (aString: string) => {
    this.searchQuery = aString;
  };

  getNextPage = () => {
    this.searchYoutube(this.searchQuery, this.ytNextPageToken);
  };

  createVideoFromEmbed = (embedCode: string) => {
    const regEx = new RegExp(/src\s*=\s*['"]?([^'"\s>]+)/i);
    const source = regEx.exec(embedCode);
    if (!embedCode.includes('youtube') && !embedCode.includes('youtu.be')) {
      SnackbarUtils.warning('Embed does not appear to be a youtube embed.');
    } else {
      if (source != null) {
        store.pageStore.createEmbedOnlineVideo(source[1]);
        this.closeYoutube();
      } else {
        if (!embedCode.includes('src')) {
          SnackbarUtils.warning('Could not find src in embed code. Try again.');
        }
      }
    }
  };

  openYoutube = () => {
    this.isYoutubeOpen = true;
  };

  closeYoutube = () => {
    this.setLoading(false);
    this.isYoutubeOpen = false;
    this.youtubeDetails = undefined;
    this.youtubeList = undefined;
    this.setSearchQuery('');
  };

  setLoading = (state: boolean) => {
    this.loading = state;
  };
}
