export interface TOwnerCourseViewModel {
  courseID: number;
  staticInfoGroup: TStaticInfoGroup;
}

export interface TStaticInfoGroup {
  caption: string;
  items: IStaticInfoGroup;
}
export interface IStaticInfoGroup {
  caption: string;
  title: TStaticInfo;
  description: TStaticInfo;
  modifiedDate: TStaticInfo;
  createdDate: TStaticInfo;
  status: TStaticInfo;
  avatar: TStaticInfo;
  lastPageVisited: TStaticInfo;
}

export interface TStaticInfo {
  caption: string;
  value: string;
  propertyValueID: number;
}

export enum EOwnerCourseListSortByType {
  Created = 1,
  Altered,
  Title,
}

export interface ICopyPageObject {
  source: ICopyPageData;
  dest: ICopyPageData;
  copyChildPages: boolean;
  index: number;
  numberOfChildren: number;
}

export interface ICopyPageData {
  courseID: number;
  coursePageID: number;
}
