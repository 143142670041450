import Popper from '@mui/material/Popper/Popper';
import React from 'react';

interface CustomPopperProps {
  open: boolean;
  anchorEl: null | HTMLElement;
  children: React.ReactNode;
}

export default function CustomPopper({ open, anchorEl, children }: CustomPopperProps) {
  return (
    <>
      <Popper style={{ zIndex: 100000 }} open={open} anchorEl={anchorEl} placement='bottom'>
        {children}
      </Popper>
    </>
  );
}
