import { Theme, useTheme } from '@mui/material/styles';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../store/store';
import EditIcon from '@mui/icons-material/Edit';
import PublishIcon from '@mui/icons-material/Publish';
import DeleteIcon from '@mui/icons-material/Delete';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useSnackbar } from 'notistack';
import CircularLoading from '../../components/loading/circularLoading';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import TopBar from '../../components/layout/topbar/TopBar';
import SideDrawer from '../../components/layout/drawer/SideDrawer';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import Paper from '@mui/material/Paper/Paper';
import Box from '@mui/material/Box/Box';
import Typography from '@mui/material/Typography/Typography';
import Button from '@mui/material/Button/Button';
import CustomPopper from '../../components/customPopper/CustomPopper';
import EditCourseView from './DashboardViews/EditCourseView';
import ExportCourseView from './DashboardViews/ExportCourseView';
import DeleteCourseView from './DashboardViews/DeleteCourseView/DeleteCourseView';
import AiVoiceView from './DashboardViews/AiVoiceView';
import { AIVoiceIcon } from '../../components/icons/Icons';

export enum EDashboardView {
  Overview = 'overview',
  Export = 'export',
  Delete = 'delete',
  /* AIVoice = 'AIVoice', */
  /* AIVoice = 'AIVoice', */
  /* Storyboard = 'storyboard', */
}

export default observer(function CourseDashboard() {
  const { courseStore, ownerStore } = useStore();
  const { loadingInitial, courseViewModel, courseProperties, loadCourseViewModel, clearCourseViewModel, deselectPageProperties } = courseStore;
  const { deleteCourse: removeCourse } = ownerStore;
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [id] = useQueryParam('id', NumberParam);
  const [currentView = EDashboardView.Overview, setView] = useQueryParam('view', StringParam);
  const [courseIDParam] = useQueryParam('courseID', NumberParam);

  const [popperOpen, setPopperOpen] = useState(false);
  const [popperAnchorEl, setPopperAnchorEl] = useState<null | HTMLElement>(null);

  const history = useHistory();

  const deleteCourse = () => {
    removeCourse(getCourseID()).then(() => {
      setPopperOpen(false);
      history.push('/');
    });
  };

  const popperHandleClick = (element: React.MouseEvent) => {
    setPopperAnchorEl(element.target as HTMLElement);
    setPopperOpen(true);
  };

  const handleChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
    if (nextView === null) return;
    setView(nextView);
  };

  const getCourseID = () => {
    if (id) {
      const courseID = +id;
      if (courseID) {
        return courseID;
      } else {
        console.log('Error. ID is not number');
        enqueueSnackbar('Error. ID is not number', {
          variant: 'error',
        });
        return 0;
      }
    }
    return 0;
  };

  const useStyles = createUseStyles((theme: Theme) => ({
    buttonGroup: {
      justifyContent: 'start',
      marginTop: 20,
      marginLeft: 20,
      '& .MuiToggleButtonGroup-grouped': {
        border: 0,
        width: '100%',
        justifyContent: 'start',
        borderRadius: 0,
        color: theme.palette.text.primary,
        '&.Mui-selected': {
          color: theme.palette.primary.main,
          background: 'transparent',
        },
        '&:hover': {
          color: theme.palette.primary.light,
          background: 'transparent',
        },
        '&.Mui-disabled': {
          color: 'gray',
        },
      },
    },
    buttonGroupIcon: {
      marginRight: 30,
      display: 'flex',
    },
  }));

  const classes = useStyles({ theme });

  /*   useEffect(() => {
    if (!view) return;
    const viableViews = ['overview', 'export'];

    if (!viableViews.includes(view)) {
      setView('overview');
    }
  }, [view]); */

  useEffect(() => {
    const courseID = getCourseID();
    if (courseID !== 0) {
      loadCourseViewModel(courseID).then((courseViewModel) => {
        if (!courseViewModel) {
          //TODO:Evt prøv å lag noe lignende SnackbarUtilsConfigurator for useHistory for å bruke useHistory i courseStore.ts.
          //Her kan evt en melding om ingen tilgang komme.
          history.push('/');
        }
      });
    }
  }, []);

  useEffect(() => {
    return () => {
      deselectPageProperties();
      clearCourseViewModel();
    };
  }, []);

  useEffect(() => {
    if (!Object.values(EDashboardView).includes(currentView as EDashboardView)) {
      console.log(EDashboardView.Overview);
      setView(EDashboardView.Overview);
    }
  }, [currentView, setView]);

  return (
    <>
      <TopBar />
      {loadingInitial ? (
        <CircularLoading loadingText=' ' />
      ) : (
        <div style={{ display: 'flex', flex: '1 1 auto', overflow: 'hidden' }}>
          <SideDrawer isOpen isLeft isPersistent>
            <ToggleButtonGroup orientation='vertical' exclusive className={classes.buttonGroup} value={currentView} onChange={handleChange}>
              <ToggleButton value={EDashboardView.Overview} aria-label='edit course'>
                <EditIcon className={classes.buttonGroupIcon} /> Edit Course
              </ToggleButton>
              <ToggleButton value={EDashboardView.Export} aria-label='export course'>
                <PublishIcon className={classes.buttonGroupIcon} /> Export
              </ToggleButton>
              {/*               <ToggleButton value={EDashboardView.Storyboard} aria-label='storyboard'>
                <AutoStoriesIcon className={classes.buttonGroupIcon} /> Storyboard
              </ToggleButton> */}
              {/* <ToggleButton value={EDashboardView.AIVoice} aria-label='AI voice generator'>
                <span className={classes.buttonGroupIcon}>
                  <AIVoiceIcon />
                </span>
                AI Voice Generator
              </ToggleButton> */}
              <ToggleButton value={EDashboardView.Delete} aria-label='delete course' /* onClick={(e) => popperHandleClick(e)} */>
                <DeleteIcon className={classes.buttonGroupIcon} /> Delete Course
              </ToggleButton>
              {/*               <ToggleButton value='version' aria-label='course versions' disabled style={{}}>
                <CloudUploadIcon className={classes.buttonGroupIcon} /> Version
                <Soon />
              </ToggleButton> */}
              {/*               <ToggleButton
                disabled
                value='language'
                aria-label='course language versions'
                onClick={() => {
                  enqueueSnackbar('error', {
                    variant: 'error',
                  });
                  enqueueSnackbar('warning', {
                    variant: 'warning',
                  });
                  enqueueSnackbar('info', {
                    variant: 'info',
                  });
                  enqueueSnackbar('success', {
                    variant: 'success',
                  });
                }}
              >
                <LanguageIcon className={classes.buttonGroupIcon} /> Language
                <Soon />
              </ToggleButton> */}
            </ToggleButtonGroup>
          </SideDrawer>

          {courseViewModel && (
            <>
              {currentView === EDashboardView.Overview && courseProperties && <EditCourseView courseViewModel={courseViewModel} courseProperties={courseProperties} />}
              {currentView === EDashboardView.Export && <ExportCourseView courseID={courseViewModel.courseID} />}
              {currentView === EDashboardView.Delete && courseProperties && <DeleteCourseView courseViewModel={courseViewModel} courseProperties={courseProperties} />}
              {/* {currentView === EDashboardView.AIVoice && <AiVoiceView />} */}
              {/* {currentView === EDashboardView.AIVoice && <AiVoiceView />} */}
              {/* {currentView === EDashboardView.Storyboard && <StoryboardView courseViewModel={courseViewModel} />} */}
            </>
          )}
        </div>
      )}

      <CustomPopper open={popperOpen} anchorEl={popperAnchorEl}>
        <Paper elevation={5} sx={{ background: theme.palette.bgFive.main, padding: theme.spacing(2) }}>
          <Typography variant='h6'>Are you sure you want to delete?</Typography>
          <Box sx={{ marginTop: theme.spacing(2), display: 'flex', justifyContent: 'center', gap: theme.spacing(2) }}>
            <Button variant='newSecondary' onClick={deleteCourse} style={{ width: 100 }}>
              Yes
            </Button>
            <Button variant='newSecondary' onClick={() => setPopperOpen(false)} style={{ width: 100 }}>
              No
            </Button>
          </Box>
        </Paper>
      </CustomPopper>
    </>
  );
});
