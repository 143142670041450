/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { StyledEngineProvider } from '@mui/material';
import { Theme, ThemeProvider } from '@mui/material/styles';
import { useStore } from '../../store/store';
import { observer } from 'mobx-react';
import NewWindow from './NewWindow';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import TabletMacIcon from '@mui/icons-material/TabletMac';
import ComputerIcon from '@mui/icons-material/Computer';
import { darkTheme } from '../../styles/Themes';
import { convertToRGB } from '../../utils/HexToRGBA';

export default observer(function PreviewCourse() {
  const { previewStore } = useStore();
  const { coursePreviewOpen, previewSource } = previewStore;
  const [theme] = useState(darkTheme);
  const [previewWindow, setPreviewWindow] = useState<Window | null>();
  const [windowSize, setWindowSize] = useState('Desktop');
  const iFrameRef = React.useRef<HTMLIFrameElement>(null);

  const hideCloseButton = () => {
    if (previewWindow !== null && previewWindow !== undefined) {
      if (iFrameRef.current?.contentWindow) {
        console.log('hideCloseButton');
        iFrameRef.current.contentWindow.postMessage('hideCloseButton', '*');
      }
    }
  };

  const rgbPrimary = convertToRGB(theme.palette.primary.main);

  const btnSize = 40;

  return (
    <>
      {coursePreviewOpen && (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <NewWindow isOpen={coursePreviewOpen} source={previewSource} setPreviewWindow={setPreviewWindow} currentWindowSize={windowSize}>
              <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                <div style={{ boxSizing: 'border-box', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'start', padding: 8 }}>
                  <button
                    onClick={() => setWindowSize('Mobile')}
                    style={{
                      background: windowSize === 'Mobile' ? `rgba(${rgbPrimary.r},${rgbPrimary.g},${rgbPrimary.b},0.1)` : 'transparent',
                      width: btnSize,
                      height: btnSize,
                      display: 'grid',
                      placeItems: 'center',
                      border: '1px solid rgba(255, 255, 255, 0.12)',
                      borderRadius: '5px 0 0 5px',
                      fill: windowSize === 'Mobile' ? theme.palette.primary.main : 'white',
                    }}
                  >
                    <div style={{ width: 18, height: 18 }}>
                      <SmartphoneIcon />
                    </div>
                  </button>
                  <button
                    onClick={() => setWindowSize('Tablet')}
                    style={{
                      background: windowSize === 'Tablet' ? `rgba(${rgbPrimary.r},${rgbPrimary.g},${rgbPrimary.b},0.1)` : 'transparent',
                      width: btnSize,
                      height: btnSize,
                      display: 'grid',
                      placeItems: 'center',
                      border: '1px solid rgba(255, 255, 255, 0.12)',
                      borderLeft: '0',
                      borderRight: '0',
                      fill: windowSize === 'Tablet' ? theme.palette.primary.main : 'white',
                    }}
                  >
                    <div style={{ width: 18, height: 18 }}>
                      <TabletMacIcon />
                    </div>
                  </button>
                  <button
                    onClick={() => setWindowSize('Desktop')}
                    style={{
                      background: windowSize === 'Desktop' ? `rgba(${rgbPrimary.r},${rgbPrimary.g},${rgbPrimary.b},0.1)` : 'transparent',
                      width: btnSize,
                      height: btnSize,
                      display: 'grid',
                      placeItems: 'center',
                      border: '1px solid rgba(255, 255, 255, 0.12)',
                      borderRadius: '0 5px 5px 0',
                      fill: windowSize === 'Desktop' ? theme.palette.primary.main : 'white',
                    }}
                  >
                    <div style={{ width: 22, height: 22 }}>
                      <ComputerIcon />
                    </div>
                  </button>
                </div>
                <iframe src={previewSource} ref={iFrameRef} onLoad={hideCloseButton} allowFullScreen allow='autoplay' style={{ flex: '1 1 auto', border: '0' }}></iframe>
              </div>
            </NewWindow>
          </ThemeProvider>
        </StyledEngineProvider>
      )}
    </>
  );
});

interface IPreviewSizeButton {
  children?: React.ReactNode;
}
