import { TreeItem } from '@atlaskit/tree/dist/types/types';

export const getPageStatusColor = (item: TreeItem) => {
  switch (item.data.completionRequirements.value) {
    case 'Optional':
      return '#8ECCFF';
      break;
    case 'Required':
      return '#FFDC7E';
      break;
    case 'Mandatory':
      return '#FF6600';
      break;
    default:
      return '#FF6600';
      break;
  }
};
