import { Theme } from '@mui/material/styles';
import { createUseStyles } from 'react-jss';

export const MediaLibraryStyle = createUseStyles((theme: Theme) => ({
  mediaFileRoot: {
    position: 'relative',
    background: theme.palette.bgFour.main,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    userSelect: 'none',
    '&:hover': {
      background: theme.palette.bgFour.light,
    },
  },
  mediaFileContent: {
    minWidth: 0,
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    userSelect: 'none',
  },
  mediaFileContentList: {
    minWidth: 0,
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'left',
    padding: 2,
    paddingLeft: 10,
    userSelect: 'none',
    gap: 20,
  },
  addFileRoot: {
    background: 'transparent',
    borderRadius: theme.shape.borderRadius,
    outline: `6px dashed ${theme.palette.bgFour.main}`,
    color: theme.palette.bgFour.main,
    outlineOffset: -6,
    display: 'flex',
    cursor: 'pointer',
    zIndex: 10,
    '&:hover': {
      outlineColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
  addFileRootContextOpen: {
    background: 'transparent',
    borderRadius: theme.shape.borderRadius,
    outline: `6px dashed ${theme.palette.primary.main}`,
    outlineOffset: -6,
    display: 'flex',
    cursor: 'pointer',
    zIndex: 10,
    color: theme.palette.primary.main,
  },
  addFileRootHasFiles: {
    width: 200,
    height: 200,
    background: 'transparent',
    borderRadius: theme.shape.borderRadius,
    outline: `6px dashed ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    outlineOffset: -6,
    display: 'flex',
    cursor: 'pointer',
    zIndex: 10,
  },
  addFileContent: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 50,
    padding: theme.spacing(2),
    color: 'inherit',
    '& input': { display: 'none' },
  },
  '@keyframes progressbar': {
    '0%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '-100% 50%',
    },
  },
  uploadProgressBar: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: '100%',
    overflow: 'hidden',
    background: 'linear-gradient(90deg, rgba(255,102,0,1) 0%, rgba(255,102,0,1) 35%, rgba(255,182,133,1) 50%, rgba(255,102,0,1) 65%, rgba(255,102,0,1) 100%)',
    backgroundRepeat: 'repeat-x',
    backgroundSize: '200% 100%',
    animation: '$progressbar 3.5s infinite linear',
  },
}));
