import React, { useEffect, useState } from 'react';
import { getIconName } from './MediaFile';
import { IDIRFilesModel } from '../../services/models/MediaLibraryModels';
import { acceptedImageTypes } from '../../utils/AcceptedFileTypes';
import CircularLoading from '../../components/loading/circularLoading';

interface IMediaFileSelectedImageProps {
  selectedFile: IDIRFilesModel;
}
export default function MediaFileSelectedImage({ selectedFile }: IMediaFileSelectedImageProps) {
  const [thumbnail, setThumbnail] = useState('');
  const [imgLoading, setImgLoading] = useState(true);

  useEffect(() => {
    setImgLoading(true);
    if (!acceptedImageTypes.includes(selectedFile.extension)) {
      if (selectedFile.metadata.tmb != undefined) {
        setThumbnail(selectedFile.metadata.tmb);
      } else {
        setThumbnail(`${process.env.PUBLIC_URL}/Icons/${getIconName(selectedFile.extension)}.svg`);
        setImgLoading(false);
      }
    } else {
      setThumbnail(selectedFile.webPath);
    }
  }, [selectedFile]);

  return (
    <>
      {imgLoading && (
        <div style={{ width: 268, height: 150, display: 'grid', placeItems: 'center' }}>
          <CircularLoading loadingText=' ' />
        </div>
      )}
      <img
        src={thumbnail}
        alt=''
        style={{ width: 268, height: 150, userSelect: 'none', objectFit: 'contain', display: !imgLoading ? 'block' : 'none' }}
        draggable={false}
        onLoad={() => setImgLoading(false)}
      />
    </>
  );
}
