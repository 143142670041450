import { makeAutoObservable } from 'mobx';
import { createApi } from 'unsplash-js';
import unsplashAgent from '../../services/api/unsplashAgent';
import { Photo } from '../../services/models/UnsplashModels';

export interface ICreatorVideoModel {
  title: string;
  description: string;
  published: string;
  link: string;
  img: string;
  duration: string;
  views: string;
  user: string;
}
enum LastAction {
  search,
  random,
  latest,
  nothing,
}

export default class UnsplashStore {
  isUnsplashOpen = false;
  loading = false;
  loadingNextPage = false;
  query = '';
  currentPage = 1;
  lastAction: LastAction = LastAction.nothing;
  unsplashData: Photo[] = [];
  api: any; //createApi from Unsplash has no type...

  requests: any;

  constructor() {
    makeAutoObservable(this);
    this.api = createApi({
      accessKey: process.env.REACT_APP_UNSPLASH_API + '',
    });
  }

  SearchUnsplash = async (query: string, nextPage?: boolean) => {
    this.query = query;
    this.lastAction = LastAction.search;
    if (!nextPage) {
      this.unsplashData = [];
      this.setLoading(true);
    } else {
      this.currentPage++;
      this.setLoadingNextPage(true);
    }
    this.api.search
      .getPhotos({ page: this.currentPage, per_page: 20, query: query })
      .then((result: any) => {
        this.setPhotosResponse(result.response.results);
      })
      .catch(() => {
        this.setLoading(false);
        this.setLoadingNextPage(false);
        console.log('something went wrong!');
      });
  };

  SearchRandomUnsplash = async (nextPage?: boolean) => {
    this.lastAction = LastAction.random;
    if (!nextPage) {
      this.unsplashData = [];
      this.setLoading(true);
    } else {
      this.setLoadingNextPage(true);
    }
    this.api.photos
      .getRandom({ count: 30 })
      .then((result: any) => {
        this.setPhotosResponse(result.response);
        this.setLoading(false);
        this.setLoadingNextPage(false);
      })
      .catch(() => {
        this.setLoading(false);
        this.setLoadingNextPage(false);
        console.log('something went wrong!');
      });
  };

  SearchLatestUnsplash = async (nextPage?: boolean) => {
    this.lastAction = LastAction.latest;
    if (!nextPage) {
      this.unsplashData = [];
      this.setLoading(true);
    } else {
      this.setLoadingNextPage(true);
    }
    this.api.photos
      .list({ page: this.currentPage, per_page: 30 })
      .then((result: any) => {
        this.setPhotosResponse(result.response.results);
        this.setLoading(false);
        this.setLoadingNextPage(false);
      })
      .catch(() => {
        this.setLoading(false);
        this.setLoadingNextPage(false);
        console.log('something went wrong!');
      });
  };

  GetRandomUnsplashImage = async () => {
    let urlResponse = '';
    await this.api.photos
      .getRandom({ count: 1, query: 'Nature' })
      .then((result: any) => {
        urlResponse = result.response[0].urls.regular;
      })
      .catch(() => {
        console.log('something went wrong!');
      });
    return urlResponse;
  };

  GetRandomUnsplashImageLowQ = async (query = 'Nature') => {
    let urlResponse = '';
    await this.api.photos
      .getRandom({ count: 1, query: query })
      .then((result: any) => {
        urlResponse = result.response[0].urls.small;
      })
      .catch(() => {
        console.log('something went wrong!');
      });
    return urlResponse;
  };

  TriggerDownload = (url: string) => {
    unsplashAgent.img.triggerDownload(url);
  };

  setPhotosResponse = async (result: any) => {
    for (let i = 0; i < result.length; i++) {
      const element = result[i];
      if (!element.urls.raw.includes('premium')) this.unsplashData.push(element);
    }
    this.setLoading(false);
    this.setLoadingNextPage(false);
  };

  openUnsplash = () => {
    this.isUnsplashOpen = true;
  };

  closeUnsplash = () => {
    this.setLoading(false);
    this.isUnsplashOpen = false;
    this.currentPage = 1;
    this.unsplashData = [];
  };

  setLoading = (state: boolean) => {
    this.loading = state;
  };
  setLoadingNextPage = (state: boolean) => {
    this.loadingNextPage = state;
  };

  getNextPage = () => {
    switch (this.lastAction) {
      case LastAction.search:
        this.SearchUnsplash(this.query, true);
        break;
      case LastAction.random:
        this.SearchRandomUnsplash(true);
        break;
      case LastAction.latest:
        this.SearchLatestUnsplash(true);
        break;
      default:
    }
  };
}
