import { makeAutoObservable, runInAction } from 'mobx';
import agent, { INewContentProperties } from '../services/api/agent';
import {
  EContentType,
  IContent,
  IContentProperties,
  ICopyObject,
  IEnquiryQuestionProperties,
  IPagePlaceholder,
  IPageProperties,
  IPlaceholderProperties,
  TPageViewModel,
} from '../services/models/PageViewModel';
import { TPagePlaceholder } from '../utils/TPagePlaceholder';
import * as Layout from '../utils/LayoutObject';
import { store } from './store';
import SnackbarUtils from '../components/snackbarUtils/SnackbarUtilsConfigurator';
import { ICoursePageExportData, IPagePlaceholderExportData, IPagePlaceholderScriptExportData } from '../services/models/ExportHTMLModels';
import { EnquiryContentProperties, IPropertyValue } from '../services/models/PropertyViewModel';
import { EEnquiryContentType, IEnquiryContentObjectProperties } from '../services/models/enquiry/EnquiryContentObjectModel';
import { exportAsImage } from '../utils/exportAsImage';
import { IPagePlaceholderStyleExportData } from '../services/models/ExportHTMLModels';
import { timeout } from '../utils/timeout';

export enum EPageState {
  loading = 0,
  loaded,
  unsaved,
  saved,
  saving,
  disconnected,
  reconnecting,
}
enum ECopyState {
  copy,
  cut,
}
export interface ISaveTimer {
  timeLastChange: number;
  timeLastSave: number;
  timeUpdateServer: number;
  timeForceSave: number;
}

interface IPlaceholderRefsModel {
  ref: HTMLDivElement;
  placeholder: IPagePlaceholder;
}

//TODO: pageState og savePageHTML må ikke endres før noe i kurset er endret.
//Nå blir det sendt med en gang du loader siden i kurset, noe som gjør at kurset får ny modified date uten at noe er endret.

//TODO: Gjør om kopiering til å ta i bruk brukerens clipboard kanskje??
//TODO: Fiks alle MobX feilmeldinger, MediaLibraryStore@10.currentFolderContent, MediaLibraryStore@10.currentFolderPath, MediaLibraryStore@10.libraryLoading,PageStore@3.coursePage.placeholders[..].contents
export default class PageStore {
  pageState: EPageState = EPageState.unsaved;
  contentState: EPageState = EPageState.unsaved;
  coursePage: TPageViewModel | undefined = undefined;
  coursePageProperties: IPageProperties | undefined = undefined;
  coursePagePreview: TPageViewModel | undefined = undefined;
  previewSource = '';
  selectedPlaceholder: IPagePlaceholder | undefined = undefined;
  placeholderProperties: IPlaceholderProperties | undefined = undefined;
  selectedContent: IContent | undefined = undefined;
  selectedEnquiryContent: IContent | undefined = undefined;
  selectedEnquiryAlternativeContent: IContent | undefined = undefined;
  contentProperties: IContentProperties | undefined = undefined;
  enquiryContentObject: IEnquiryContentObjectProperties | undefined = undefined;
  enquiryProperties: EnquiryContentProperties | undefined = undefined;

  editorRef: HTMLDivElement | null = null;
  tinyMCETrigger = false; //This is just a trigger for Content.tsx to react to.

  coursePagePreviewOpen = false;
  loading = false;
  loadingInitial = true;
  loadingExtra = false;
  domParser = new DOMParser();

  pagePlaceholderRefs: IPlaceholderRefsModel[] = [];

  enableSwap = false;

  isSaving = false;

  //currentPageId = 1;
  currentCoursePageId = 1;
  public readonly pagePlaceholders: Array<TPagePlaceholder> = [];

  defaultTextPadding = '15px';

  constructor() {
    makeAutoObservable(this);
  }

  resetPagePlaceholderRefs = () => {
    this.pagePlaceholderRefs = [];
  };

  addPagePlaceholderRefs = (ref: HTMLDivElement, placeholder: IPagePlaceholder) => {
    this.pagePlaceholderRefs.push({ ref: ref, placeholder: placeholder });
  };

  timeLastSave = 0;

  savePageHTMLTimer = async () => {
    //Updates html only when there has been more than 1 second since last save.
    const now = Date.now();
    if (this.timeLastSave + 1000 < now) {
      this.timeLastSave = now;
      this.savePageHTML();
      if (this.selectedPlaceholder && this.selectedContent) {
        const exportData = await this.handleContentSpecificSave(this.selectedContent);
        this.selectedPlaceholder.styleSaveData = exportData?.styleExportData;
        this.selectedPlaceholder.scriptSaveData = exportData?.scriptExportData;
        this.updatePlaceholder(this.selectedPlaceholder);
      }
    }
  };

  savePageHTML = async () => {
    if (this.coursePage === undefined) return;

    exportAsImage(this.editorRef);

    const placeholderExportData: IPagePlaceholderExportData[] = [];

    for (let index = 0; index < this.pagePlaceholderRefs.length; index++) {
      const element = this.pagePlaceholderRefs[index];
      const data = await this.SavePlaceholderRenderedHTML(element.placeholder, element.ref.outerHTML);

      if (element.placeholder.contents.length > 0) {
        const exportData = await this.handleContentSpecificSave(element.placeholder.contents[0]);
        element.placeholder.styleSaveData = exportData?.styleExportData;
        element.placeholder.scriptSaveData = exportData?.scriptExportData;
      }

      const exportData: IPagePlaceholderExportData = {
        coursePageID: element.placeholder.coursePageID,
        pagePlaceholderID: element.placeholder.pagePlaceholderID,
        index: index,
        data: data,
        style: element.placeholder.styleSaveData,
        script: element.placeholder.scriptSaveData,
      };
      placeholderExportData.push(exportData);
    }

    const pageExportData: ICoursePageExportData = {
      courseID: this.coursePage.courseID,
      coursePageID: this.coursePage.coursePageID,
      exportData: placeholderExportData,
    };

    await agent.Page.UpdateCoursePageHtml(pageExportData).then(() => {
      this.setPageState(EPageState.saved);
    });
  };

  SavePlaceholderRenderedHTML = async (placeholder: IPagePlaceholder, aHtml: string) => {
    let htmlData = aHtml;

    if (placeholder.contents.length > 0 && placeholder.contents[0].contentType === EContentType.OnLineVideo) {
      htmlData = await this.handleSaveOnlineVideo(placeholder, htmlData);
    }

    const doc = this.domParser.parseFromString(htmlData, 'text/html');

    //This is to stop react from giving an error when opening preview. React doesnt like contenteditable attribute.
    const contenteditable = doc.querySelectorAll('[contenteditable]');
    contenteditable.forEach((item) => {
      item.removeAttribute('contenteditable');
    });

    const donotrenderanything = doc.querySelectorAll('[data-donotrenderanything]');
    donotrenderanything.forEach((item) => {
      item.remove();
    });

    //This removes elements that as the attribute "data-donotrender", but keeps the children of these elements.
    //This allows manipulation of objects, such as the orange border without having it show up on the preview.
    const doNotRender = doc.querySelectorAll('[data-donotrender]');
    doNotRender.forEach((item) => {
      item.replaceWith(...Array.from(item.childNodes));
    });

    placeholder.renderedHTML = doc.body.innerHTML;
    return doc.body.innerHTML;
  };

  //youtube
  //?autoplay=1&cc_load_policy=1&controls=1&fs=1&loop=0&modestbranding=1&start=0

  //vimeo
  //?autoplay=0&loop=0&controls=1#t=0s
  handleSaveOnlineVideo = async (placeholder: IPagePlaceholder, html: string) => {
    const properties = await agent.Page.GetPagePlaceholderContentProperties(placeholder.courseID, placeholder.coursePageID, placeholder.pagePlaceholderID, placeholder.contents[0].contentID);

    const autoplay = properties.onlineVideo.items.autoPlay.option.value === 'True' ? 1 : 0;
    const loop = properties.onlineVideo.items.loop.option.value === 'True' ? 1 : 0;
    const controls = properties.onlineVideo.items.controls.option.value === 'True' ? 1 : 0;
    const subtitle = properties.onlineVideo.items.subtitle.option.value === 'True' ? 1 : 0;
    const startTime = properties.onlineVideo.items.startTime.option.value;

    interface IMapObj {
      [key: string]: string;
    }
    const mapObj: IMapObj = {
      'autoplay=0': `autoplay=${autoplay}`,
      'cc_load_policy=0': `cc_load_policy=${subtitle}`,
      'controls=1': `controls=${controls}`,
      'loop=0': `loop=${loop}`,
      'start=0': `start=${startTime}`,
      '#t=0s': `#t=${startTime}s`,
    };

    const regEx = new RegExp(Object.keys(mapObj).join('|'), 'gi');
    html = html.replace(regEx, function (matched) {
      return mapObj[matched];
    });

    return html;
  };

  setPageState = (state: EPageState) => {
    this.pageState = state;

    if (state === EPageState.unsaved) {
      this.saveTimer.timeLastChange = Date.now();
      //this.saveContent();
    }
  };

  setContentState = (state: EPageState) => {
    this.contentState = state;

    if (state === EPageState.unsaved) {
      //this.saveContent();
    }
  };

  //TODO: Flytt "setLastPageVisited" til der man kjører en endring???
  getPage = async (courseId: number, coursePageId: number, fetchPageState?: number) => {
    this.setPageState(EPageState.saved);

    this.setLoading(true);
    this.resetPagePlaceholderRefs();
    this.deselectContent();
    this.deselectPlaceholder();

    runInAction(() => {
      this.coursePageProperties = undefined;
      this.placeholderProperties = undefined;
    });
    this.editorRef = null;
    let coursePage_Temp: TPageViewModel | undefined = undefined;
    this.setLastPageVisited(courseId, coursePageId); //Set currentpage to last visited
    try {
      if (fetchPageState) {
        coursePage_Temp = await agent.Page.getPageFetchState(courseId, fetchPageState);
      } else {
        coursePage_Temp = await agent.Page.getPage(courseId, coursePageId);
      }
      try {
        coursePage_Temp = this.checkResizeDirections(coursePage_Temp);
        await this.setCoursePage(coursePage_Temp);

        this.currentCoursePageId = coursePage_Temp.coursePageID;
        this.getPageProperties(courseId, coursePageId).then((properties) => {
          runInAction(() => {
            this.coursePageProperties = properties;
          });
        });
        coursePage_Temp.isSaved = true;
        this.getPlaceholderList();
      } catch (error) {
        console.log(error);
      }

      if (this.coursePage !== undefined) {
        this.coursePage.placeholders.forEach(async (pholder) => {
          const keys = ['paddingLeft', 'paddingRight', 'paddingTop', 'paddingBottom'];
          const largePaddings: string[] = [];
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (pholder.colorAndDesign[key]) {
              const value = parseInt(pholder.colorAndDesign[key].replace('px', ''));
              if (isNaN(value)) continue;
              if (value > pholder.height) {
                largePaddings.push(key);
              }
            }
          }
          if (largePaddings.length > 0) {
            const properties = await agent.Page.GetPagePlaceholderProperties(courseId, coursePageId, pholder.pagePlaceholderID);
            const propertyList: IPropertyValue[] = [];
            for (let i = 0; i < largePaddings.length; i++) {
              const key = largePaddings[i];
              propertyList.push({ propertyValueView: properties.colorAndDesign.items[key], value: this.defaultTextPadding });
              runInAction(() => {
                pholder.colorAndDesign[key] = this.defaultTextPadding;
              });
            }
            store.propertyStore.setOptionList(properties.colorAndDesign, propertyList);
          }
        });
      }

      this.setLoading(false);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.deselectCourse();
      this.setLoadingInitial(false);
    }
  };

  getPageProperties = async (courseId: number, coursePageId: number) => {
    return await agent.Page.getProperties(courseId, coursePageId);
  };

  setLastPageVisited = async (courseId: number, coursePageId: number) => {
    if (!store.courseStore.courseProperties) {
      await store.courseStore.getCourseProperties(courseId);
    }
    if (store.courseStore.courseProperties) {
      store.propertyStore.setOption(store.courseStore.courseProperties.settings.items.lastPageVisited, coursePageId.toString());
    }
  };

  getCoursePage = () => {
    return this.coursePage;
  };

  getCoursePageProperties = () => {
    return this.coursePageProperties;
  };

  openPagePreview = async () => {
    await this.savePageHTML();
    store.previewStore.openPagePreview();
  };

  getPlaceholderList = (): void => {
    if (this.coursePage) {
      for (let i = 0; i < this.coursePage.placeholders.length; i++) {
        const serverData = this.coursePage.placeholders[i];
        const newPagePlaceholder = new TPagePlaceholder(serverData);
        this.pagePlaceholders.push(newPagePlaceholder);
        //TODO: sircular referanse
        serverData.pagePlaceholder = newPagePlaceholder;
        //console.log(serverData.pagePlaceholderID);
      }
      Layout.updateLayout(0, 0, 960, 540);
    }
  };

  checkResizeDirections = (aCoursePage: TPageViewModel) => {
    const compare = (a: number, b: number) => {
      return Math.abs(a - b) <= 2;
    };

    for (let i = 0; i < aCoursePage.placeholders.length; i++) {
      const placeholder = aCoursePage.placeholders[i];
      placeholder.resizeDirections = { left: false, top: false, right: false, bottom: false };
      if (placeholder.deleted) continue;
      for (let y = 0; y < aCoursePage.placeholders.length; y++) {
        if (aCoursePage.placeholders[y].deleted) continue;

        if (compare(placeholder.bottom, aCoursePage.placeholders[y].top)) {
          placeholder.resizeDirections.bottom = true;
        }

        if (compare(placeholder.top, aCoursePage.placeholders[y].bottom)) {
          placeholder.resizeDirections.top = true;
        }

        if (compare(placeholder.left, aCoursePage.placeholders[y].right)) {
          placeholder.resizeDirections.left = true;
        }

        if (compare(placeholder.right, aCoursePage.placeholders[y].left)) {
          placeholder.resizeDirections.right = true;
        }
      }
    }
    return aCoursePage;
  };

  selectPlaceholder = async (placeholder: IPagePlaceholder) => {
    if (this.coursePage !== undefined) {
      this.placeholderProperties = undefined;
      if (this.selectedContent?.pagePlaceholderID !== placeholder.pagePlaceholderID || this.selectedEnquiryContent?.pagePlaceholderID !== placeholder.pagePlaceholderID) {
        this.deselectContent();
      }
      this.selectedPlaceholder = placeholder;

      const properties = await agent.Page.GetPagePlaceholderProperties(this.coursePage.courseID, this.coursePage.coursePageID, placeholder.pagePlaceholderID);
      runInAction(() => {
        this.placeholderProperties = properties;
      });
    }
  };

  deselectPlaceholder = () => {
    this.deselectContent();
    this.selectedPlaceholder = undefined;
    this.placeholderProperties = undefined;
  };

  /*   selectEnquiryContent = async (placeholder: IPagePlaceholder, content: IContent) => {
    if (this.coursePage === undefined) return;
    if (this.selectedPlaceholder?.pagePlaceholderID != placeholder.pagePlaceholderID) {
      this.selectPlaceholder(placeholder);
    }
    this.selectedContent = undefined;
    this.selectedEnquiryAlternativeContent = undefined;
    this.selectedEnquiryContent = content;

    this.enquiryProperties = undefined;
  };

  selectEnquiryAlternativeContent = async (placeholder: IPagePlaceholder, content: IContent) => {
    if (this.coursePage === undefined) return;
    if (this.selectedPlaceholder?.pagePlaceholderID != placeholder.pagePlaceholderID) {
      this.selectPlaceholder(placeholder);
    }
    this.selectedEnquiryContent = undefined;
    this.selectedEnquiryAlternativeContent = content;

    this.enquiryProperties = undefined;
  }; */

  //This is true if the sidebar is in tab one.
  showToolbar = true;
  setShowToolbar = async (b: boolean) => {
    this.showToolbar = b;
  };

  selectContent = async (placeholder: IPagePlaceholder, content: IContent) => {
    if (this.coursePage === undefined) return;
    this.selectedEnquiryContent = undefined;
    this.deselectContent();
    //this.selectedEnquiryAlternativeContent = undefined;
    if (this.selectedPlaceholder?.pagePlaceholderID != placeholder.pagePlaceholderID) {
      this.selectPlaceholder(placeholder);
    }
    this.selectedContent = content;

    agent.Page.GetPagePlaceholderContentProperties(this.coursePage.courseID, this.coursePage.coursePageID, placeholder.pagePlaceholderID, content.contentID).then((contentProperties) => {
      runInAction(async () => {
        this.contentProperties = contentProperties;
      });
    });

    if (content.enquiryContent) {
      const type = Object.keys(content.enquiryContent)[0];

      if (type === 'question') {
        const enquiryTypes = Object.keys(content.enquiryContent.question);

        switch (enquiryTypes[0]) {
          case 'multipleAlternative':
          case 'singleAlternative':
            agent.Enquiry.getEnquiryContentProperties(content.contentID).then((properties) => {
              runInAction(async () => {
                this.enquiryProperties = properties;
              });
            });
            //this.enquiryProperties = await agent.Enquiry.getEnquiryContentProperties(content.contentID);
            break;
          case 'flashCard':
            agent.Enquiry.getEnquiryContentObject(content.enquiryContent.question.flashCard.alternatives[0].enquiryContent.alternative.flashCard).then((properties) => {
              this.enquiryContentObject = properties;
            });
            break;
          case 'matching':
            agent.Enquiry.getEnquiryContentObject(content.enquiryContent.question.matching.alternatives[0].enquiryContent.alternative.matching).then((properties) => {
              this.enquiryContentObject = properties;
            });
            break;
          case 'sorting':
            agent.Enquiry.getEnquiryContentObject(content.enquiryContent.question.sorting.alternatives[0].enquiryContent.alternative.sorting).then((properties) => {
              this.enquiryContentObject = properties;
            });
            break;

          default:
            break;
        }
      } else {
        agent.Enquiry.getEnquiryContentProperties(content.contentID).then((properties) => {
          runInAction(async () => {
            this.enquiryProperties = properties;
          });
        });
      }
    } else {
      runInAction(async () => {
        this.enquiryProperties = undefined;
        this.enquiryContentObject = undefined;
      });
    }
    return;
  };

  getEnquiryContentProperties = async () => {
    if (this.selectedContent === undefined && this.selectedEnquiryContent === undefined) return undefined;
    if (this.enquiryProperties !== undefined && this.enquiryProperties.contentID === this.selectedContent?.contentID) return this.enquiryProperties;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const content = this.selectedEnquiryContent !== undefined ? this.selectedEnquiryContent : this.selectedContent!; //Hvis selectedEnquiryConten er undefined, så kan ikke selectedContent være undefined.

    this.enquiryProperties = await agent.Enquiry.getEnquiryContentProperties(content.contentID);
    return this.enquiryProperties;
  };

  getEnquiryContentPropertiesFromContent = async (contentID: number) => {
    let properties: EnquiryContentProperties | undefined = undefined;
    properties = await agent.Enquiry.getEnquiryContentProperties(contentID);
    return properties;
  };

  deselectContent = () => {
    this.selectedContent = undefined;
    this.selectedEnquiryContent = undefined;
    this.enquiryProperties = undefined;
    this.selectedEnquiryAlternativeContent = undefined;
    this.enquiryContentObject = undefined;
    this.contentProperties = undefined;
  };

  getEnquiryContentObject = async (contentObject: IEnquiryQuestionProperties) => {
    return (this.enquiryContentObject = await agent.Enquiry.getEnquiryContentObject(contentObject));
  };

  setEnquiryContentObject = (properties: IEnquiryContentObjectProperties) => {
    this.enquiryContentObject = { ...properties };
  };

  unsetEnquiryContentObject = () => {
    this.enquiryContentObject = undefined;
  };

  MovePlaceholder = async (placeholder: IPagePlaceholder, top: number, left: number) => {
    placeholder.top = top;
    placeholder.left = left;
    placeholder.hasChanged = true;
    this.checkUpdatePlaceholder();
  };

  ResizePlaceholder = async (placeholder: IPagePlaceholder, top: number, left: number, width: number, height: number) => {
    if (this.coursePage?.isLayout) {
      for (let i = 0; i < this.pagePlaceholders.length; i++) {
        const pagePlaceHolder = this.pagePlaceholders[i];
        if (pagePlaceHolder.serverData.pagePlaceholderID === placeholder.pagePlaceholderID) {
          pagePlaceHolder.serverData.top = placeholder.top = top;
          pagePlaceHolder.serverData.left = placeholder.left = left;
          pagePlaceHolder.serverData.width = placeholder.width = width;
          pagePlaceHolder.serverData.height = placeholder.height = height;
          pagePlaceHolder.serverData.hasChanged = placeholder.hasChanged = true;
        }
        pagePlaceHolder.ResizePlaceholder(pagePlaceHolder.serverData.pagePlaceholderID === placeholder.pagePlaceholderID);
      }
      Layout.updateLayout(0, 0, 960, 540);
    }
    this.checkUpdatePlaceholder();
  };

  saveTimeout: NodeJS.Timeout | undefined = undefined;
  saveTimer: ISaveTimer = { timeLastChange: 0, timeLastSave: 0, timeUpdateServer: 500, timeForceSave: 5000 };

  //Lagrer så fort det går 500ms mellom tastetrykk eller det er lenger enn 5 sekunder siden siste oppdatering.
  saveContent = (content: IContent) => {
    if (this.saveTimeout !== undefined) {
      const currentTime = Date.now();
      const timeElapsed = currentTime - this.saveTimer.timeLastChange;
      if (timeElapsed > this.saveTimer.timeForceSave) {
        this.saveTimeout = undefined; //resetter saveTimeout så den kan starte på nytt.
        clearTimeout(this.saveTimeout);
      }

      return; // returnere fordi saveTimeout er ikke null, som vil si det er en timeout aktiv.
    }

    this.saveTimeout = setTimeout(async () => {
      const currentTime = Date.now();
      const timeElapsed = currentTime - this.saveTimer.timeLastChange;
      /* const timeSinceSave = currentTime - this.saveTimer.timeLastSave; */

      if (timeElapsed >= this.saveTimer.timeUpdateServer /* || timeSinceSave >= this.saveTimer.timeForceSave */) {
        await this.updateContent(content).then((updatedContent) => {
          this.saveTimer.timeLastSave = Date.now();
          if (updatedContent !== undefined) {
            runInAction(async () => {
              content.contentID = updatedContent.contentID; //replaces the contentID of the old content with the updatedContent ID
            });
          }
        });
        if (this.selectedPlaceholder) {
          const exportData = await this.handleContentSpecificSave(content);
          this.selectedPlaceholder.styleSaveData = exportData?.styleExportData;
          this.selectedPlaceholder.scriptSaveData = exportData?.scriptExportData;
          this.updatePlaceholder(this.selectedPlaceholder);
        }
        this.savePageHTML();
        this.saveTimeout = undefined; //resetter saveTimeout så den kan starte på nytt.
        clearTimeout(this.saveTimeout);
      } else {
        this.saveTimeout = undefined; //resetter saveTimeout så den kan starte på nytt.
        clearTimeout(this.saveTimeout);
        this.saveContent(content);
      }
    }, this.saveTimer.timeUpdateServer);
  };

  //TODO: Need a better solution to handle both enquiryContent and normal content. What if both need something?

  //This will be to handle specific saves for contents. IE classicAccordion needs to save to palceholder.styleSaveData.
  handleContentSpecificSave = async (aContent: IContent) => {
    const placeholderExportData: { styleExportData: IPagePlaceholderStyleExportData; scriptExportData: IPagePlaceholderScriptExportData } = {
      styleExportData: {},
      scriptExportData: {},
    };

    let properties = this.contentProperties;
    if (properties?.contentID !== aContent.contentID) properties = await this.getPlaceholderContetProperties(aContent);

    if (properties === undefined) {
      return;
    }

    switch (aContent.contentType) {
      case EContentType.Image:
        {
          if (!properties.image.items.showFullscreenButton?.option.value) return;

          placeholderExportData.scriptExportData.fileName = 'ImageContent.js';
          placeholderExportData.scriptExportData.where = 'let buttonID, containerID, imageContentID;';
          placeholderExportData.scriptExportData.js = [
            `let buttonID = "imageButton${aContent.contentID}";`,
            `let containerID = "imageContainer${aContent.contentID}";`,
            `let imageContentID = "imageContent${aContent.contentID}";`,
          ];

          return placeholderExportData;
        }
        break;
      default:
        break;
    }

    if (aContent.enquiryContent === null) {
      return;
    }

    const enquiryContent = Object.keys(aContent.enquiryContent)[0];
    const enquiryType = Object.keys(aContent.enquiryContent[enquiryContent])[0];

    //TODO: Husk å gjøre ClassicAccordion.js dynamisk slik at man kan flere accordions på samme side.
    switch (enquiryType) {
      case EEnquiryContentType.classicAccordion:
        {
          placeholderExportData.styleExportData.fileName = 'ClassicAccordion.css';
          placeholderExportData.styleExportData.where = '.classicAccordion {}';
          placeholderExportData.styleExportData.css = [
            '.classicAccordion {',
            `--classic-sections-color: ${properties.classicAccordion.items.sectionsColor.option.value};`,
            `--classic-border-color: ${properties.classicAccordion.items.borderColor.option.value};`,
            `--classic-panels-color: ${properties.classicAccordion.items.panelsColor.option.value};`,
            `--classic-panel-gap: ${properties.classicAccordion.items.sectionsGap.option.value}px;`,
            `--classic-border-radius: ${properties.classicAccordion.items.borderRadius.option.value}px;`,
            `--classic-justify-vertical: ${properties.classicAccordion.items.justifySection.option.value};`,
            '}',
          ];
          placeholderExportData.scriptExportData.fileName = 'ClassicAccordion.js';

          return placeholderExportData;
        }
        break;
      case EEnquiryContentType.trueOrFalse:
        {
          placeholderExportData.styleExportData.fileName = 'trueFalse.css';

          return placeholderExportData;
        }
        break;
      case EEnquiryContentType.multipleAlternative:
        {
          placeholderExportData.styleExportData.fileName = 'multipleChoice.css';

          return placeholderExportData;
        }
        break;
      case EEnquiryContentType.singleAlternative: {
        placeholderExportData.styleExportData.fileName = 'singleChoice.css';

        return placeholderExportData;
      }
      case EEnquiryContentType.categorization:
        {
          const mainContent = this.coursePage?.placeholders.find((x) => x.pagePlaceholderID === aContent.pagePlaceholderID)?.contents[0];
          if (!mainContent) return;
          placeholderExportData.styleExportData.fileName = 'categorization.css';
          placeholderExportData.styleExportData.where = '/*.columnHover {}*/';
          placeholderExportData.styleExportData.css = [
            '.columnHover {',
            `background-color: ${mainContent.enquiryContent.question.categorization.categorizationColumn.columnHoverColor} !important;`,
            '}',
          ];

          placeholderExportData.scriptExportData.fileName = 'Categorization.js';
          placeholderExportData.scriptExportData.where = 'const contentID = 0;';
          placeholderExportData.scriptExportData.js = [`const contentID = ${mainContent.contentID};`];

          return placeholderExportData;
        }
        break;
      default:
        break;
    }
  };

  SetContentInnerHTML = (content: IContent, innerHTML: string) => {
    runInAction(async () => {
      this.setPageState(EPageState.unsaved);
      content.innerHTML = innerHTML;
    });
    this.saveTimer.timeLastChange = Date.now();

    this.saveContent(content);
  };

  UpdateContentInnerHTMLFromID = async (contentID: number, innerHTML: string) => {
    if (this.coursePage === undefined) return;
    let content = undefined;

    for (let i = 0; i < this.coursePage.placeholders.length; i++) {
      const element = this.coursePage.placeholders[i];
      for (let y = 0; y < element.contents.length; y++) {
        const child = element.contents[y];
        if (child.contentID == contentID) {
          content = child;
          break;
        }
      }
    }
    if (content === undefined) {
      console.log('Did not find content');
      return;
    }

    this.setPageState(EPageState.unsaved);
    content.innerHTML = innerHTML;
    return this.saveContent(content);
  };

  private createPlaceholder = async (
    typeSpecific: (placeholder: IPagePlaceholder, src?: string, width?: number, height?: number) => Promise<void>,
    content?: string,
    width?: number,
    height?: number,
  ) => {
    const newPlaceholder: IPagePlaceholder = await this.newPlaceholder();
    if (this.coursePage) {
      if (this.coursePage.placeholders.find((x) => x.pagePlaceholderID == newPlaceholder.pagePlaceholderID) === undefined) {
        runInAction(async () => {
          newPlaceholder.hasChanged = true;
          await typeSpecific(newPlaceholder, content, width, height);
          this.updatePlaceholder(newPlaceholder);
          if (this.coursePage) {
            const tempPlaceholderList: IPagePlaceholder[] = [...this.coursePage.placeholders];
            tempPlaceholderList.push(newPlaceholder);
            this.coursePage.placeholders = tempPlaceholderList;
          }
        });
      } else {
        console.log('HER SKJEDDE DET EN FEIL. NYE PALCEHOLDEREN EKSISTERER ALLEREDE I TPagePlaceholder');
      }
    }
  };

  imageContent = async (placeholder: IPagePlaceholder, src?: string, width?: number, height?: number) => {
    const newImageContent = await this.newContent(placeholder.pagePlaceholderID, EContentType.Image);
    if (newImageContent === undefined) return;
    if (placeholder) {
      placeholder.contents.push(newImageContent);
      if (src) {
        //const img: any = await this.getImgMeta(src);
        placeholder.contents[0].innerHTML = src;
        placeholder.height = height ? height : 100;
        placeholder.width = width ? width : 200;
        placeholder.hasChanged = true;
      }
    }
  };

  embedVideoContent = async (placeholder: IPagePlaceholder, src?: string, width?: number, height?: number) => {
    const newContent = await this.newContent(placeholder.pagePlaceholderID, EContentType.Video);
    if (newContent === undefined) return;

    placeholder.contents.push(newContent);
    if (src) {
      placeholder.contents[0].innerHTML =
        '<div style="width:100%; height:100%; position:relative;"> <iframe width="100%" height="100%" src="' +
        src +
        '" frameborder="0" allow="autoplay; encrypted-media;" allowfullscreen></iframe></div>';
      // placeholder.height = 290;
      // placeholder.width = 500;
      placeholder.height = height ? height : 100;
      placeholder.width = width ? width : 200;
    } else {
      console.log('Error. No source recieved. youtubeContent');
    }
  };

  createText = async (placeholder: IPagePlaceholder, content?: string) => {
    if (!this.coursePage) return;

    const newContentProperties: INewContentProperties = {
      paddingLeft: this.defaultTextPadding,
      paddingRight: this.defaultTextPadding,
      paddingTop: this.defaultTextPadding,
      paddingBottom: this.defaultTextPadding,
    };

    const newContent = await this.newContent(placeholder.pagePlaceholderID, EContentType.TextDynamic, newContentProperties);
    if (newContent === undefined) return;

    newContent.innerHTML = content ? content : '<div><span style="font-size: 14pt;" data-mce-style="font-size: 14pt;">Place text here...</span></div>';
    placeholder.contents.push(newContent);
    placeholder.colorAndDesign.paddingBottom = placeholder.colorAndDesign.paddingLeft = placeholder.colorAndDesign.paddingRight = placeholder.colorAndDesign.paddingTop = this.defaultTextPadding;
    this.saveContent(newContent);
  };

  //TODO: Kanskje legge inn egenskaper for å skjule toolbar https://stackoverflow.com/questions/2104608/hiding-the-toolbars-surrounding-an-embedded-pdf
  //TODO: Legg til flere content typer. Image, video, xl, ppt, pdf.
  createFileEmbed = async (path: string, extension: string, aImgAltText?: string) => {
    if (this.selectedPlaceholder === undefined) return;
    if (this.coursePage === undefined) return;

    agent.Page.GetPagePlaceholderProperties(this.coursePage.courseID, this.coursePage.coursePageID, this.selectedPlaceholder.pagePlaceholderID).then(() => {
      //console.log(response);
    });

    if (this.selectedPlaceholder.contents.length > 0 && this.selectedContent !== undefined) {
      this.replaceImage(path, this.selectedContent);
      return;
    }

    let contentType = EContentType.TextStatic;
    let innerHTML = path;
    extension = extension.toLocaleLowerCase();
    switch (extension) {
      case '.jpg':
      case '.jpeg':
      case '.png':
      case '.svg':
      case '.gif':
      case '.webp':
      case '.avif':
      case '.jfif':
        return this.createImage(innerHTML, undefined, undefined, aImgAltText);
        break;
      case '.mp4':
      case '.avi':
      case '.mov':
      case '.mpeg':
      case '.ogv':
      case '.webm':
      case '.mkv':
        innerHTML = path;
        contentType = EContentType.Video;
        break;
      case '.mp3':
      case '.m4a':
        innerHTML = `<audio controls style="max-width:100%; object-fit: contain;">
                      <source src="${path}" type="audio/mpeg">Your browser does not support the audio tag.</audio>`;
        contentType = EContentType.Audio;
        break;
      case '.pdf':
        innerHTML = `<object data="${path}" type="application/pdf" width="100%" height="100%">
                <p>This browser does not support PDFs. Please download the PDF to view it: 
                <a href="${path}">Download PDF</a>.</p>
            </object>`;
        contentType = EContentType.PDF;
        break;
      //Vi kan også legge inn HTML fil her og bruke iframe. Husk å bruk "sandbox" som et atributt til iframe'n.
      /*         case '.xlsx': //TODO: Embed tag is deprecated. Hopefully the object tag will work on android devices etc soon.
          innerHTML = `<object data="https://docs.google.com/viewer?url=${file.webPath}&embedded=true" type="application/xlsx" width="100%" height="100%">
                <embed src="https://docs.google.com/viewer?url=${file.webPath}&embedded=true" width="100%" height="100%" />
                    <p>This browser does not support excel. Please download the excel to view it: 
                    <a href="${file.webPath}">Download excel</a>.</p>
                </embed></object>`;
          contentType = EContentType.Excel;
          break;
        case '.pptx': //TODO: Embed tag is deprecated. Hopefully the object tag will work on android devices etc soon.
          innerHTML = `<object data="https://docs.google.com/viewer?url=${file.webPath}&embedded=true" type="application/pptx" width="100%" height="100%">
                    <embed src="https://docs.google.com/viewer?url=${file.webPath}&embedded=true" width="100%" height="100%" />
                        <p>This browser does not support pptx. Please download the pptx to view it: 
                        <a href="${file.webPath}">Download pptx</a>.</p>
                    </embed></object>`;
          contentType = EContentType.PowerPoint;
          break; */
      default:
        SnackbarUtils.warning('Filetype not supported');
        return false;
    }

    const newContent = await this.newContent(this.selectedPlaceholder.pagePlaceholderID, contentType);
    if (newContent === undefined) return false;
    newContent.innerHTML = innerHTML;
    this.selectedPlaceholder.contents.push(newContent);
    this.saveContent(newContent);
    return newContent;
  };

  createImage = async (src: string, width?: number, height?: number, aImgAltText?: string) => {
    if (this.coursePage === undefined || this.selectedPlaceholder === undefined) return;

    const newContent = await this.newContent(this.selectedPlaceholder.pagePlaceholderID, EContentType.Image);
    if (newContent === undefined) return;
    const contentProperties = await agent.Page.GetPagePlaceholderContentProperties(this.coursePage.courseID, this.coursePage.coursePageID, newContent.pagePlaceholderID, newContent.contentID);

    const propertyList: IPropertyValue[] = [
      { propertyValueView: contentProperties.CSS.items.objectFit, value: 'cover' },
      { propertyValueView: contentProperties.CSS.items.objectPosition, value: '50% 50%' },
    ];

    if (aImgAltText) {
      propertyList.push({ propertyValueView: contentProperties.image.items.altText, value: aImgAltText });
    }

    store.propertyStore.setOptionList(contentProperties.CSS, propertyList);

    newContent.innerHTML = src;

    runInAction(() => {
      newContent.CSS.objectFit = 'cover';
      newContent.CSS.objectPosition = '50% 50%';

      if (newContent.image?.altText !== undefined && aImgAltText) {
        newContent.image.altText = aImgAltText;
      }
      if (this.selectedPlaceholder !== undefined) this.selectedPlaceholder.contents.push(newContent);
    });

    this.saveContent(newContent);
    return newContent;
  };

  replaceImage = async (src: string, content: IContent, aImgAltText?: string) => {
    this.SetContentInnerHTML(content, src);
    runInAction(() => {
      if (content.image?.altText !== undefined && aImgAltText) {
        agent.Page.GetPagePlaceholderContentProperties(content.courseID, content.coursePageID, content.pagePlaceholderID, content.contentID).then((properties) => {
          store.propertyStore.setOption(properties.image.items.altText, aImgAltText);
        });
        content.image.altText = aImgAltText;
      }
    });
  };

  //FileList
  uploadAndCreateFile = async (placeholder: IPagePlaceholder, files: FileList, folder?: string) => {
    if (this.selectedPlaceholder != undefined) {
      const response = await store.mediaLibraryStore.uploadFilesToFolder(files, folder ? folder : 'Upload');
      if (response !== undefined) {
        const keys = Object.keys(response);

        if (keys.length < 1 || response[keys[0]].status === false) {
          SnackbarUtils.warning('Upload failed. Try again.');
          return;
        }

        const extension = /.[0-9a-zA-Z]+$/.exec(files[0].name);
        if (extension === null) return false;
        return this.createFileEmbed(response[keys[0]].url, extension[0]);
      }
    }
  };

  //File
  uploadAndCreateSingleImage = async (placeholder: IPagePlaceholder, file: File, folder?: string, aAltText?: string) => {
    if (this.selectedPlaceholder != undefined) {
      store.mediaLibraryStore.uploadImage(file, folder, aAltText).then((response) => {
        if (response.url !== '') {
          const extension = /.[0-9a-zA-Z]+$/.exec(file.name);
          if (extension === null) return;

          this.createFileEmbed(response.url, extension[0], response.altText);
        }
      });
    }
  };

  /*
        urltoFile(video.img, `${video.title}-thumbnail.png`, 'image/png').then(function (file:File) {
        agent.Property.uploadPhoto(coursePageProperties.descriptions.items.avatar.propertyValueID, 'Hidden', file);
      });
  */

  //Player parameters
  //youtube
  //?autoplay=1&cc_load_policy=1&controls=1&fs=1&loop=0&modestbranding=1&start=0

  //vimeo
  //?autoplay=0&loop=0&controls=1#t=0s
  createEmbedOnlineVideo = async (src: string, thumbnailUrl?: string, width?: number, height?: number) => {
    if (this.selectedPlaceholder == undefined) {
      this.createPlaceholder(this.embedVideoContent, src, width, height);
    } else {
      const newContentProperties: INewContentProperties = {
        paddingLeft: this.defaultTextPadding,
        paddingRight: this.defaultTextPadding,
        paddingTop: this.defaultTextPadding,
        paddingBottom: this.defaultTextPadding,
      };
      const newContent = await this.newContent(this.selectedPlaceholder.pagePlaceholderID, EContentType.OnLineVideo, newContentProperties);
      if (newContent === undefined) return;
      const n = src.lastIndexOf('/');
      const videoID = src.substring(n + 1);
      let playerParameters = `?autoplay=0&rel=0&cc_load_policy=0&controls=1&enablejsapi=1&fs=1&loop=0&modestbranding=1&start=0&playlist=${videoID}`;
      if (src.includes('vimeo')) {
        let paramterSeperator = '?';
        if (videoID.includes('?')) {
          paramterSeperator = '&';
        }
        playerParameters = `${paramterSeperator}autoplay=0&loop=0&controls=1#t=0s`;
      }
      newContent.innerHTML = src + playerParameters;
      this.selectedPlaceholder.contents.push(newContent);

      /*       if (thumbnailUrl !== undefined) {
        agent.Page.GetPagePlaceholderContentProperties(newContent.courseID, newContent.coursePageID, newContent.pagePlaceholderID, newContent.contentID).then((properties) => {
          //agent.Property.UploadFileFromUrl(properties.onlineVideo.items.thumbnail.propertyValueID, 'Hidden', thumbnailUrl);
        });
      } */
      this.saveContent(newContent);
    }
  };

  newPlaceholder = async () => {
    return await agent.Page.NewPlaceholder(this.currentCoursePageId);
  };

  newContent = async (pagePlaceholderID: number, coursePageContentType: EContentType, newContentProperties?: INewContentProperties) => {
    if (this.coursePage === undefined) return;
    const newContent = await agent.Page.NewContent(this.coursePage?.courseID, this.coursePage?.coursePageID, pagePlaceholderID, coursePageContentType.valueOf(), newContentProperties);
    return newContent;
  };

  updateContent = async (content: IContent) => {
    const newContent = await agent.Page.updateContent(content);
    this.setContentState(EPageState.saved);
    return newContent;
  };

  //Setter et alternativ til true og de andre til false. Bare for single choice.
  updateSingleChoiceAlternative = (aQuestionContent: IContent, aAlternative: IContent, aCorrect: boolean) => {
    const updateList: IContent[] = [];
    runInAction(async () => {
      aAlternative.enquiryContent.alternative.singleAlternative.isCorrect = aCorrect;
      updateList.push(aAlternative);

      for (let I = 0; I < aQuestionContent.enquiryContent.question.singleAlternative.alternatives.length; I++) {
        const element = aQuestionContent.enquiryContent.question.singleAlternative.alternatives[I];
        if (element.enquiryContent.alternative.singleAlternative.isCorrect === aCorrect && element.contentID != aAlternative.contentID) {
          element.enquiryContent.alternative.singleAlternative.isCorrect = !aCorrect;
          updateList.push(element);
        }
      }
    });
    agent.Page.updateContents(updateList);
  };

  //Setter et alternativ til true og de andre til false. Bare for multiple choice og true false.
  setAlternativeCorrect = (aAlternative: IContent, aCorrect: boolean, aType: 'multipleAlternative' | 'trueOrFalse') => {
    runInAction(async () => {
      aAlternative.enquiryContent.alternative[aType].isCorrect = aCorrect;

      agent.Page.updateContent(aAlternative).then((res) => {
        aAlternative = res;
        this.setContentState(EPageState.saved);
      });
    });
  };

  checkUpdatePlaceholder = async () => {
    if (this.coursePage) {
      for (let i = 0; i < this.coursePage.placeholders.length; i++) {
        const placeholder = this.coursePage.placeholders[i];
        this.checkPlaceholder(placeholder);
      }
    }
  };

  checkPlaceholder = async (placeholder: IPagePlaceholder) => {
    for (let i = 0; i < placeholder.placeholderChildren.length; i++) {
      this.checkPlaceholder(placeholder.placeholderChildren[i]);
    }
    this.updatePlaceholder(placeholder);
  };

  updatePlaceholder = async (placeholder: IPagePlaceholder) => {
    if (placeholder.hasChanged) {
      if (this.coursePage === undefined) return;
      this.setPageState(EPageState.unsaved);
      placeholder.top = Math.round(placeholder.top);
      placeholder.left = Math.round(placeholder.left);
      placeholder.right = Math.round(placeholder.right);
      placeholder.bottom = Math.round(placeholder.bottom);
      const tmpPlaceholder = { ...placeholder };
      tmpPlaceholder.pagePlaceholder = undefined;
      await agent.Page.updatePlaceholder(tmpPlaceholder);
      this.savePageHTMLTimer();
      placeholder.hasChanged = false;
    }
  };

  deletePlaceholder = async (placeholder: IPagePlaceholder) => {
    await agent.Page.DeletePlaceholder(placeholder.coursePageID, placeholder.pagePlaceholderID);

    if (this.coursePage) {
      const tempPlaceholderList: IPagePlaceholder[] = []; // = { ...this.coursePage.placeholders };
      for (let i = 0; i < this.coursePage.placeholders.length; i++) {
        const element = this.coursePage.placeholders[i];
        if (element.pagePlaceholderID != placeholder.pagePlaceholderID) {
          tempPlaceholderList.push(element);
        }
      }
      this.coursePage.placeholders = tempPlaceholderList;
      this.placeholderProperties = undefined;
    }
  };

  deleteContent = async (content: IContent, placeholder: IPagePlaceholder, parentContent?: IContent) => {
    if (document.activeElement?.hasAttribute('data-editor')) return; //Dont delete content if editor is active.

    if (content.enquiryContent?.question?.categorization) {
      return this.deleteCategorizationColumn(content, placeholder, parentContent);
    }

    //Dette er for å håndtere sletting av alternative uten at det krøller til noe. Samme som skjer i multiple choice alt properties.
    //Se QuizAlternativeProperties.tsx linje 10
    if ((content.enquiryContent?.alternative !== undefined || content.enquiryContent?.accordionAlternative) && this.coursePage !== undefined) {
      await this.forceDeleteContent(content, placeholder);
      this.getPage(this.coursePage.courseID, this.coursePage.coursePageID);
      return;
    }

    this.forceDeleteContent(content, placeholder, parentContent);
  };

  deleteCategorizationColumn = async (content: IContent, placeholder: IPagePlaceholder, parentContent?: IContent) => {
    if (parentContent) {
      runInAction(async () => {
        parentContent.enquiryContent.question.categorization.alternatives = parentContent.enquiryContent.question.categorization.alternatives.filter((x) => x.contentID !== content.contentID);
      });
    }
    this.forceDeleteContent(content, placeholder, parentContent);
    return;
  };

  forceDeleteContent = async (content: IContent, placeholder: IPagePlaceholder, parentContent?: IContent) => {
    await agent.Page.DeleteContent(content.courseID, placeholder.coursePageID, placeholder.pagePlaceholderID, content.contentID);

    this.removeAlignProperties(placeholder);

    if (parentContent?.enquiryContent?.question?.multipleAlternative) {
      parentContent.enquiryContent.question.multipleAlternative.alternatives = parentContent.enquiryContent.question.multipleAlternative.alternatives.filter((x) => x.contentID !== content.contentID);
    } else if (parentContent?.enquiryContent?.question?.singleAlternative) {
      parentContent.enquiryContent.question.singleAlternative.alternatives = parentContent.enquiryContent.question.singleAlternative.alternatives.filter((x) => x.contentID !== content.contentID);
    } else if (this.coursePage) {
      const pHolder = this.coursePage.placeholders.find((x) => x.pagePlaceholderID === placeholder.pagePlaceholderID);
      if (pHolder) {
        runInAction(() => {
          pHolder.contents = pHolder.contents.filter((x) => x.contentID !== content.contentID);
        });
      }
    }
    if (content.contentID === this.selectedContent?.contentID) {
      this.deselectContent();
    }
    this.savePageHTML();
  };

  //This removes the align properties. This is called when you delete content of a placeholder.
  removeAlignProperties = async (placeholder: IPagePlaceholder) => {
    runInAction(() => {
      placeholder.colorAndDesign.display = '';
      placeholder.colorAndDesign.justifyContent = '';
      placeholder.colorAndDesign.alignItems = '';
    });

    const placeholderProperties = await agent.Page.GetPagePlaceholderProperties(placeholder.courseID, placeholder.coursePageID, placeholder.pagePlaceholderID);

    const propertyList: IPropertyValue[] = [
      { propertyValueView: placeholderProperties.colorAndDesign.items.display, value: '' },
      { propertyValueView: placeholderProperties.colorAndDesign.items.justifyContent, value: '' },
      { propertyValueView: placeholderProperties.colorAndDesign.items.alignItems, value: '' },
    ];
    store.propertyStore.setOptionList(placeholderProperties.colorAndDesign, propertyList);
  };

  //TODO: Gjør dette mer permanent. Flytt variabel opp blandt annet.
  copiedContent: IContent | undefined = undefined;
  sourcePlaceholder: IPagePlaceholder | undefined = undefined;
  copyState: ECopyState = ECopyState.copy;

  copyContent = async (content: IContent) => {
    this.copiedContent = content;
    this.copyState = ECopyState.copy;
  };

  pasteContent = async (placeholder: IPagePlaceholder) => {
    if (this.copiedContent === undefined) return;
    if (this.coursePage === undefined) return;

    for (let i = 0; i < placeholder.contents.length; i++) {
      await this.deleteContent(placeholder.contents[i], placeholder);
    }

    const copyObject: ICopyObject = {
      source: {
        courseID: this.copiedContent.courseID,
        coursePageID: this.copiedContent.coursePageID,
        pagePlaceholderID: this.copiedContent.pagePlaceholderID,
      },
      dest: {
        courseID: placeholder.courseID,
        coursePageID: placeholder.coursePageID,
        pagePlaceholderID: placeholder.pagePlaceholderID,
      },
    };

    await agent.Page.CopyContent(copyObject);

    await this.getPage(this.coursePage.courseID, this.coursePage.coursePageID);
  };

  createNewAccordion = async (placeholder: IPagePlaceholder) => {
    if (this.coursePage) {
      const newContentProperties: INewContentProperties = {
        paddingLeft: this.defaultTextPadding,
        paddingRight: this.defaultTextPadding,
        paddingTop: this.defaultTextPadding,
        paddingBottom: this.defaultTextPadding,
      };
      const newContent = await agent.Page.NewAccordion(
        this.coursePage.courseID,
        this.coursePage.coursePageID,
        placeholder.pagePlaceholderID,
        EEnquiryContentType.classicAccordion,
        newContentProperties,
      );

      runInAction(() => {
        placeholder.colorAndDesign.paddingBottom = placeholder.colorAndDesign.paddingLeft = placeholder.colorAndDesign.paddingRight = placeholder.colorAndDesign.paddingTop = this.defaultTextPadding;
        placeholder.contents.push(newContent);
      });

      this.saveContent(newContent);
    }
  };

  createNewAccordionSection = async () => {
    if (this.coursePage && this.selectedPlaceholder && this.enquiryProperties) {
      const content = this.selectedPlaceholder.contents[0];
      const typeKeys = Object.keys(content.enquiryContent)[0];
      const questionKeys = Object.keys(content.enquiryContent[typeKeys])[0];
      if (questionKeys.length > 0) {
        const enquiryID = content.enquiryContent[typeKeys][questionKeys].enquiryID;
        const enquiryContentID = content.enquiryContent[typeKeys][questionKeys].contentID;
        const newAlternatives: IContent = await agent.Page.NewAccordionSection(
          this.coursePage.courseID,
          enquiryID,
          enquiryContentID,
          this.currentCoursePageId,
          this.selectedPlaceholder.pagePlaceholderID,
          questionKeys[0],
        );
        content.enquiryContent = { ...newAlternatives.enquiryContent };
      }
    }
  };

  createNewQuestion = async (placeholder: IPagePlaceholder, enquiryContentType: EEnquiryContentType) => {
    if (this.coursePage) {
      const newContentProperties: INewContentProperties = {
        paddingLeft: this.defaultTextPadding,
        paddingRight: this.defaultTextPadding,
        paddingTop: this.defaultTextPadding,
        paddingBottom: this.defaultTextPadding,
      };
      const newContent = await agent.Page.NewQuestion(this.coursePage.courseID, this.coursePage.coursePageID, placeholder.pagePlaceholderID, enquiryContentType, newContentProperties);

      const acceptedTypes = [EEnquiryContentType.multipleAlternative, EEnquiryContentType.singleAlternative, EEnquiryContentType.trueOrFalse];
      if (acceptedTypes.includes(enquiryContentType)) {
        //Sette første alternativ til riktig.
        if (newContent.enquiryContent.question !== null) {
          const enqType = Object.keys(newContent.enquiryContent.question)[0];
          if (enqType !== 'trueOrFalse') {
            newContent.enquiryContent.question[enqType].alternatives[0].enquiryContent.alternative[enqType].isCorrect = true;
            await this.updateContent(newContent.enquiryContent.question[enqType].alternatives[0]);
          }
        }

        runInAction(() => {
          placeholder.colorAndDesign.paddingBottom = placeholder.colorAndDesign.paddingLeft = placeholder.colorAndDesign.paddingRight = placeholder.colorAndDesign.paddingTop = this.defaultTextPadding;
          placeholder.contents.push(newContent);
        });
      }

      this.saveContent(newContent);
    }
  };

  createNewAlternative = async () => {
    if (this.coursePage && this.selectedPlaceholder && this.enquiryProperties) {
      const content = this.selectedPlaceholder.contents[0];
      const questionKeys = Object.keys(content.enquiryContent.question);
      if (questionKeys.length > 0) {
        const enquiryID = content.enquiryContent.question[questionKeys[0]].enquiryID;
        const enquiryContentID = content.enquiryContent.question[questionKeys[0]].contentID;
        const newAlternatives: IContent = await agent.Page.NewAnswer(
          this.coursePage.courseID,
          enquiryID,
          enquiryContentID,
          this.currentCoursePageId,
          this.selectedPlaceholder.pagePlaceholderID,
          questionKeys[0],
        );
        content.enquiryContent = { ...newAlternatives.enquiryContent };
      }
    }
  };

  createNewCategorization = async (placeholder: IPagePlaceholder) => {
    if (this.coursePage) {
      const newContentProperties: INewContentProperties = {
        paddingLeft: '30px',
        paddingRight: '30px',
        paddingTop: '30px',
        paddingBottom: '30px',
      };
      const categorizationData = {
        courseID: this.coursePage.courseID,
        coursePageID: this.coursePage.coursePageID,
        pagePlaceholderID: placeholder.pagePlaceholderID,
        enquiryID: this.coursePage.enquiryID,
        index: 0,
        innerHTML: '<span style="font-size: 20pt;"><strong>Categorization - Drag Cards to Drop Zones</strong></span>',
        columns: [
          {
            index: 1,
            innerHTML: '<span style="font-size: 16pt;"><strong>Drop Zone 1</strong></span>',
            items: [],
          },
          {
            index: 2,
            innerHTML: '<span style="font-size: 16pt;"><strong>Drop Zone 2</strong></span>',
            items: [],
          },
          {
            index: 3,
            innerHTML: '<span style="font-size: 16pt;"><strong>Alternatives</strong></span>',
            items: [
              {
                index: 1,
                innerHTML: '<span style="font-size: 14pt;">Card 1</span>',
              },
              {
                index: 2,
                innerHTML: '<span style="font-size: 14pt;">Card 2</span>',
              },
            ],
          },
        ],
        properties: newContentProperties,
      };

      const newContent = await agent.Page.NewDragDrop(JSON.stringify(categorizationData));
      runInAction(() => {
        placeholder.colorAndDesign.paddingRight = placeholder.colorAndDesign.paddingTop = placeholder.colorAndDesign.paddingBottom = placeholder.colorAndDesign.paddingLeft = '30px';
        placeholder.contents.push(newContent);
      });

      this.saveContent(newContent);
    }
  };

  createNewCategorizationColumn = async (placeholder: IPagePlaceholder, enquiryContentType: EEnquiryContentType) => {
    if (this.coursePage) {
      await agent.Page.NewQuestion(this.coursePage.courseID, this.coursePage.coursePageID, placeholder.pagePlaceholderID, enquiryContentType);
      this.getPage(placeholder.courseID, placeholder.coursePageID);
    }
  };

  createNewCategorizationCard = async (placeholder: IPagePlaceholder, content: IContent) => {
    if (this.coursePage && this.selectedPlaceholder && this.enquiryProperties) {
      const questionKeys = Object.keys(content.enquiryContent.question);
      const courseID = this.coursePage.courseID;
      if (questionKeys.length > 0) {
        runInAction(async () => {
          const enquiryID = content.enquiryContent.question[questionKeys[0]].enquiryID;
          const response = await agent.Page.NewAnswer(courseID, enquiryID, content.contentID, this.currentCoursePageId, placeholder.pagePlaceholderID, questionKeys[0]);
          const newColumns = response.enquiryContent.question.categorization.alternatives;
          const oldColumns = content.enquiryContent.question.categorization;
          oldColumns.alternatives = newColumns[newColumns.length - 1].enquiryContent.question.categorization.alternatives;
        });
        //const newCards = columns[columns.length - 1].enquiryContent.question.categorization.alternatives;
        //const oldCards = content.enquiryContent.question.categorization.alternatives;
        //const newCard = newCards.find((itemB) => !oldCards.some((itemA) => itemA.contentID === itemB.contentID));
        //if (newCard) oldCards.push(newCard);
        //placeholder.contents[0] = test;
        //this.getPage(placeholder.courseID, placeholder.coursePageID);
      }
    }
  };

  createEmbedContent = async (placeholder: IPagePlaceholder, embedURL: string) => {
    if (!this.coursePage) return;
    const newContent = await this.newContent(placeholder.pagePlaceholderID, EContentType.EmbedContent);
    if (newContent === undefined) return;
    newContent.innerHTML = embedURL;
    placeholder.contents.push(newContent);
    this.saveContent(newContent);
  };

  setExpanded = (aCourseID: number, aCoursePageID: string, aIsExpanded: boolean) => {
    //Converting from string to number because TreeItemID is stupid and can be both.
    let coursePageID = 0;
    try {
      coursePageID = parseInt(aCoursePageID);
    } catch {
      console.log('unable to parse aCoursePageID: ' + aCoursePageID);
      return;
    }

    agent.Page.SetExpanded(aCourseID, coursePageID, aIsExpanded);
  };

  deselectCourse = () => {
    runInAction(() => {
      this.coursePage = undefined;
    });
  };

  setCoursePage = async (course: TPageViewModel) => {
    course.isLayout = true;
    this.coursePage = undefined;
    this.coursePage = course;
  };

  setLoading = (state: boolean) => {
    this.loading = state;
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  scaleFactor = 1;
  setScaleFactor = (n: number) => {
    this.scaleFactor = n;
  };

  changePageLayout = async (layoutNo: number) => {
    if (this.coursePage === undefined) {
      return;
    }
    await agent.Page.changePageLayout(this.coursePage.courseID, this.coursePage?.coursePageID, layoutNo);
    await this.getPage(this.coursePage.courseID, this.coursePage.coursePageID);
    timeout(500).then(() => this.savePageHTML());
  };

  setEditorRef = (ref: HTMLDivElement) => {
    this.editorRef = ref;
  };

  setTinyMCeEditorFocus = async () => {
    this.tinyMCETrigger = !this.tinyMCETrigger;
  };

  getPlaceholderContetProperties = async (aContent: IContent) => {
    return await agent.Page.GetPagePlaceholderContentProperties(aContent.courseID, aContent.coursePageID, aContent.pagePlaceholderID, aContent.contentID);
  };

  //Dette er for å få tabulering av oppgaver og oppgave alternativer til å fungere uten å få feilmelding fordi vi spør server for fort.
  isTabEventAllowed = true;

  getTabEventAllowed = () => {
    return this.isTabEventAllowed;
  };

  setTabEventNotAllowed = () => {
    this.isTabEventAllowed = false;
    this.setTabEventAllowed();
  };

  setTabEventAllowed = () => {
    timeout(500).then(() => (this.isTabEventAllowed = true));
  };
}
