import React, { useEffect, useState } from 'react';
import useTheme from '@mui/system/useTheme';
import { convertToHex, hsvToRGB, convertToRGB, getColorName, rgbColor } from '../../utils/HexToRGBA';
import { clamp, mapRange } from '../../utils/ClampNumber';
import Select from '@mui/material/Select/Select';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import ColorizeIcon from '@mui/icons-material/Colorize';
import IconButton from '@mui/material/IconButton/IconButton';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import { createUseStyles } from 'react-jss';
import TextField from '@mui/material/TextField/TextField';
import InputAdornment from '@mui/material/InputAdornment/InputAdornment';
import { ColorObject } from './ColorObject';
import tinycolor from 'tinycolor2';
import { hslToRgb } from '@mui/system';
import { createPortal } from 'react-dom';
import Wrapper from './Wrapper';

interface IPosCords {
  x: number;
  y: number;
}

export interface IColorSwatches {
  colorList: IColor[];
}
interface IColor {
  name: string;
  colorCode: string;
}

export interface IColorPickerProps {
  defaultColor: string;
  onColorChange: (s: string) => void;
  onCommitColor?: (s: string) => void;
  swatches?: IColorSwatches;
  showSwatches?: boolean;
  isCompact?: boolean;
}

const tempSwatches: IColorSwatches = {
  colorList: [
    {
      colorCode: '#CEC5ED',
      name: 'Creator Lavender',
    },
    {
      colorCode: '#F5b800',
      name: 'Creator Amber',
    },
    {
      colorCode: '#Ff9b58',
      name: 'Creator Peach',
    },
    {
      colorCode: '#Ff6600',
      name: 'Creator Orange',
    },
    {
      colorCode: '#BA2E3E',
      name: 'Creator Crimson',
    },
    {
      colorCode: '#4B3CBE',
      name: 'Creator Violet',
    },
    {
      colorCode: '#8ECCFF',
      name: 'Creator Sky',
    },
    {
      colorCode: '#0099FF',
      name: 'Creator Sea',
    },
    {
      colorCode: '#B3EC90',
      name: 'Creator Mint',
    },
    {
      colorCode: '#6EBE3C',
      name: 'Creator Jasmine',
    },
  ],
};

//TODO: reformat the colors of this component to put the colors in an object instead of their own hooks.

export default function ColorPicker({ defaultColor, onColorChange, onCommitColor, swatches, showSwatches = true, isCompact = false }: IColorPickerProps) {
  swatches = tempSwatches;

  const theme = useTheme();
  const alphaRef = React.useRef<HTMLDivElement>(null);
  const saturationRef = React.useRef<HTMLDivElement>(null);
  const hueRef = React.useRef<HTMLDivElement>(null);
  const height = 180;
  const saturationWidth = 150;
  const saturationHeight = height;

  const colorButtonRef = React.useRef<HTMLDivElement>(null);

  const [colorModel, setColorModel] = useState<'Hex' | 'Hex8' | 'RGB' | 'HSL' | 'HSV'>('Hex');
  const [alphaPickerPos, setAlphaPickerPos] = useState<IPosCords>({ x: 0, y: 0 });
  const [huePicker, setHuePickerPos] = useState<IPosCords>({ x: 0, y: 0 });
  const [sPicker, setsPickerPos] = useState<IPosCords>({ x: 0, y: 0 });
  const [hueColor, setHueColor] = useState<tinycolor.Instance>(tinycolor(defaultColor));
  const [alphaValue, setAlphaValue] = useState(tinycolor(defaultColor).getAlpha() * 100);
  const [mainColor, setMainColor] = useState<tinycolor.Instance>(tinycolor(defaultColor));
  const [colors, setColors] = useState<ColorObject>(new ColorObject(defaultColor));

  const [colorTextInput, setColorTextInput] = useState(defaultColor);
  const [lastChange, setLastChange] = useState(0); //To make sure the onColorChange function doesn't get called too often.

  const [isSatMouseDown, setSatMouseDown] = useState(false);
  const [isHueMouseDown, setHueMouseDown] = useState(false);
  const [isAlphaMouseDown, setAlphaMouseDown] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const initColorPicker = () => {
    const hsv = mainColor.toHsv();
    const sPPos: IPosCords = { x: mapRange(hsv.s, 0, 1, 0, saturationWidth), y: mapRange(1 - hsv.v, 0, 1, 0, saturationHeight) };
    setsPickerPos(sPPos);
    setHuePickerPos({ x: 0, y: mapRange(360 - hsv.h, 0, 360, 0, height) });
    const rgb = hsvToRGB(hsv.h, 1, 1);

    setHueColor(tinycolor(`rgb(${rgb[0]},${rgb[1]},${rgb[2]})`));
    const alphaPos = mapRange(100 - mainColor.getAlpha() * 100, 0, 100, 0, height);
    setAlphaPickerPos({ x: 0, y: alphaPos });
  };

  const handleSaturationMouseDown = () => {
    setSatMouseDown(true);
  };

  const handleHueMouseDown = () => {
    setHueMouseDown(true);
  };

  const handleAlphaMouseDown = () => {
    setAlphaMouseDown(true);
  };

  const handleDocumentMouseUp = () => {
    setSatMouseDown(false);
    setHueMouseDown(false);
    setAlphaMouseDown(false);
  };

  const handleMouseMove = (event: any) => {
    if (isSatMouseDown) {
      handleSaturationMouseMove(event);
    } else if (isHueMouseDown) {
      handleHueMouseMove(event);
    } else if (isAlphaMouseDown) {
      handleAlphaMouseMove(event);
    }
  };

  const handleSaturationMouseMove = (event: any) => {
    const rect = saturationRef.current?.getBoundingClientRect();
    if (!rect) return;

    let x = event.clientX - rect.x;
    x = x > 0 ? x : 0;
    let y = event.clientY - rect.y;
    y = y > 0 ? y : 0;

    y = clamp(y, 0, saturationHeight);
    x = clamp(x, 0, saturationWidth);

    setsPickerPos({ x: x, y: y });
    const rgbString = getFinalColorAtPos(x, y);
    const expColor = createExportColor(rgbString, alphaValue);
    handleColorChange(expColor);
  };

  const handleHueMouseMove = (event: any) => {
    const rect = hueRef.current?.getBoundingClientRect();
    if (!rect) return;

    const x = event.clientX - rect.x;
    const y = event.clientY - rect.y;

    const newY = clamp(y, 0, height);
    setHuePickerPos({ x: 0, y: newY });
    const hue = getHueAtPos(x, newY);

    setHueColor(tinycolor(hslToRgb(`hsl(${hue}, 100%, 50%)`)));

    const rgbString = getFinalColorAtPos(sPicker.x, sPicker.y, hue);
    const expColor = createExportColor(rgbString, alphaValue);
    handleColorChange(expColor);
  };

  const handleAlphaMouseMove = (event: any) => {
    const rect = alphaRef.current?.getBoundingClientRect();
    if (!rect) return;

    const y = event.clientY - rect.y;
    calculateAlphaValue(y);
  };

  const getFinalColorAtPos = (x: number, y: number, hue?: number) => {
    //const hueV = hue ? hue : mainColor.toHsv().h;
    const hueV = hue ? hue : tinycolor(hueColor).toHsv().h;

    const saturation = mapRange(x, 0, saturationWidth, 0, 100) / 100;
    const value = 1 - mapRange(y, 0, saturationHeight, 0, 100) / 100;

    const rgb = hsvToRGB(hueV, saturation, value);

    const rgbString = `rgb(${rgb[0]},${rgb[1]},${rgb[2]})`;
    return rgbString;
  };

  const setAlpha = (aAlphaValue: number) => {
    const alphaPos = mapRange(100 - aAlphaValue, 0, 100, 0, height);
    setAlphaPickerPos({ x: 0, y: alphaPos });
    setAlphaValue(Math.round(aAlphaValue));
  };

  const alphaValueTextInput = (e: any) => {
    let value = parseInt(e.target.value.trim()) || 0;

    const min = 0,
      max = 100;

    if (value > max) {
      value = max;
    } else if (value < min) {
      value = min;
    }
    setAlpha(value);
    const rgbString = getFinalColorAtPos(sPicker.x, sPicker.y);
    const expColor = createExportColor(rgbString, value);
    handleColorChange(expColor);
  };

  const calculateAlphaValue = (y: number) => {
    const yPos = clamp(y, 0, height);
    const alpha = Math.round(100 - mapRange(yPos, 0, height, 0, 100));

    setAlphaPickerPos({ x: 0, y: yPos });
    setAlphaValue(alpha);
    const rgbString = getFinalColorAtPos(sPicker.x, sPicker.y);
    const expColor = createExportColor(rgbString, alpha);
    handleColorChange(expColor);
  };

  const getHueAtPos = (x: number, y: number) => {
    const hue = 360 - mapRange(y, 0, 180, 0, 360);
    return hue;
  };

  const handleInput = (aColor: tinycolor.Instance) => {
    const HSV = aColor.toHsv();
    if (HSV === null) return;

    const sPPos: IPosCords = { x: mapRange(HSV.s, 0, 1, 0, saturationWidth), y: mapRange(1 - HSV.v, 0, 1, 0, saturationHeight) };
    setsPickerPos(sPPos);

    setHuePickerPos({ x: 0, y: mapRange(360 - HSV.h, 0, 360, 0, height) });

    const rgb = hsvToRGB(HSV.h, 1, 1);
    setHueColor(tinycolor(`rgb(${rgb[0]},${rgb[1]},${rgb[2]})`));

    const tmpColor = tinycolor(aColor);
    setMainColor(tmpColor);

    commitColor(tmpColor.toRgbString());
    onColorChange(tmpColor.toHexString());
  };

  const onColorTextInputChange = (aColorValue: string) => {
    const tmpColor = tinycolor(aColorValue);
    setColorTextInput(aColorValue);

    if (aColorValue.length < 6) return;
    if (!tmpColor.isValid()) {
      return;
    }
    handleInput(tmpColor);
  };

  const setTextField = (color: ColorObject) => {
    switch (colorModel) {
      case 'Hex8':
        setColorTextInput(color.hex8);
        break;
      case 'RGB':
        setColorTextInput(color.rgbString);
        break;
      case 'HSL':
        setColorTextInput(color.hslString);
        break;
      case 'HSV':
        setColorTextInput(color.hsvString);
        break;
      default:
        setColorTextInput(color.hex);
        break;
    }
  };

  const handleColorModelChange = (e: any) => {
    setColorModel(e.target.value);
  };

  const handleColorChange = (aColor: string) => {
    const color = tinycolor(aColor);
    setMainColor(color);
    if (Date.now() - lastChange > 100) {
      setLastChange(Date.now());
      onColorChange(color.toHexString());
      commitColor(aColor);
    }
  };

  const commitColor = (aColor: string) => {
    if (aColor === defaultColor) return; //no need to commit a color that is the same as the color saved.
    if (onCommitColor !== undefined) {
      if (aColor !== null) {
        onCommitColor(aColor);
      }
    }
  };

  const createExportColor = (aColor: string, aAlphaValue: number) => {
    const rgb = convertToRGB(aColor);
    let newColor = '';
    if (aAlphaValue < 100) {
      newColor = `rgba(${rgb.r},${rgb.g},${rgb.b},${aAlphaValue / 100})`;
    } else {
      newColor = `rgb(${rgb.r},${rgb.g},${rgb.b})`;
    }
    return newColor;
  };
  /* 
  interface IWrapperProps {
    wrapper: boolean;
    isWrapperOpen: boolean;
    children: React.ReactNode;
  }
  const Wrapper = ({ wrapper, isWrapperOpen, children }: IWrapperProps) => {
    const useStyles = createUseStyles({
      pickerOpen: {
        height: '200px',
        overflow: 'hidden',
      },
      pickerClosed: {
        height: '0px',
        overflow: 'hidden',
      },
    });
    const classes = useStyles();

    if (wrapper) {
      return (
        <>
          <div className={isWrapperOpen ? classes.pickerOpen : classes.pickerClosed}>{children}</div>
        </>
      );
    }
    return <>{children}</>;
  }; */

  const EyeDropper = () => {
    const openEyeDropper = () => {
      if (window.EyeDropper) {
        const ed = new window.EyeDropper();
        ed.open().then((color: ColorSelectionResult) => onColorTextInputChange(color.sRGBHex));
      }
    };

    if (window.EyeDropper === undefined) {
      return (
        <>
          <Tooltip title='This feature requires Chrome' placement='top' arrow={true}>
            <div>
              <IconButton aria-label='eyedropper' disabled>
                <ColorizeIcon />
              </IconButton>
            </div>
          </Tooltip>
        </>
      );
    }

    return (
      <>
        <IconButton aria-label='eyedropper' onClick={openEyeDropper}>
          <ColorizeIcon />
        </IconButton>
      </>
    );
  };
  interface IStylesProps {
    color: rgbColor;
  }
  const useStyles = createUseStyles({
    pickerCircle: ({ color }: IStylesProps) => ({
      '--circle-box-shadow1': `0 0 0 0 rgba(${color.r}, ${color.g}, ${color.b}, 0.7)`,
      '--circle-box-shadow2': `0 0 0 10px rgba(${color.r}, ${color.g}, ${color.b}, 0)`,
      '--circle-box-shadow3': `0 0 0 0 rgba(${color.r}, ${color.g}, ${color.b}, 0)`,
      '--circle-box-shadow4': `0 0 0 0 rgba(${color.r}, ${color.g}, ${color.b}, 1)`,
      boxShadow: `0 0 0 0 rgba(${color.r}, ${color.g}, ${color.b}, 1)`,
      transition: 'box-shadow 0.3s ease-in-out',
      '&:hover': {
        animation: '$pulseCircle 2s forwards',
      },
    }),
    '@keyframes pulseCircle': {
      '0%': {
        transform: 'translate(0%, -50%) scale(1)',
        boxShadow: 'var(--circle-box-shadow1)',
      },
      '70%': {
        transform: 'translate(0%, -50%) scale(1)',
        boxShadow: 'var(--circle-box-shadow2)',
      },
      '100%': {
        transform: 'translate(0%, -50%) scale(1)',
        boxShadow: 'var(--circle-box-shadow3)',
      },
    },
  });
  const classes = useStyles({ color: colors.rgb });

  useEffect(() => {
    const newColor = new ColorObject(mainColor.toRgbString());
    setColors(newColor);
    setTextField(newColor);
  }, [mainColor]);

  useEffect(() => {
    setTextField(colors);
  }, [colorModel]);

  useEffect(() => {
    initColorPicker();

    document.addEventListener('mouseup', handleDocumentMouseUp, { passive: false });
    return () => {
      document.removeEventListener('mouseup', handleDocumentMouseUp);
      document.getElementById('color-picker-wrapper')?.remove();
    };
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Select id='color-models' variant='standard' value={colorModel} onChange={handleColorModelChange} sx={{ width: 70 }}>
          <MenuItem value='Hex'>Hex</MenuItem>
          <MenuItem value='Hex8'>Hex8</MenuItem>
          <MenuItem value='RGB'>RGB</MenuItem>
          <MenuItem value='HSL'>HSL</MenuItem>
          <MenuItem value='HSV'>HSV</MenuItem>
        </Select>
        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
          <TextField
            value={alphaValue}
            aria-label='alpha value'
            variant='standard'
            InputProps={{ inputProps: { min: 1, max: 100, style: { textAlign: 'center' } }, endAdornment: <InputAdornment position='end'>%</InputAdornment> }}
            sx={{ input: { width: 30 }, marginRight: '16px' }}
            onChange={alphaValueTextInput}
            autoComplete={'off'}
            size={'small'}
          />
          <EyeDropper />
        </div>
      </div>
      <div style={{ display: 'flex', position: 'relative', width: '100%' }}>
        <input
          type='text'
          style={{
            width: '100%',
            padding: theme.spacing(2),
            borderRadius: theme.shape.borderRadius * 2,
            border: 0,
            outlineColor: 'transparent',
            backgroundColor: theme.palette.bgFour.light,
            color: theme.palette.bgFour.contrastText,
            /* outline: `2px solid ${colors.rgbString}`, */
            textTransform: 'uppercase',
          }}
          value={colorTextInput}
          onChange={(e) => onColorTextInputChange(e.target.value)}
        />
        <div
          className={isOpen ? classes.pickerCircle : ''}
          style={{
            height: '65%',
            aspectRatio: '1/1',
            backgroundColor: colors.rgbString,
            position: 'absolute',
            top: '50%',
            right: 10,
            borderRadius: 99999,
            cursor: isCompact ? 'pointer' : 'auto',
            transform: 'translate(0%, -50%) scale(1)',
          }}
          onClick={() => setIsOpen(!isOpen)}
          ref={colorButtonRef}
        ></div>
      </div>
      {colorButtonRef.current && (
        <Wrapper wrapper={isCompact} isWrapperOpen={isOpen} onClose={() => setIsOpen(!isOpen)} parentElement={colorButtonRef.current}>
          <div style={{ display: 'flex', gap: 8, justifyContent: 'space-between', padding: 8 }} onMouseMove={handleMouseMove}>
            <div
              ref={saturationRef}
              style={{
                width: saturationWidth,
                height: saturationHeight,
                position: 'relative',
                backgroundColor: hueColor.toRgbString(),
                backgroundImage: 'url("https://colorpicker.me/image/sv-map.png")',
                backgroundSize: 'cover',
                userSelect: 'none',
                cursor: 'pointer',
              }}
              onMouseDown={handleSaturationMouseDown}
              onMouseUp={handleSaturationMouseMove}
            >
              <div
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: 99999,
                  backgroundColor: colors.rgbString,
                  outline: '2px solid white',
                  outlineOffset: -1,
                  position: 'absolute',
                  top: sPicker.y - 5,
                  left: sPicker.x - 5,
                  pointerEvents: 'none',
                }}
              ></div>
            </div>
            <div style={{ position: 'relative' }}>
              <div
                ref={hueRef}
                style={{ width: 20, height: 180, backgroundImage: 'url(https://colorpicker.me/image/hue-map.png)', backgroundSize: 'contain', cursor: 'pointer' }}
                onMouseDown={handleHueMouseDown}
                onMouseUp={handleHueMouseMove}
              ></div>
              <div
                style={{
                  position: 'absolute',
                  top: huePicker.y,
                  left: huePicker.x,
                  width: '100%',
                  height: 4,
                  background: hueColor.toRgbString(),
                  outline: '2px solid white',
                  borderRadius: 2,
                  pointerEvents: 'none',
                }}
              ></div>
            </div>
            <div
              ref={alphaRef}
              style={{
                position: 'relative',
                width: 20,
                height: 180,
                backgroundColor: '#FFF',
                backgroundImage: `linear-gradient(45deg, #ccc 25%, transparent 25%), 
              linear-gradient(135deg, #ccc 25%, transparent 25%),
              linear-gradient(45deg, transparent 75%, #ccc 75%),
              linear-gradient(135deg, transparent 75%, #ccc 75%)`,
                backgroundSize: '20px 20px',
                backgroundPosition: '0 0, 10px 0, 10px -10px, 0px 10px',
                cursor: 'pointer',
              }}
              onMouseDown={handleAlphaMouseDown}
              onMouseUp={handleAlphaMouseMove}
            >
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundImage: 'linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255,1))',
                  backgroundColor: 'transparent',
                }}
              ></div>
              <div
                style={{
                  position: 'absolute',
                  top: alphaPickerPos.y,
                  left: alphaPickerPos.x,
                  width: '100%',
                  height: 4,
                  background: '#909090',
                  outline: '2px solid white',
                  borderRadius: 2,
                  pointerEvents: 'none',
                }}
              ></div>
            </div>
          </div>
        </Wrapper>
      )}
      {showSwatches && swatches.colorList.length > 0 && (
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, 30px)', gridAutoRows: 30, justifyContent: 'space-between', gap: 16, marginTop: theme.spacing(6) }}>
          {swatches.colorList.map((color: IColor, index: number) => (
            <ColorSwatch key={index} swatch={color} currentColor={mainColor.toRgbString()} selectColor={handleInput} />
          ))}
        </div>
      )}
    </div>
  );
}

interface IColorSwatch {
  swatch: IColor;
  currentColor?: string;
  selectColor: (color: tinycolor.Instance) => void;
}

function ColorSwatch({ swatch, currentColor, selectColor }: IColorSwatch) {
  interface IStyleProps {
    swatchColor: string;
    currentColor: string;
  }

  const useStyles = createUseStyles({
    colorBox: {
      height: 30,
      width: 30,
      backgroundColor: swatch.colorCode,
      borderRadius: 99999,
      boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 0px 1px inset',
      cursor: 'pointer',
      display: 'grid',
      placeItems: 'center',
      outlineOffset: 2,
      outline: ({ swatchColor, currentColor }: IStyleProps) => (swatchColor.toLowerCase() === convertToHex(currentColor).toLowerCase() ? `2px solid ${swatchColor}` : 'none'),
    },
    tooltip: {
      textTransform: 'capitalize',
    },
  });
  const classes = useStyles({ swatchColor: swatch.colorCode, currentColor: currentColor ? currentColor : '' });

  return (
    <>
      <Tooltip title={swatch.name.length > 0 ? swatch.name : getColorName(swatch.colorCode)} classes={{ tooltip: classes.tooltip }} placement='top' arrow={true}>
        <div className={classes.colorBox} onClick={() => selectColor(tinycolor(swatch.colorCode))}></div>
      </Tooltip>
    </>
  );
}
