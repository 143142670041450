import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../../services/api/agent';
import { IDIRFilesModel, IDIRModel, IDIRResponseModel, LSResponseModel } from '../../services/models/MediaLibraryModels';
import SnackbarUtils from '../../components/snackbarUtils/SnackbarUtilsConfigurator';
import { createTreeObjectFromPathList, deletePathObject, getFullPath, IPathObject } from '../../utils/TreeList';
import { timeout } from '../../utils/timeout';
import { bytesToSize } from '../../utils/BytesToSize';

export default class MediaLibraryStore {
  mediaFolders: IPathObject | undefined = undefined;
  currentFolderContent: IDIRModel | undefined = undefined;
  currentFolderExists = true;
  selectedFile: IDIRFilesModel | undefined = undefined;
  rootPath = '.\\\\';
  currentFolderPath = '';
  isMediaLibraryOpen = false;
  isMediaLibraryOpenFromEditor = false;

  renameFileTrigger = false;

  libraryLoadingInitial = true;
  libraryLoading = false;

  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  //TODO: mediaFolders blir ikke tømt før nytt blir hentet nå endringer skjer i koden.
  getMediaLibrary = async () => {
    this.mediaFolders = undefined;
    const LSResponse = await agent.Media.getFolders();
    runInAction(() => {
      this.mediaFolders = createTreeObjectFromPathList('', LSResponse.ls);
      this.libraryLoadingInitial = false;
    });
    if (LSResponse.ls.length === 0) {
      await agent.Media.createFolder('New Folder');
      const newResponse = await agent.Media.getFolders();
      this.mediaFolders = createTreeObjectFromPathList('', newResponse.ls);
    }
  };

  getFilesInFolder = async (path: string) => {
    if (path === '') {
      return;
    }
    this.libraryLoading = true;
    if (this.currentFolderPath !== path) {
      this.deselectFile();
    }
    const response: IDIRResponseModel = await agent.Media.getFilesInFolder(path);
    if (!response.dir.exists) {
      SnackbarUtils.error('Folder does not exist');
      this.currentFolderContent = undefined;
      this.libraryLoading = false;
      this.currentFolderExists = false;
    } else {
      for (let i = 0; i < response.dir.files.length; i++) {
        response.dir.files[i].size = bytesToSize(response.dir.files[i].length);
        response.dir.files[i].createdTime = new Date(response.dir.files[i].creationTime);
        response.dir.files[i].modifiedTime = new Date(response.dir.files[i].lastWriteTime);
      }
      response.dir.files.sort((a, b) => a.name.localeCompare(b.name));
      runInAction(() => {
        this.currentFolderContent = response.dir;
        this.currentFolderPath = path;
        this.libraryLoading = false;
        this.currentFolderExists = true;
      });
    }
    return;
  };

  //TODO: Legg inn error håndtering av filer. Sjekk om alle opplastede filer faktisk er lastet opp.
  uploadFiles = async (files: FileList) => {
    if (this.currentFolderPath.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        if (files[i].size < 200000000) {
          formData.append('formFiles', files[i]);
        } else {
          SnackbarUtils.info(files[i].name + 'was not uploaded. The file must be less than 200MB.', undefined, undefined, 10000);
        }
      }

      if (!formData.has('formFiles')) {
        return;
      }
      this.initUploadProgress();
      await agent.Media.uploadFiles(this.currentFolderPath.replace(/\/+$/, ''), formData);
      this.getFilesInFolder(this.currentFolderPath);
      this.finnishUploadProgress('Done!');
    } else {
      SnackbarUtils.warning('current folder path is empty' + this.currentFolderPath);
    }
  };

  uploadProgress = 0;
  uploadProgressVisibility = false;
  uploadProgressText = 'Uploading...';
  initUploadProgress = () => {
    runInAction(() => {
      this.uploadProgress = 0;
      this.uploadProgressVisibility = true;
    });
  };

  setUploadProgress = (progress: number) => {
    this.uploadProgress = progress;
  };

  finnishUploadProgress = async (msg: string) => {
    if (this.uploadProgress <= 0) return;
    runInAction(() => {
      this.uploadProgressText = msg;
    });

    await timeout(3000);

    runInAction(() => {
      this.uploadProgress = 0;
      this.uploadProgressVisibility = false;
      this.uploadProgressText = 'Uploading...';
    });
  };

  uploadFilesToFolder = async (files: FileList, folder: string) => {
    if (folder.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('formFiles', files[i]);
      }

      return await agent.Media.uploadFiles(folder, formData);
    } else {
      SnackbarUtils.warning('folder path is empty' + folder);
    }
  };

  uploadFileToFolder = async (file: File, folder: string) => {
    if (folder.length > 0) {
      const formData = new FormData();
      formData.append('formFiles', file);
      const res = await agent.Media.uploadFile(folder, formData);
      this.setUploadProgress(0);
      return res;
    } else {
      SnackbarUtils.warning('folder path is empty' + folder);
    }
  };

  uploadImage = async (file: File, folder?: string, aAltText?: string): Promise<{ url: string; altText: string }> => {
    const response = await this.uploadFileToFolder(file, folder ? folder : 'Upload');
    if (response !== undefined) {
      const keys = Object.keys(response);

      if (keys.length < 1 || response[keys[0]].status === false) {
        SnackbarUtils.warning('Upload failed. Try again.');
        return { url: '', altText: '' };
      }

      const extension = /.[0-9a-z]+$/.exec(file.name);
      if (extension === null) return { url: '', altText: '' };

      return { url: response[keys[0]].url, altText: aAltText ? aAltText : '' };
    }
    return { url: '', altText: '' };
  };

  renameFileFolder = async (newName: string, file?: IDIRFilesModel, folder?: IPathObject) => {
    let response;
    if (file !== undefined) {
      if (newName != file.name) {
        if (!newName.includes(file.extension)) {
          newName += file.extension;
        }
        response = await agent.Media.rename(this.currentFolderPath + '\\' + file.name, this.currentFolderPath + '\\' + newName, '/F');
        if (response) {
          file.name = newName;
        }
      }
    } else if (folder !== undefined) {
      const indexOf = folder.path.lastIndexOf(folder.name);
      const newPath = folder.path.slice(0, indexOf) + newName;
      if (newPath != folder.path.slice(0, -2)) {
        response = await agent.Media.rename(folder.path, newPath, '/D');
        if (response) {
          folder.path = newPath;
          folder.name = newPath;

          runInAction(() => {
            this.currentFolderPath = newPath;
          });
        }
      }
    } else {
      console.log('something went wrong. file and folder are undefined.');
    }
    if (response) {
      SnackbarUtils.success(response.ren);
    }
  };

  moveFile = async (file: IDIRFilesModel, folder: IPathObject) => {
    if (this.currentFolderPath.length < 1) return;

    const from = this.currentFolderPath + '\\' + file.name;
    const to = folder.path + '\\' + file.name;

    await agent.Media.move(from, to);
    this.getFilesInFolder(this.currentFolderPath);
  };

  tryDeleteFolder = async (folder: IPathObject) => {
    const response: IDIRResponseModel = await agent.Media.getFilesInFolder(folder.path);
    if (response.dir.files.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  //TODO: clear path and folder content
  deleteFolder = async (aFolder: IPathObject) => {
    const response: any = await agent.Media.deleteFolder(aFolder.path);
    if (response.del !== undefined) {
      deletePathObject(aFolder);
      SnackbarUtils.success('deleted folder');
    }
  };

  deleteFile = async (aFileName: string) => {
    const response: any = await agent.Media.deleteFile(this.currentFolderPath + '\\' + aFileName);
    if (response.del !== undefined && this.currentFolderContent !== undefined) {
      const newFolderContent = this.currentFolderContent.files.filter((x) => x.name !== aFileName);
      runInAction(() => {
        if (this.currentFolderContent === undefined) return;
        this.currentFolderContent.files = newFolderContent;
      });
      if (this.selectFile.name == aFileName) {
        this.deselectFile();
      }
      SnackbarUtils.success('deleted file');
    }
  };

  createNewFolder = async (aParent: IPathObject) => {
    const newFolderName = 'New Folder';
    let response: LSResponseModel;
    if (aParent != undefined && aParent.path.length > 0) {
      response = await agent.Media.createFolder(aParent.path + '\\' + newFolderName);
    } else {
      response = await agent.Media.createFolder(newFolderName);
    }

    const row = response.mkd.split('\\');
    const name = row[row.length - 1];

    if (response.mkd) {
      const newFolder: IPathObject = { name: name, node: null, children: [], path: '.', parentPath: '', expanded: false };

      if (aParent.node != undefined) {
        newFolder.node = aParent.node?.addNode(newFolder);
        newFolder.path = getFullPath(newFolder);
      }
      return newFolder;
    }
  };

  selectFile = (file: IDIRFilesModel) => {
    this.selectedFile = file;
  };

  RenameFileTrigger = () => {
    timeout(200).then(() => (this.renameFileTrigger = !this.renameFileTrigger));
  };

  selectFileByWebPath = (webPath: string) => {
    if (this.currentFolderContent === undefined) return;
    for (let i = 0; i < this.currentFolderContent.files.length; i++) {
      const file = this.currentFolderContent.files[i];
      if (file.webPath === webPath) {
        this.selectedFile = file;
      }
    }
  };

  sortFilesByName = (aAsc: boolean) => {
    if (aAsc) {
      this.currentFolderContent?.files.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      this.currentFolderContent?.files.sort((a, b) => b.name.localeCompare(a.name));
    }
  };

  sortFilesBySize = (aAsc: boolean) => {
    if (aAsc) {
      this.currentFolderContent?.files.sort((a, b) => a.length - b.length);
    } else {
      this.currentFolderContent?.files.sort((a, b) => b.length - a.length);
    }
  };

  sortFilesByCreatedDate = (aAsc: boolean) => {
    if (aAsc) {
      this.currentFolderContent?.files.sort((a, b) => a.createdTime.getTime() - b.createdTime.getTime());
    } else {
      this.currentFolderContent?.files.sort((a, b) => b.createdTime.getTime() - a.createdTime.getTime());
    }
  };

  deselectFile = () => {
    this.selectedFile = undefined;
  };

  openMediaLibrary = () => {
    this.getMediaLibrary();
    this.isMediaLibraryOpen = true;
  };

  openMediaLibraryFromTextEditor = () => {
    this.getMediaLibrary();
    this.isMediaLibraryOpen = true;
    this.isMediaLibraryOpenFromEditor = true;
  };

  openMediaLibraryFromEditor = () => {
    this.getMediaLibrary();
    this.isMediaLibraryOpenFromEditor = true;
  };

  closeMediaLibrary = () => {
    this.setLoading(false);
    this.isMediaLibraryOpen = false;
    this.isMediaLibraryOpenFromEditor = false;
    this.deselectFile();
    console.log('closing');
  };

  setLoading = (state: boolean) => {
    this.loading = state;
  };
}
