import axios, { AxiosResponse } from 'axios';

const axiosUnsplash = axios.create();

// Add a request interceptor
axiosUnsplash.interceptors.request.use(
  function (config) {
    config.headers.Authorization = 'Client-ID Bf_NpfDrHi-ZYANTqrqLkrZI8WpZavTEnKJmEM1W5PU';
    return config;
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  },
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
  triggerDownload: <T>(url: string) => axiosUnsplash.get<T>(url).then(responseBody),
};

const img = {
  triggerDownload: (url: string) => requests.triggerDownload<any>(url + 'Client_ID=Bf_NpfDrHi-ZYANTqrqLkrZI8WpZavTEnKJmEM1W5PU'),
};

const unsplashAgent = {
  img,
};

export default unsplashAgent;
