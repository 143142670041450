import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../services/api/agent';
import { store } from './store';
import { ECreatorUserRole } from './userStore';

export interface IRoles {
  id: string;
  name: string;
  description: string;
  users: IUser[];
}

export interface IUser {
  user_id: string;
  email: string;
  picture: string;
  name: string;
}

export interface IOwnerIdModel {
  ownerID: number;
  nameIdentifier: string;
  mediaFolder: string;
  testOwnerID: string;
  ownerCourses: string;
  propertyValueMaps: string;
  mediaOwners: string;
  charedMediaOwners: string;
  testOwner: string;
  cheatingOwners: string;
}

export interface ITestOwner {
  ownerID: number;
  nameIdentifier: string;
  testOwnerID: number;
  user: ITestOwnerUser;
}

export interface ITestOwnerUser {
  userName: string;
  nickName: string;
  firstName: string;
  fullName: string;
  lastName: string;
  picture: string;
  userId: string;
}

//TODO: Endre sånn at testOwner bruker samme user klasse som i userStore...
export default class AdminStore {
  roleUsers: IRoles[] = [];
  testOwner: ITestOwner | undefined = undefined; //The current user
  simulatedUserName = '';

  constructor() {
    makeAutoObservable(this);
  }

  getAllUserRoles = async () => {
    const getAllUserRoles: any = await agent.User.GetAllUserRoles();

    this.roleUsers = getAllUserRoles.roles;
  };

  getTestOwner = async () => {
    const isTestOwner = localStorage.getItem('TestOwner') === 'true';
    if (isTestOwner) {
      const testOwner: ITestOwner = await agent.Admin.GetTestOwner();
      this.testOwner = testOwner;

      if (store.userStore.role !== ECreatorUserRole.superAdmin && testOwner.nameIdentifier !== testOwner.user?.userId) {
        await this.resetTestOwner();
        return;
      }

      //Fiks denne til å ikke bruke fullName. Det er ikke alle som har den
      runInAction(() => {
        if (testOwner.user !== null && testOwner.ownerID !== testOwner.testOwnerID) {
          this.simulatedUserName = testOwner.user.fullName;
        }
      });
    }
  };

  setTestOwner = async (aUserID: string) => {
    const user = await agent.Admin.GetOwnerID(aUserID);
    await agent.Admin.SetTestOwner('myself', user[0].ownerID);

    localStorage.setItem('TestOwner', 'true');

    this.getTestOwner();
  };

  resetTestOwner = async () => {
    if (this.testOwner !== undefined) {
      await agent.Admin.SetTestOwner('myself', this.testOwner.ownerID);
      store.ownerStore.loadOwnerViewModel();
      this.getTestOwner();
      this.simulatedUserName = '';
      localStorage.setItem('TestOwner', 'false');
    }
  };
}
