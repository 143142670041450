import React, { useEffect, useState } from 'react';
import { useTheme, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography/Typography';
import Divider from '@mui/material/Divider/Divider';
import TextField, { TextFieldProps } from '@mui/material/TextField/TextField';
import Select from '@mui/material/Select/Select';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import styled from '@mui/system/styled';
import Button from '@mui/material/Button/Button';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { BiggerTooltip } from '../../../components/tooltip/BiggerTooltip';
import { useStore } from '../../../store/store';
import { observer } from 'mobx-react';
import { bytesToSize } from '../../../utils/BytesToSize';
import moment from 'moment';
import { ICourseExportPackages } from '../../../services/models/CourseViewModels';
import { TransitionGroup } from 'react-transition-group';
import Collapse from '@mui/material/Collapse/Collapse';
import { createUseStyles } from 'react-jss';
import { DynamicImageField } from '../../../components/properties/Properties';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import { eExportType } from '../../../store/courseStore';
import ViewWrapper from './ViewWrapper';

interface IExportCourseViewProps {
  courseID: number;
}

export default observer(function ExportCourseView({ courseID }: IExportCourseViewProps) {
  const { courseStore } = useStore();
  const { generateExport, getExportPackages, getExportPackageFile, purgeExportPackage, courseProperties } = courseStore;

  const [transitionDuration, setTransitionDuration] = useState(0);
  const [courseExportList, setCourseExportList] = useState<ICourseExportPackages[]>([]);
  const [exportType, setExportType] = useState(eExportType.scorm12);
  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.down('xl'));
  const [language, setLanguage] = useState('en');

  const getExportList = async () => {
    setTransitionDuration(300);
    generateExport(exportType, language).then((res) => {
      if (res !== undefined) {
        setCourseExportList(res);
      }
    });
  };

  const purgeExport = async (aCourseID: number, aExportID: number) => {
    setTransitionDuration(300);
    purgeExportPackage(aCourseID, aExportID).then(() => {
      setCourseExportList((list) => list.filter((x) => x.courseExportID !== aExportID));
    });
  };

  const useStyles = createUseStyles((theme: Theme) => ({
    exportRow: {
      '&:nth-child(n+1)': {
        borderBottom: '1px solid #515151',
      },
      '&:last-child': { border: 0 },
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.bgOne.main,
      },
    },
    setting: {
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
  }));

  const classes = useStyles({ theme });

  useEffect(() => {
    getExportPackages(courseID).then((res) => {
      setCourseExportList(res);
    });
  }, []);

  const StyledTextField = styled((props: TextFieldProps) => <TextField {...props} />)(({ theme }) => ({
    '& .MuiInputBase-root': {
      border: '0px solid red',
      backgroundColor: theme.palette.bgOne.main,
      borderRadius: theme.shape.borderRadius,
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&:focus fieldset': {
        borderColor: 'transparent',
      },
      '&:active fieldset': {
        borderColor: 'transparent',
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
    },
  }));

  if (!courseProperties) {
    return <></>;
  }

  return (
    <>
      <ViewWrapper title={'Export'}>
        <div style={{ display: 'flex', gap: theme.spacing(3), flex: '1 1 auto', flexWrap: 'wrap', overflow: breakpoint ? 'auto' : 'hidden' }}>
          <div style={{ background: theme.palette.bgTwo.main, flexGrow: 1, maxHeight: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <Typography variant='body1' sx={{ fontWeight: 'bold', margin: theme.spacing(2) }}>
              Export Settings
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
              <div className={classes.setting} style={{ flexDirection: 'row' }}>
                <Typography variant='body1'>Export Type</Typography>
                <Select variant={'standard'} size={'small'} value={exportType} onChange={(e) => setExportType(e.target.value as eExportType)} sx={{ minWidth: 100, maxWidth: 160 }}>
                  <MenuItem value={eExportType.scorm12}>SCORM 1.2</MenuItem>
                  <MenuItem value={eExportType.html}>HTML</MenuItem>
                  {/*                 <SoonWrapper>
                  <MenuItem value={'scorm-2004'} disabled>
                    SCORM 2004
                  </MenuItem>
                </SoonWrapper>
                <SoonWrapper>
                  <MenuItem value={'xAPI'} disabled>
                    xAPI
                  </MenuItem>
                </SoonWrapper> */}
                </Select>
              </div>
              <Divider />
              <div className={classes.setting} style={{ flexDirection: 'row' }}>
                <Typography variant='body1'>Language</Typography>
                <Select variant={'standard'} size={'small'} value={language} onChange={(e) => setLanguage(e.target.value)} sx={{ minWidth: 100, maxWidth: 160 }}>
                  <MenuItem value={'en'}>English</MenuItem>
                  <MenuItem value={'no'}>Norwegian</MenuItem>
                  <MenuItem value={'se'}>Swedish</MenuItem>
                  <MenuItem value={'fi'}>Finnish</MenuItem>
                  <MenuItem value={'es'}>Spanish</MenuItem>
                  <MenuItem value={'de'}>German</MenuItem>
                  <MenuItem value={'fr'}>French</MenuItem>
                </Select>
              </div>
              <Divider />
              <div className={classes.setting} style={{ flexDirection: 'row' }}>
                <Typography variant='body1'>Logo</Typography>
                <DynamicImageField property={courseProperties.settings.items.companyLogo} imgSize={{ width: 200, height: 40 }} />
              </div>
              <div style={{ flex: '1 1 auto' }}>{/* Filler div - Bare for å dytte knappen ned. */}</div>
              <Button data-pagetour='1' color='primary' variant='contained' style={{ margin: theme.spacing(2), alignSelf: 'baseline' }} onClick={getExportList}>
                GENERATE EXPORT
              </Button>
            </div>
          </div>
          <div style={{ background: theme.palette.bgTwo.main, flexGrow: 5, maxHeight: breakpoint ? 'unset' : '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <div style={{ padding: theme.spacing(2), display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                Export History
              </Typography>

              <StyledTextField
                label={
                  <div style={{ display: 'flex', alignItems: 'center', gap: 8, color: theme.palette.text.primary }}>
                    <SearchIcon />
                    Search
                  </div>
                }
                variant='outlined'
              />
            </div>

            <div style={{ flex: '1 1 auto', background: theme.palette.bgTwo.main, display: 'flex', flexDirection: 'column', overflow: breakpoint ? 'auto' : 'auto' }}>
              <div>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '2.5fr 1fr 1fr 0.5fr 0.75fr',
                    gap: 16,
                    padding: '24px 16px',
                    position: 'sticky',
                    top: 0,
                    backgroundColor: theme.palette.bgSix.main,
                    borderBottom: '1px solid #515151',
                    zIndex: 999,
                    fontWeight: 'bold',
                  }}
                >
                  <div>File Name</div>
                  <div>Export Type</div>
                  <div>Date Exported</div>
                  <div>File Size</div>
                  <div style={{ justifySelf: 'center' }}>Tools</div>
                </div>
                <div>
                  {courseExportList && (
                    <>
                      <TransitionGroup>
                        {courseExportList.map((row) => (
                          <Collapse key={`${row.courseID}_${row.courseExportID}`} className={classes.exportRow} timeout={transitionDuration}>
                            <div style={{ display: 'grid', gridTemplateColumns: '2.5fr 1fr 1fr 0.5fr 0.75fr', gap: 16, padding: 16, alignItems: 'center' }}>
                              <div>{row.fileName}</div>
                              <div>{row.versionName}</div>
                              <div>{moment(row.date).format('DD.MM.YYYY HH:mm')}</div>
                              <div>{bytesToSize(row.fileSize)}</div>
                              <div style={{ justifySelf: 'center' }}>
                                <BiggerTooltip title='Download' placement='bottom'>
                                  <IconButton onClick={() => getExportPackageFile(row.courseID, row.courseExportID)} color='primary'>
                                    <DownloadIcon />
                                  </IconButton>
                                </BiggerTooltip>
                                <BiggerTooltip title='Delete' placement='bottom'>
                                  <IconButton style={{ color: theme.palette.grey[600] }} onClick={() => purgeExport(row.courseID, row.courseExportID)}>
                                    <DeleteIcon />
                                  </IconButton>
                                </BiggerTooltip>
                              </div>
                            </div>
                          </Collapse>
                        ))}
                      </TransitionGroup>
                    </>
                  )}
                </div>
              </div>

              {courseExportList === undefined ||
                (courseExportList.length <= 0 && (
                  <>
                    <div
                      style={{
                        flex: '1 1 auto',
                        background: theme.palette.bgOne.main,
                        width: '100%',
                        display: 'grid',
                        color: '#707070',
                        alignContent: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                      }}
                    >
                      <span>You haven&apos;t exported anything yet.</span>
                      <span>When you do we will display your export history here.</span>
                      <span style={{ marginTop: 16 }}>(ﾉ&gt;ω&lt;)ﾉ :｡･:*:･ﾟ’★,｡･:*:･ﾟ’☆</span>
                    </div>
                  </>
                ))}
              <div style={{ flex: '1 1 auto', background: theme.palette.bgOne.main }}></div>
            </div>
          </div>
        </div>
      </ViewWrapper>
    </>
  );
});
