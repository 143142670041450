import { makeAutoObservable } from 'mobx';
import { TCourseViewModel } from '../../services/models/CourseViewModels';
import agent from '../../services/api/agent';
import { TPageViewModel } from '../../services/models/PageViewModel';

export default class StoryboardStore {
  currentCourseID = 0;
  course = {};
  content: TPageViewModel[] = [];
  loading = true;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchContent(aCourse: TCourseViewModel) {
    this.content = [];
    this.currentCourseID = aCourse.courseID;
    this.course = aCourse;

    for (const [key, value] of Object.entries(aCourse.pages.items)) {
      const parsedKey = parseInt(key, 10);
      if (parsedKey === 0) continue;
      console.log('parsedKey', parsedKey);
      const page = await agent.Page.getPage(aCourse.courseID, parsedKey);
      console.log('page.coursePageID', page.coursePageID);
      this.content.push(page);
    }

    this.setLoading(false);
  }

  setContent(newContent: any) {
    this.content = newContent;
  }

  setLoading(isLoading: any) {
    this.loading = isLoading;
  }
}
