import Typography from '@mui/material/Typography';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useTheme } from '@mui/material/styles';
import Select from '@mui/material/Select/Select';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import { Divider } from '@mui/material';

interface ILanguageSelectorProps {
  language: string;
  setLanguage: (s: string) => void;
  voice: string;
  setVoice: (s: string) => void;
}

export default function LanguageSelector({ language, setLanguage, voice, setVoice }: ILanguageSelectorProps) {
  const theme = useTheme();

  interface IVoicesByLanguage {
    [key: string]: string[];
  }
  const voicesByLanguage: IVoicesByLanguage = {
    'nb-NO': ['nb-NO-FinnNeural', 'nb-NO-PernilleNeural'],
    'en-GB': ['en-GB-SoniaNeural', 'en-GB-RyanNeural', 'en-GB-LibbyNeural', 'en-GB-AlfieNeural'],
    'en-US': ['en-US-EmmaNeural', 'en-US-AvaNeural', 'en-US-AndrewNeural', 'en-US-BrianNeural'],
    'de-DE': ['de-DE-KatjaNeural', 'de-DE-ConradNeural', 'de-DE-AmalaNeural', 'de-DE-BerndNeural'],
    'es-ES': ['es-ES-ElviraNeural', 'es-ES-AlvaroNeural', 'es-ES-AbrilNeural', 'es-ES-ArnauNeural'],
    'fr-FR': ['fr-FR-DeniseNeural', 'fr-FR-HenriNeural', 'fr-FR-AlainNeural', 'fr-FR-BrigitteNeural'],
    'sv-SE': ['sv-SE-SofieNeural', 'sv-SE-MattiasNeural'],
    'fi-FI': ['fi-FI-SelmaNeural', 'fi-FI-HarriNeural'],
  };

  const useStyles = createUseStyles({
    bold: {
      fontWeight: 600,
    },
    setting: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
  });
  const classes = useStyles();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(3) }}>
      <div>
        <Typography variant='body1' fontWeight={500}>
          Output
        </Typography>
      </div>
      <div className={classes.setting} style={{ flexDirection: 'row' }}>
        <Typography variant='body1'>Language</Typography>
        <Select variant={'standard'} size={'small'} value={language} onChange={(e) => setLanguage(e.target.value)} sx={{ minWidth: 140, maxWidth: 140 }}>
          <MenuItem value={'en-US'}>English (US)</MenuItem>
          <MenuItem value={'en-GB'}>English (UK)</MenuItem>
          <MenuItem value={'nb-NO'}>Norwegian</MenuItem>
          <MenuItem value={'sv-SE'}>Swedish</MenuItem>
          <MenuItem value={'fi-FI'}>Finnish</MenuItem>
          <MenuItem value={'es-ES'}>Spanish</MenuItem>
          <MenuItem value={'de-DE'}>German</MenuItem>
          <MenuItem value={'fr-FR'}>French</MenuItem>
        </Select>
      </div>
      <div className={classes.setting} style={{ flexDirection: 'row' }}>
        <Typography variant='body1'>Voice</Typography>
        <Select variant={'standard'} size={'small'} value={voice} onChange={(e) => setVoice(e.target.value)} sx={{ minWidth: 140, maxWidth: 140 }}>
          {voicesByLanguage[language]?.map((voiceOption) => (
            <MenuItem key={voiceOption} value={voiceOption}>
              {voiceOption.split('-')[2].replace('Neural', '')}
            </MenuItem>
          ))}
        </Select>
      </div>
      <Divider />
      <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1), color: theme.palette.gray.main }}>
        <Typography variant='body1' fontWeight={'500'}>
          Tips for Writing Text for AI Voice Generator
        </Typography>
        <Typography variant='body2'>
          <span className={classes.bold}>Test and adjust</span>: Listen to the generated speech and tweak the text for better pronunciation.
        </Typography>
        <Typography variant='body2'>
          <span className={classes.bold}>Homophones</span>: Adjust words that sound the same but are spelled differently (e.g., &quot;Lead&quot; vs. &quot;Lead&quot;).
        </Typography>
        <Typography variant='body2'>
          <span className={classes.bold}>Avoid abbreviations</span>: Write full words (e.g., &quot;Dr.&quot; becomes &quot;Doctor&quot;).
        </Typography>
        <Typography variant='body2'>
          <span className={classes.bold}>Periods over commas</span>: Use periods for clearer pauses (e.g., &quot;He walked home. Tired and hungry.&quot;).
        </Typography>
        <Typography variant='body2'>
          <span className={classes.bold}>Simplify words</span>: Break up complex words phonetically if mispronounced (e.g., &quot;si-kol-o-gy&quot;).
        </Typography>
      </div>
    </div>
  );
}
