import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography/Typography';
import { observer } from 'mobx-react';
import React from 'react';
import PageSoundProperty from '../../styleProperties/PageSoundProperty';
import { useStore } from '../../../store/store';
import { DynamicImageField, DynamicTextField } from '../../../components/properties/Properties';
import { runInAction } from 'mobx';
import PageCompletionRequirements from '../../../components/properties/requiermentProperties/PageCompletionRequirements';

export interface IBackgroundStyle {
  backgroundImage?: string;
  backgroundSize?: EBackgroundSize;
  backgroundPosition?: string;
  backgroundColor?: string;
}
export enum EBackgroundSize {
  contain = 'contain',
  cover = 'cover',
}

export default observer(function DashboardPageProperties() {
  const theme = useTheme();
  const { courseStore } = useStore();
  const { pageProperties, pagePropertiesPageID, courseViewModel } = courseStore;

  const handleOnTitleChange = (s: string) => {
    if (courseViewModel) {
      runInAction(() => {
        courseViewModel.pages.items[pagePropertiesPageID].data.title.value = s;
      });
    }
  };

  if (pageProperties !== undefined) {
    return (
      <div
        style={{ display: 'flex', flexDirection: 'column', width: '100%', borderRadius: 12, padding: 16, gap: 16, background: theme.palette.bgFive.main, flex: '1 1 auto', boxSizing: 'border-box' }}
      >
        <Typography sx={{ fontWeight: 'bold' }}>Page Properties</Typography>
        <DynamicImageField property={pageProperties.descriptions.items.avatar} />
        <DynamicTextField label textVariant={'body1'} property={pageProperties.descriptions.items.title} style={{ width: '100%' }} onBlur={handleOnTitleChange} />
        <DynamicTextField
          label
          textVariant={'body1'}
          multiline
          fullWidth
          rows={5}
          property={pageProperties.descriptions.items.note}
          placeholder={'Page Note'}
          style={{ width: '100%', marginBottom: 16 }}
        />
        <PageSoundProperty audio={pageProperties.sound} accordion={false} />

        <PageCompletionRequirements completionRequirements={pageProperties.requirements.items.completionRequirements} />
      </div>
    );
  } else {
    return <></>;
  }
});
