import ViewListIcon from '@mui/icons-material/ViewList';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import React, { useEffect, useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import SideViewProperties from '../../../features/courseOverview/drawer/DrawerProperties';
import { TCourseViewModel } from '../../../services/models/CourseViewModels';
import { useStore } from '../../../store/store';
import CoursePageList, { TreeItem } from '../CoursePages/CoursePageList';
import { ICourseProperties } from '../../../services/models/PropertyViewModel';
import Typography from '@mui/material/Typography/Typography';
import Divider from '@mui/material/Divider/Divider';
import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField/TextField';
import { runInAction } from 'mobx';
import { createUseStyles } from 'react-jss';
import { Theme, useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import SettingsIcon from '@mui/icons-material/Settings';
import { observer } from 'mobx-react';
import DashboardPageProperties from '../../../features/properties/pageProperties/DashboardPageProperties';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import IconButton from '@mui/material/IconButton/IconButton';
import { ICopyPageObject } from '../../../services/models/OwnerViewModel';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import SnackbarUtils from '../../../components/snackbarUtils/SnackbarUtilsConfigurator';
import { checkNumberOfChildren, checkNumberOfParents } from '../../../utils/courseDashboard/coureDashboardUtils';

interface IEditCourseViewProps {
  courseViewModel: TCourseViewModel;
  courseProperties: ICourseProperties;
}

export default observer(function EditCourseView({ courseViewModel, courseProperties }: IEditCourseViewProps) {
  const { courseStore, propertyStore } = useStore();
  const { newEmptyPage, pageProperties, deselectPageProperties, copyPage, loadCourseViewModel } = courseStore;
  const { setProperty } = propertyStore;
  const [pagesCount, setPagesCount] = useState(0);
  const [pageView, setPageView] = React.useState<string | null>(courseViewModel.pageView ? courseViewModel.pageView : 'list');
  const [courseViewHeight, setCourseViewHeight] = useState(window.innerHeight - 300 > 200 ? window.innerHeight - 300 : 200);
  const [courseTitle, setCourseTitle] = useState(courseProperties.descriptions.items.title.option.value);
  const [editTitleMode, setEditTitleMode] = useState(false);

  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCourseTitle(event.target.value);
  };

  const updateTitle = () => {
    if (editTitleMode) {
      setProperty(courseProperties.descriptions.items.title, courseTitle);
      runInAction(() => {
        courseProperties.descriptions.items.title.option.value = courseTitle;
      });
    }
    setEditTitleMode(false);
  };

  const handleOnKeyDown = (e: any) => {
    if (editTitleMode && e.keyCode === 13) {
      updateTitle();
    }
  };

  const handleNewPageButton = () => {
    newEmptyPage(pagesCount, undefined);
  };

  const handlePageView = (event: React.MouseEvent<HTMLElement>, aPageView: string | null) => {
    if (aPageView !== null) {
      setPageView(aPageView);
      setProperty(courseProperties.settings.items.pageView, aPageView);
    }
  };

  const handleNewPageItem = (item: TreeItem) => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    if (item.data === undefined) {
      //newEmptyPage(0);
    } else {
      let index = item.data.index + 1;
      let parentCourseID = item.data.parentCoursePageID ? item.data.parentCoursePageID : 0;

      if (item.hasChildren && item.isExpanded) {
        index = 1;
        parentCourseID = item.id as number;
      }

      newEmptyPage(index, parentCourseID);
      //const parentCourseID = item.isExpanded ? (item.id as number) : item.data.parentCoursePageID;
      //const parentCourseID = item.data.parentCoursePageID ? item.data.parentCoursePageID : item.isExpanded && item.hasChildren ? (item.id as number) : 0;
      //const index = item.data.index;
    }
  };

  const handleDuplicatePage = (aPage: TreeItem) => {
    const pageID = parseInt(aPage.id + '');

    if (isNaN(pageID)) {
      console.log('unable to parse pageID as number.');
      enqueueSnackbar('Error copying page.', {
        variant: 'error',
      });
      return;
    }
    const copyObj: ICopyPageObject = {
      source: { courseID: courseViewModel.courseID, coursePageID: pageID },
      dest: { courseID: courseViewModel.courseID, coursePageID: aPage.data?.parentCoursePageID ? aPage.data.parentCoursePageID : 0 },
      copyChildPages: false,
      index: aPage.data?.index ? aPage.data.index : pagesCount,
      numberOfChildren: 0,
    };
    copyPage(copyObj)
      .then(() => {
        loadCourseViewModel(courseViewModel.courseID);
      })
      .catch((error) => {
        console.error('error copying page:', error);
      });
  };

  const handleCopyPage = (aPage: TreeItem, aCopyChildren: boolean) => {
    const pageID = parseInt(aPage.id + '');

    if (isNaN(pageID)) {
      console.log('unable to parse pageID as number.');
      enqueueSnackbar('Error copying page.', {
        variant: 'error',
      });
      return;
    }

    const copyObj: ICopyPageObject = {
      source: { courseID: courseViewModel.courseID, coursePageID: pageID },
      dest: { courseID: 0, coursePageID: 0 },
      copyChildPages: aCopyChildren,
      index: 0,
      numberOfChildren: 0,
    };
    if (aCopyChildren) {
      copyObj.numberOfChildren = checkNumberOfChildren(courseViewModel.pages, aPage.id);
    }
    navigator.clipboard.writeText(JSON.stringify(copyObj));
    SnackbarUtils.info('Copied to clipboard');
  };

  const resizeCourseView = () => {
    setCourseViewHeight(window.innerHeight - 300 > 200 ? window.innerHeight - 300 : 200);
  };

  const handleCopyPastePage = async (item?: TreeItem) => {
    // Read clipboard data
    navigator.clipboard
      .readText()
      .then((clipboardData) => {
        try {
          const parsedCopyObj: ICopyPageObject = JSON.parse(clipboardData);
          // Validating structure
          let index = pagesCount;
          if (parsedCopyObj.source && parsedCopyObj.source.courseID !== undefined && parsedCopyObj.source.coursePageID !== undefined && parsedCopyObj.copyChildPages !== undefined) {
            if (item?.data !== undefined) {
              index = item.data.index + 1;
              let parentCourseID = item.data.parentCoursePageID ? item.data.parentCoursePageID : 0;

              if (item.hasChildren && item.isExpanded) {
                index = 0;
                parentCourseID = item.id as number;
              }

              parsedCopyObj.dest.coursePageID = parentCourseID;
              const parentCount = checkNumberOfParents(courseViewModel.pages, item.id);
              parsedCopyObj.numberOfChildren = parsedCopyObj.numberOfChildren + parentCount - 1; //-1 fordi den teller med root.
            }

            parsedCopyObj.index = index;
            parsedCopyObj.dest.courseID = courseViewModel.courseID;

            if (parsedCopyObj.numberOfChildren < 3) {
              copyPage(parsedCopyObj)
                .then(() => {
                  loadCourseViewModel(courseViewModel.courseID);
                })
                .catch((error) => {
                  console.error('error copying page:', error);
                });
            } else {
              // eslint-disable-next-line quotes
              SnackbarUtils.info('Maximum indent level reached. Please paste at a higher level.', undefined, undefined, 5000);
            }

            return;
          } else {
            SnackbarUtils.error('Invalid page structure in clipboard. Please copy pages again and retry');
            return;
          }
        } catch (err) {
          SnackbarUtils.error('Invalid page structure in clipboard. Please copy pages again and retry');
          return;
        }
      })
      .catch((error) => {
        const errorString = error + '';

        if (errorString.includes('NotAllowedError')) {
          // eslint-disable-next-line quotes
          SnackbarUtils.error("We don't have access to your clipboard. Please reset browser permissions or duplicate page instead.", () => openWiki(), 'reset', 10000);
        }
      });
  };

  const openWiki = async () => {
    window.open('https://norskinteraktiv.atlassian.net/wiki/spaces/CBP/pages/2557870093/Creator+Essentials+Startup+Menu', '_blank')?.focus();
  };

  const handleDeselectPageProperties = (e: any) => {
    if (e.target.getAttribute('data-clickable') === 'true') {
      deselectPageProperties();
    }
  };

  const useStyles = createUseStyles((theme: Theme) => ({
    title: {
      color: theme.palette.text.primary,
    },
    divider: {
      borderColor: theme.palette.grey[600],
      borderBottomWidth: 2,
    },
    caption: {
      color: theme.palette.grey[600],
    },
    box: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(3),
      gridArea: '2 / 1 / 3 / 2',
    },
    content: {
      marginTop: 20,
      paddingTop: 5,
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
      overflow: 'auto',
    },
    conentTitle: {
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'space-between',
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
    },
    viewGroup: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(2),
      gridArea: '1 / 1 / 2 / 2',
    },
    drawer: {
      background: theme.palette.bgTwo.main,
      padding: 16,
      boxSizing: 'border-box',
      display: 'flex',
      gridArea: '1 / 2 / 3 / 3',
      flexGrow: 1,
      flexDirection: 'column',
      overflow: 'auto',
    },
    tooltip: {
      fontSize: theme.typography.pxToRem(15),
    },
    iconButton: {
      color: theme.palette.darkGray.main,
      margin: 5,
      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.palette.darkGray.light,
      },
    },
  }));
  const classes = useStyles({ theme });

  useEffect(() => {
    resizeCourseView();
    window.addEventListener('resize', resizeCourseView, { passive: false });
    return () => {
      window.removeEventListener('resize', resizeCourseView);
    };
  }, []);

  useEffect(() => {
    const pageCount = Object.keys(courseViewModel.pages.items);
    setPagesCount(pageCount.length);
  }, [courseViewModel]);

  return (
    <>
      <div
        data-clickable='true'
        style={{ flex: '1 1 auto', display: 'grid', gridAutoColumns: '1fr', gridTemplateColumns: '1fr 400px', gridTemplateRows: '72px 1fr' }}
        onClick={handleDeselectPageProperties}
      >
        <div data-clickable='true' className={classes.viewGroup}>
          <div>
            <Tooltip title='Course Properties' placement='top' tabIndex={0} arrow={true} classes={{ tooltip: classes.tooltip }}>
              <IconButton size='small' className={classes.iconButton} onClick={deselectPageProperties}>
                <SettingsIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
            <Tooltip title='Paste Page(s)' placement='top' tabIndex={1} arrow={true} classes={{ tooltip: classes.tooltip }}>
              <IconButton size='small' className={classes.iconButton} onClick={() => handleCopyPastePage()}>
                <ContentPasteIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
          </div>
          <ToggleButtonGroup size='small' value={pageView} exclusive onChange={handlePageView} aria-label='text alignment'>
            <ToggleButton value='list' aria-label='right aligned'>
              <ViewHeadlineIcon />
            </ToggleButton>
            <ToggleButton value='thicList' aria-label='centered'>
              <ViewListIcon />
            </ToggleButton>
            {/*             <ToggleButton value='tile' aria-label='left aligned'>
              <ViewModuleIcon />
            </ToggleButton> */}
          </ToggleButtonGroup>
        </div>

        <div className={classes.box} data-clickable='true'>
          {courseViewModel && (
            <>
              <div className={classes.conentTitle}>
                <div style={{ flex: '1 1 auto', marginRight: 10 }}>
                  {!editTitleMode ? (
                    <>
                      <Typography variant='h4' className={classes.title} onClick={() => setEditTitleMode(true)}>
                        {courseProperties.descriptions.items.title.option.value}
                      </Typography>
                      <Divider className={classes.divider} />
                    </>
                  ) : (
                    <>
                      <TextField
                        data-pagetour='2'
                        variant={'standard'}
                        size={'small'}
                        inputProps={{ style: { fontSize: '2.0243rem' } }}
                        hiddenLabel={true}
                        value={courseTitle}
                        onChange={handleChange}
                        onKeyDown={handleOnKeyDown}
                        onBlur={updateTitle}
                        fullWidth
                        autoFocus
                      />
                    </>
                  )}

                  <Typography variant='caption' className={classes.caption}>
                    ENGLISH
                  </Typography>
                </div>
                <Button style={{ maxHeight: 36.5, minWidth: 144.67 }} data-pagetour='1' color='primary' variant='contained' onClick={handleNewPageButton}>
                  ADD NEW PAGE
                </Button>
              </div>
              <div className={classes.content} data-clickable='true'>
                {pageView === 'list' && (
                  <CoursePageList
                    pages={courseViewModel.pages}
                    height={courseViewHeight}
                    handleNewPageItem={handleNewPageItem}
                    handlePastePage={handleCopyPastePage}
                    handleCopyPageItem={handleCopyPage}
                    handleDuplicatePageItem={handleDuplicatePage}
                  />
                )}
                {pageView === 'thicList' && (
                  <CoursePageList
                    pages={courseViewModel.pages}
                    height={courseViewHeight}
                    handleNewPageItem={handleNewPageItem}
                    handlePastePage={handleCopyPastePage}
                    handleCopyPageItem={handleCopyPage}
                    handleDuplicatePageItem={handleDuplicatePage}
                    image
                  />
                )}
                {/*                 {pageView === 'tile' && (
                  <CoursePageGrid
                    pages={courseViewModel.pages}
                    height={courseViewHeight}
                    handleNewPageItem={handleNewPageItem}
                    handlePastePage={handleCopyPastePage}
                    handleCopyPageItem={handleCopyPage}
                  />
                )} */}
              </div>
            </>
          )}
        </div>

        <div className={classes.drawer}>
          {pageProperties === undefined ? <>{courseProperties && <SideViewProperties propertyGroup={courseProperties} />}</> : <>{pageProperties && <DashboardPageProperties />}</>}
        </div>
      </div>
    </>
  );
});
